<template>
  <div>
    <div class="py-6">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <clientes></clientes>
      </div>
    </div>
  </div>
</template>

<script>
import Clientes from '../components/Clientes.vue';

export default {
  name: 'ClientesView',
  components: {
    Clientes
  }
}
</script> 