import configService from './config';

// Servicio para gestionar series
const seriesService = {
  // Obtener la URL base con los datos de configuración
  async getBaseUrl() {
    // Obtenemos la configuración
    const config = await configService.getConfig();
    
    // Formamos la URL correcta usando las variables de configuración
    return `${config.servidor}/${config.instancia}/simplegest_dat_dat/v1`;
  },

  // Obtener la API key desde la configuración
  async getApiKey() {
    return await configService.getApiKey();
  },

  // Obtener todas las series
  async getAll(params = {}) {
    const baseUrl = await this.getBaseUrl();
    const apiKey = await this.getApiKey();
    
    // Construir la URL con los parámetros de búsqueda
    let url = `${baseUrl}/series?api_key=${apiKey}`;
    
    // Añadir parámetros de filtro si existen
    if (params.filter && params.filter.parts) {
      url += `&filter%5Bparts%5D=${encodeURIComponent(params.filter.parts)}`;
    }
    
    console.log('Obteniendo series, URL:', url);
    
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    });

    if (!response.ok) {
      console.error('Error en respuesta:', response.status, response.statusText);
      throw new Error(`Error al obtener series: ${response.statusText}`);
    }

    const data = await response.json();
    console.log('Respuesta obtención series:', data);
    return data;
  },

  // Obtener una serie por ID
  async getById(id) {
    const baseUrl = await this.getBaseUrl();
    const apiKey = await this.getApiKey();
    const url = `${baseUrl}/series/${id}?api_key=${apiKey}`;
    console.log('Obteniendo serie por ID, URL:', url);
    
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    });

    if (!response.ok) {
      console.error('Error en respuesta:', response.status, response.statusText);
      throw new Error(`Error al obtener serie: ${response.statusText}`);
    }

    const data = await response.json();
    console.log('Respuesta obtención serie:', data);
    return data;
  },

  // Crear una nueva serie
  async create(serie) {
    const baseUrl = await this.getBaseUrl();
    const apiKey = await this.getApiKey();
    const url = `${baseUrl}/series?api_key=${apiKey}`;
    console.log('Creando serie, URL:', url);
    console.log('Datos a enviar:', serie);
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(serie)
    });

    if (!response.ok) {
      console.error('Error en respuesta:', response.status, response.statusText);
      throw new Error(`Error al crear serie: ${response.statusText}`);
    }

    const data = await response.json();
    console.log('Respuesta creación:', data);
    return data;
  },

  // Actualizar una serie existente
  async update(id, serie) {
    const baseUrl = await this.getBaseUrl();
    const apiKey = await this.getApiKey();
    const url = `${baseUrl}/series/${id}?api_key=${apiKey}`;
    console.log('Actualizando serie, URL:', url);
    console.log('Datos a enviar:', serie);
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(serie)
    });

    if (!response.ok) {
      console.error('Error en respuesta:', response.status, response.statusText);
      throw new Error(`Error al actualizar serie: ${response.statusText}`);
    }

    const data = await response.json();
    console.log('Respuesta actualización:', data);
    return data;
  },

  // Eliminar una serie
  async delete(id) {
    const baseUrl = await this.getBaseUrl();
    const apiKey = await this.getApiKey();
    const url = `${baseUrl}/series/${id}?api_key=${apiKey}`;
    console.log('Eliminando serie, URL:', url);
    
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json'
      }
    });

    if (!response.ok) {
      console.error('Error en respuesta:', response.status, response.statusText);
      throw new Error(`Error al eliminar serie: ${response.statusText}`);
    }

    const data = await response.json();
    console.log('Respuesta eliminación:', data);
    return data;
  }
};

export default seriesService; 