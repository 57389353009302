<template>
  <div class="container mx-auto py-8 px-4 sm:px-6 lg:px-8">
    <!-- Encabezado responsivo -->
    <div class="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-6">
      <h1 class="text-2xl font-semibold text-gray-900 mb-3 sm:mb-0">Series</h1>
      <button 
        @click="abrirModalCrear" 
        class="w-full sm:w-auto px-6 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 inline-flex items-center justify-center"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
        </svg>
        Nueva Serie
      </button>
    </div>
    
    <!-- Búsqueda responsiva -->
    <div class="flex flex-col sm:flex-row sm:items-center mb-6">
      <div class="relative flex-grow mb-4 sm:mb-0 sm:mr-4">
        <input
          type="text"
          v-model="terminoBusqueda"
          placeholder="Buscar por nombre"
          class="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-amber-500 focus:border-amber-500 text-sm"
          @input="debounceSearch"
        />
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
          </svg>
        </div>
        <button 
          v-if="terminoBusqueda" 
          @click="limpiarBusqueda" 
          class="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-500"
        >
          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
          </svg>
        </button>
      </div>
      <button
        @click="buscarSeries"
        class="w-full sm:w-auto inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
      >
        <svg class="h-4 w-4 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
        </svg>
        Buscar
      </button>
    </div>
    
    <!-- Tabla de series -->
    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div v-if="cargando" class="p-10 text-center">
        <svg class="animate-spin h-10 w-10 mx-auto text-amber-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <p class="mt-3 text-gray-600">Cargando series...</p>
      </div>
      
      <!-- Contenido cuando no está cargando -->
      <div v-else>
        <!-- Vista de tabla para pantallas medianas y grandes -->
        <div class="hidden md:block">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nombre</th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Abreviatura</th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tipos Movimientos</th>
                <th scope="col" class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Acciones</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-if="seriesFiltradas.length === 0">
                <td colspan="5" class="px-6 py-8 text-center text-gray-500">
                  No hay series disponibles
                </td>
              </tr>
              <tr v-for="serie in seriesFiltradas" :key="serie.id" class="hover:bg-gray-50">
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{{ serie.id }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-600">{{ serie.name }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-600">{{ serie.abreviatura }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                  <span v-if="serie.tipos_movimientos === 'C'" class="text-blue-800">Compras</span>
                  <span v-else-if="serie.tipos_movimientos === 'V'" class="text-green-800">Ventas</span>
                  <span v-else>{{ serie.tipos_movimientos }}</span>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <!--
                  <button 
                    @click="editarSerie(serie.id)" 
                    class="text-amber-600 hover:text-amber-800 mr-4"
                  >
                    Editar
                  </button>
                  <button 
                    @click="confirmarEliminar(serie)" 
                    class="text-red-600 hover:text-red-800"
                  >
                    Eliminar
                  </button>
                  -->
                  <button 
                    @click="editarSerie(serie.id)" 
                    class="text-amber-600 hover:text-amber-900 mx-2"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                    </svg>
                  </button>
                  <button 
                    @click="confirmarEliminar(serie)" 
                    class="text-red-600 hover:text-red-900 mx-2"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <!-- Vista de tarjetas para dispositivos móviles -->
        <div class="block md:hidden">
          <div v-if="seriesFiltradas.length === 0" class="px-6 py-8 text-center text-gray-500">
            No hay series disponibles
          </div>
          <div v-else>
            <div v-for="serie in seriesFiltradas" :key="serie.id" class="mb-4 border-b border-gray-200 pb-4 last:border-b-0 px-4 py-2">
              <div class="flex justify-between items-center mb-2">
                <h3 class="text-lg font-medium text-gray-900">{{ serie.name }}</h3>
                <span class="text-sm text-gray-500">ID: {{ serie.id }}</span>
              </div>
              <div class="grid grid-cols-2 gap-y-2 text-sm text-gray-600 mb-3">
                <div class="col-span-1">
                  <span class="font-medium">Abreviatura:</span>
                </div>
                <div class="col-span-1">
                  {{ serie.abreviatura }}
                </div>
                <div class="col-span-1">
                  <span class="font-medium">Tipos Movimientos:</span>
                </div>
                <div class="col-span-1">
                  <span v-if="serie.tipos_movimientos === 'C'" class="text-blue-800">Compras</span>
                  <span v-else-if="serie.tipos_movimientos === 'V'" class="text-green-800">Ventas</span>
                  <span v-else>{{ serie.tipos_movimientos }}</span>
                </div>
              </div>
              <div class="flex justify-end space-x-2 mt-2">

                <button 
                    @click="editarSerie(serie.id)" 
                    class="text-amber-600 hover:text-amber-900 mx-2"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                    </svg>
                  </button>
                  <button 
                    @click="confirmarEliminar(serie)" 
                    class="text-red-600 hover:text-red-900 mx-2"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!-- Controles de paginación -->
      <div v-if="!cargando && series.length > 0" class="px-4 py-3 bg-white border-t border-gray-200 sm:px-6">
        <div class="flex items-center justify-between">
          <div class="flex-1 flex justify-between sm:hidden">
            <button
              @click="cambiarPagina(paginaActual - 1)"
              :disabled="paginaActual === 1"
              :class="[
                'relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white',
                paginaActual === 1 
                  ? 'opacity-50 cursor-not-allowed' 
                  : 'hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-amber-500 focus:border-amber-500'
              ]"
            >
              Anterior
            </button>
            <button
              @click="cambiarPagina(paginaActual + 1)"
              :disabled="paginaActual === getPaginasTotales"
              :class="[
                'ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white',
                paginaActual === getPaginasTotales 
                  ? 'opacity-50 cursor-not-allowed' 
                  : 'hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-amber-500 focus:border-amber-500'
              ]"
            >
              Siguiente
            </button>
          </div>
          <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p class="text-sm text-gray-700">
                Mostrando 
                <span class="font-medium">{{ inicioPagina }}</span>
                a
                <span class="font-medium">{{ finPagina }}</span>
                de
                <span class="font-medium">{{ totalRegistros }}</span>
                resultados
              </p>
            </div>
            <div v-if="getPaginasTotales > 1">
              <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                <button
                  @click="cambiarPagina(1)"
                  :disabled="paginaActual === 1"
                  :class="[
                    'relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500',
                    paginaActual === 1 
                      ? 'opacity-50 cursor-not-allowed' 
                      : 'hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-amber-500 focus:border-amber-500'
                  ]"
                >
                  <span class="sr-only">Primera</span>
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                  </svg>
                </button>
                <button
                  @click="cambiarPagina(paginaActual - 1)"
                  :disabled="paginaActual === 1"
                  :class="[
                    'relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500',
                    paginaActual === 1 
                      ? 'opacity-50 cursor-not-allowed' 
                      : 'hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-amber-500 focus:border-amber-500'
                  ]"
                >
                  <span class="sr-only">Anterior</span>
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                  </svg>
                </button>
                
                <!-- Números de página -->
                <template v-for="pagina in paginasVisibles" :key="pagina">
                  <span
                    v-if="pagina === '...'"
                    class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"
                  >
                    ...
                  </span>
                  <button
                    v-else
                    @click="cambiarPagina(pagina)"
                    :class="[
                      'relative inline-flex items-center px-4 py-2 border text-sm font-medium',
                      pagina === paginaActual
                        ? 'z-10 bg-amber-50 border-amber-500 text-amber-600'
                        : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                    ]"
                  >
                    {{ pagina }}
                  </button>
                </template>
                
                <button
                  @click="cambiarPagina(paginaActual + 1)"
                  :disabled="paginaActual === getPaginasTotales"
                  :class="[
                    'relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500',
                    paginaActual === getPaginasTotales 
                      ? 'opacity-50 cursor-not-allowed' 
                      : 'hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-amber-500 focus:border-amber-500'
                  ]"
                >
                  <span class="sr-only">Siguiente</span>
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  </svg>
                </button>
                <button
                  @click="cambiarPagina(getPaginasTotales)"
                  :disabled="paginaActual === getPaginasTotales"
                  :class="[
                    'relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500',
                    paginaActual === getPaginasTotales 
                      ? 'opacity-50 cursor-not-allowed' 
                      : 'hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-amber-500 focus:border-amber-500'
                  ]"
                >
                  <span class="sr-only">Última</span>
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  </svg>
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Modal para crear o editar serie -->
    <div v-if="mostrarModal" class="fixed inset-0 z-10 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" @click="cerrarModal"></div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-lg">
          <form @submit.prevent="guardarSerie" class="w-full">
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div class="sm:flex sm:items-start">
                <div class="mt-3 text-center sm:mt-0 sm:text-left w-full">
                  <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    {{ modoEdicion ? 'Editar' : 'Nueva' }} Serie
                  </h3>
                  <div class="mt-4 space-y-4">
                    <!-- Nombre -->
                    <div>
                      <label for="name" class="block text-sm font-medium text-gray-700">Nombre</label>
                      <input 
                        type="text" 
                        id="name" 
                        v-model="serieActual.name" 
                        class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                        required
                      >
                    </div>
                    
                    <!-- Campos en layout de grid para pantallas más grandes -->
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <!-- Abreviatura -->
                      <div>
                        <label for="abreviatura" class="block text-sm font-medium text-gray-700">Abreviatura</label>
                        <input 
                          type="text" 
                          id="abreviatura" 
                          v-model="serieActual.abreviatura" 
                          class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                          required
                        >
                      </div>
                      
                      <!-- Tipos de movimientos -->
                      <div>
                        <label for="tipos_movimientos" class="block text-sm font-medium text-gray-700">Tipos de Movimientos</label>
                        <select 
                          id="tipos_movimientos" 
                          v-model="serieActual.tipos_movimientos" 
                          class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                          required
                        >
                          <option value="C">Compras</option>
                          <option value="V">Ventas</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button 
                type="submit" 
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-amber-500 text-base font-medium text-white hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 sm:ml-3 sm:w-auto sm:text-sm"
                :disabled="guardando"
              >
                <svg v-if="guardando" class="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                {{ guardando ? 'Guardando...' : 'Guardar' }}
              </button>
              <button 
                type="button" 
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                @click="cerrarModal"
              >
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    
    <!-- Modal de confirmación para eliminar -->
    <div v-if="mostrarModalEliminar" class="fixed inset-0 z-10 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" @click="mostrarModalEliminar = false"></div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-lg">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                  Eliminar Serie
                </h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    ¿Está seguro que desea eliminar la serie "{{ serieEliminar?.name }}"? Esta acción no se puede deshacer.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button 
              type="button" 
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              @click="eliminarSerie"
              :disabled="eliminando"
            >
              <svg v-if="eliminando" class="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              {{ eliminando ? 'Eliminando...' : 'Eliminar' }}
            </button>
            <button 
              type="button" 
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              @click="mostrarModalEliminar = false"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Modal para mensajes popup -->
    <div v-if="mensaje && mensaje.tipo === 'error'" class="fixed inset-0 z-20 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" @click="cerrarMensaje"></div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10 bg-red-100">
                <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                  Error
                </h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    {{ mensaje.texto }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button 
              type="button" 
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white bg-red-600 hover:bg-red-700 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
              @click="cerrarMensaje"
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Notificación de éxito tipo toast -->
    <div 
      v-if="mensaje && mensaje.tipo === 'exito'" 
      class="fixed top-4 right-4 z-50 bg-green-50 text-green-800 p-4 rounded-md shadow-lg border-l-4 border-green-500 max-w-sm transform transition-all duration-500 ease-in-out"
      :class="toastVisible ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'"
    >
      <div class="flex">
        <div class="flex-shrink-0">
          <svg class="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3">
          <p class="text-sm font-medium">{{ mensaje.texto }}</p>
        </div>
        <div class="ml-auto pl-3">
          <div class="-mx-1.5 -my-1.5">
            <button 
              type="button" 
              class="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              @click="cerrarMensaje"
            >
              <span class="sr-only">Cerrar</span>
              <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import seriesService from '../services/series';
import { useToast } from 'vue-toastification';

export default {
  name: 'SeriesComponent',
  data() {
    return {
      series: [],
      cargando: true,
      guardando: false,
      eliminando: false,
      mostrarModal: false,
      mostrarModalEliminar: false,
      modoEdicion: false,
      serieActual: {
        id: 0,
        name: '',
        abreviatura: '',
        tipos_movimientos: 'C'
      },
      serieEliminar: null,
      mensaje: null,
      // Propiedades para paginación
      paginaActual: 1,
      elementosPorPagina: 10,
      terminoBusqueda: '',
      filtroReset: false,
      searchTimeout: null,
      debounceTimeout: null,
      toast: useToast()
    };
  },
  computed: {
    // Filtrar series según la página actual
    seriesFiltradas() {
      const inicio = (this.paginaActual - 1) * this.elementosPorPagina;
      const fin = inicio + this.elementosPorPagina;
      return this.series.slice(inicio, fin);
    },
    // Calcular el número total de páginas
    totalPaginas() {
      return Math.ceil(this.series.length / this.elementosPorPagina);
    },
    // Getter adicional para totalPaginas para evitar conflictos
    getPaginasTotales() {
      return Math.ceil(this.series.length / this.elementosPorPagina);
    },
    // Calcular el índice del primer elemento de la página actual
    inicioPagina() {
      return this.series.length > 0 
        ? (this.paginaActual - 1) * this.elementosPorPagina + 1 
        : 0;
    },
    // Calcular el índice del último elemento de la página actual
    finPagina() {
      const fin = this.paginaActual * this.elementosPorPagina;
      return Math.min(fin, this.series.length);
    },
    // Calcular el número total de registros
    totalRegistros() {
      return this.series.length;
    },
    // Generar un array con los números de página visibles
    paginasVisibles() {
      const paginasVisibles = [];
      const totalPags = this.getPaginasTotales;
      
      if (totalPags <= 7) {
        // Si hay 7 o menos páginas, mostrar todas
        for (let i = 1; i <= totalPags; i++) {
          paginasVisibles.push(i);
        }
      } else {
        // Siempre incluir la primera página
        paginasVisibles.push(1);
        
        // Si la página actual está entre las primeras 3, mostrar 1-5 ... ultima
        if (this.paginaActual <= 3) {
          for (let i = 2; i <= 5; i++) {
            paginasVisibles.push(i);
          }
          paginasVisibles.push('...');
          paginasVisibles.push(totalPags);
        } 
        // Si la página actual está entre las últimas 3, mostrar 1 ... ultima-4 - ultima
        else if (this.paginaActual >= totalPags - 2) {
          paginasVisibles.push('...');
          for (let i = totalPags - 4; i < totalPags; i++) {
            paginasVisibles.push(i);
          }
          paginasVisibles.push(totalPags);
        } 
        // Si la página actual está en medio, mostrar 1 ... actual-1, actual, actual+1 ... ultima
        else {
          paginasVisibles.push('...');
          paginasVisibles.push(this.paginaActual - 1);
          paginasVisibles.push(this.paginaActual);
          paginasVisibles.push(this.paginaActual + 1);
          paginasVisibles.push('...');
          paginasVisibles.push(totalPags);
        }
      }
      
      return paginasVisibles;
    }
  },
  created() {
    this.cargarSeries();
  },
  methods: {
    // Cargar todas las series
    async cargarSeries() {
      this.cargando = true;
      try {
        const response = await seriesService.getAll();
        this.series = response.series || [];
      } catch (error) {
        this.mostrarMensaje('Error al cargar series: ' + error.message, 'error');
      } finally {
        this.cargando = false;
      }
    },
    
    // Abrir modal para crear nueva serie
    abrirModalCrear() {
      this.modoEdicion = false;
      this.serieActual = {
        id: 0,
        name: '',
        abreviatura: '',
        tipos_movimientos: 'C'
      };
      this.mostrarModal = true;
    },
    
    // Abrir modal para editar serie existente
    async editarSerie(id) {
      this.cargando = true;
      try {
        const response = await seriesService.getById(id);
        if (response.series && response.series.length > 0) {
          this.serieActual = { ...response.series[0] };
          this.modoEdicion = true;
          this.mostrarModal = true;
        }
      } catch (error) {
        this.mostrarMensaje('Error al cargar los datos de la serie: ' + error.message, 'error');
      } finally {
        this.cargando = false;
      }
    },
    
    // Cerrar modal
    cerrarModal() {
      this.mostrarModal = false;
      this.serieActual = {
        id: 0,
        name: '',
        abreviatura: '',
        tipos_movimientos: 'C'
      };
    },
    
    // Guardar serie (crear o actualizar)
    async guardarSerie() {
      this.guardando = true;
      try {
        if (this.modoEdicion) {
          // Actualizar serie existente
          await seriesService.update(this.serieActual.id, this.serieActual);
          this.mostrarMensaje(`Serie "${this.serieActual.name}" actualizada correctamente`, 'exito');
        } else {
          // Crear nueva serie
          await seriesService.create(this.serieActual);
          this.mostrarMensaje(`Serie "${this.serieActual.name}" creada correctamente`, 'exito');
        }
        this.cerrarModal();
        this.cargarSeries();
      } catch (error) {
        this.mostrarMensaje('Error al guardar serie: ' + error.message, 'error');
      } finally {
        this.guardando = false;
      }
    },
    
    // Confirmar eliminación de serie
    confirmarEliminar(serie) {
      this.serieEliminar = serie;
      this.mostrarModalEliminar = true;
    },
    
    // Eliminar serie
    async eliminarSerie() {
      if (!this.serieEliminar) return;
      
      this.eliminando = true;
      try {
        await seriesService.delete(this.serieEliminar.id);
        this.mostrarMensaje(`Serie "${this.serieEliminar.name}" eliminada correctamente`, 'exito');
        this.mostrarModalEliminar = false;
        this.serieEliminar = null;
        this.cargarSeries();
      } catch (error) {
        this.mostrarMensaje('Error al eliminar serie: ' + error.message, 'error');
      } finally {
        this.eliminando = false;
      }
    },
    
    // Mostrar mensaje temporal
    mostrarMensaje(texto, tipo) {
      // Usar directamente toast en lugar del sistema personalizado
      if (tipo === 'exito') {
        this.toast.success(texto);
      } else if (tipo === 'error') {
        this.toast.error(texto);
      } else if (tipo === 'info') {
        this.toast.info(texto);
      } else if (tipo === 'warning') {
        this.toast.warning(texto);
      }
    },
    
    // Cambiar de página
    cambiarPagina(pagina) {
      if (pagina >= 1 && pagina <= this.totalPaginas) {
        this.paginaActual = pagina;
      }
    },

    // Función para buscar series
    async buscarSeries() {
      this.cargando = true;
      try {
        const response = await seriesService.getAll({ filter: { parts: this.terminoBusqueda } });
        this.series = response.series || [];
      } catch (error) {
        this.mostrarMensaje('Error al buscar series: ' + error.message, 'error');
      } finally {
        this.cargando = false;
      }
    },

    // Función para limpiar la búsqueda
    limpiarBusqueda() {
      this.terminoBusqueda = '';
      this.cargarSeries();
    },

    // Función para aplicar el debounce en la búsqueda
    debounceSearch() {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      
      this.debounceTimeout = setTimeout(() => {
        if (this.terminoBusqueda.length > 0) {
          this.buscarSeries();
        } else if (this.terminoBusqueda === '') {
          this.cargarSeries();
        }
      }, 500); // Esperar 500ms para evitar demasiadas llamadas
    },

    cerrarMensaje() {
      // Limpiar temporizador si existe
      if (this.toastTimeout) {
        clearTimeout(this.toastTimeout);
        this.toastTimeout = null;
      }
      
      // Ocultar toast
      this.toastVisible = false;
    }
  }
}
</script> 