<template>
  <div>
    <div class="py-6">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <anos-component></anos-component>
      </div>
    </div>
  </div>
</template>

<script>
import AnosComponent from '../components/Anos.vue';

export default {
  name: 'AnosView',
  components: {
    AnosComponent
  }
}
</script> 