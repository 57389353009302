<template>
  <div class="container mx-auto px-4 py-6">
    <TiposRetencion />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import TiposRetencion from '../components/TiposRetencion.vue';

export default defineComponent({
  name: 'TiposRetencionView',
  components: {
    TiposRetencion
  }
});
</script> 