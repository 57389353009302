import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// Importar Tailwind CSS (asegúrate de que este archivo exista)
import './tailwind.css'
// Importar estilos personalizados
import './assets/styles.css'

// Importar vue-toastification
import Toast from "vue-toastification";
// Importar estilos de vue-toastification
import "vue-toastification/dist/index.css";

// Opciones para toast
const toastOptions = {
  transition: "Vue-Toastification__bounce",
  maxToasts: 3,
  newestOnTop: true,
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false
};

const app = createApp(App)
app.use(router)
app.use(Toast, toastOptions) // Registramos Toast como plugin
app.mount('#app')
