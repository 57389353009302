import configService from './config';

// Servicio para gestionar movimientos de almacén
const movimientosAlmacenService = {
  // Obtener la URL base con los datos de configuración
  async getBaseUrl() {
    // Obtenemos la configuración
    const config = await configService.getConfig();
    
    // Formamos la URL correcta usando las variables de configuración
    return `${config.servidor}/${config.instancia}/simplegest_dat_dat/v1`;
  },

  // Obtener la API key desde la configuración
  async getApiKey() {
    return await configService.getApiKey();
  },

  // Obtener todos los movimientos de almacén con posibilidad de filtrado
  async getAll(params = {}) {
    const baseUrl = await this.getBaseUrl();
    const apiKey = await this.getApiKey();
    
    // Construir la URL con los parámetros de búsqueda
    let url = `${baseUrl}/movimientos_almacen?api_key=${apiKey}`;
    
    // Añadir parámetros de filtro si existen
    if (params.filter) {
      if (params.filter.facturas_compra) {
        url += `&filter%5Bfacturas_compra%5D=${encodeURIComponent(params.filter.facturas_compra)}`;
      }
      if (params.filter.articulo) {
        url += `&filter%5Barticulo%5D=${encodeURIComponent(params.filter.articulo)}`;
      }
      if (params.filter.proveedor) {
        url += `&filter%5Bproveedor%5D=${encodeURIComponent(params.filter.proveedor)}`;
      }
    }
    
    console.log('Obteniendo movimientos de almacén, URL:', url);
    
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    });

    const data = await response.json();
    console.log('Respuesta obtención movimientos de almacén:', data);
    return data;
  },

  // Obtener un movimiento de almacén por ID
  async getById(id) {
    const baseUrl = await this.getBaseUrl();
    const apiKey = await this.getApiKey();
    const url = `${baseUrl}/movimientos_almacen/${id}?api_key=${apiKey}`;
    console.log('Obteniendo movimiento de almacén por ID, URL:', url);
    
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    });

    const data = await response.json();
    console.log('Respuesta obtención movimiento de almacén:', data);
    return data;
  },

  // Crear un nuevo movimiento de almacén
  async create(movimiento) {
    const baseUrl = await this.getBaseUrl();
    const apiKey = await this.getApiKey();
    const url = `${baseUrl}/movimientos_almacen?api_key=${apiKey}`;
    console.log('Creando movimiento de almacén, URL:', url);
    console.log('Datos a enviar:', movimiento);
    
    delete movimiento.cliente;
    delete movimiento.proveedor;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(movimiento)
    });

    const data = await response.json();
    console.log('Respuesta creación movimiento de almacén:', data);
    return data;
  },

  // Actualizar un movimiento de almacén existente
  async update(id, movimiento) {
    const baseUrl = await this.getBaseUrl();
    const apiKey = await this.getApiKey();
    const url = `${baseUrl}/movimientos_almacen/${id}?api_key=${apiKey}`;
    console.log('Actualizando movimiento de almacén, URL:', url);
    console.log('Datos a enviar:', movimiento);
    

    delete movimiento.cliente;
    delete movimiento.proveedor;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(movimiento)
    });

    const data = await response.json();
    console.log('Respuesta actualización movimiento de almacén:', data);
    return data;
  },

  // Eliminar un movimiento de almacén
  async delete(id) {
    const baseUrl = await this.getBaseUrl();
    const apiKey = await this.getApiKey();
    const url = `${baseUrl}/movimientos_almacen/${id}?api_key=${apiKey}`;
    console.log('Eliminando movimiento de almacén, URL:', url);
    
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json'
      }
    });

    const data = await response.json();
    console.log('Respuesta eliminación movimiento de almacén:', data);
    return data;
  }
};

export default movimientosAlmacenService; 