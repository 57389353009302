import configService from './config';



// Servicio para gestionar clientes
const clientesService = {

       // Obtenemos la configuración
  
  // Obtener la URL base con los datos de configuración
  async getBaseUrl() {
    // Obtenemos la configuración
    const config = await configService.getConfig();
    
    // Formamos la URL correcta usando las variables de configuración
    return `${config.servidor}/${config.instancia}/simplegest_dat_dat/v1`;
  },

  // Obtener la API key desde la configuración
  async getApiKey() {
    return await configService.getApiKey();
  },

  // Obtener todas las formas de pago
  async getAll(params = {}) {
    const baseUrl = await this.getBaseUrl();
    const apiKey = await this.getApiKey();
    
    // Construir la URL con los parámetros de búsqueda
    let url = `${baseUrl}/clientes?api_key=${apiKey}`;
    
    // Añadir parámetros de filtro si existen
    if (params.filter && params.filter.parts) {
      url += `&filter%5Bparts%5D=${encodeURIComponent(params.filter.parts)}`;
    }
    
    console.log('Obteniendo clientes desde busqueda, URL:', url);
    
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    });

    //if (!response.ok) {
    //  console.error('Error en respuesta:', response.status, response.statusText);
    //  throw new Error(`Error al obtener proveedores: ${response.statusText}`);
    //}

    const data = await response.json();
    console.log('Respuesta obtención clientes:', data);
    return data;
  },

  // Obtener una forma de pago por ID
  async getById(id) {
    const baseUrl = await this.getBaseUrl();
    const apiKey = await this.getApiKey();
    const url = `${baseUrl}/clientes/${id}?api_key=${apiKey}`;
    console.log('Obteniendo cliente por ID, URL:', url);
    
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    });

    //if (!response.ok) {
    //  console.error('Error en respuesta:', response.status, response.statusText);
    //  throw new Error(`Error al obtener proveedor: ${response.statusText}`);
    //}

    const data = await response.json();
    console.log('Respuesta obtención cliente:', data);
    return data;
  },

  // Crear una nueva forma de pago
  async create(cliente) {
    const baseUrl = await this.getBaseUrl();
    const apiKey = await this.getApiKey();
    const url = `${baseUrl}/clientes?api_key=${apiKey}`;
    console.log('Creando cliente, URL:', url);
    console.log('Datos a enviar:', cliente);
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(cliente)
    });

    //if (!response.ok) {
    //  console.error('Error en respuesta:', response.status, response.statusText);
    //  throw new Error(`Error al crear proveedor: ${response.statusText}`);
    //}

    const data = await response.json();
    console.log('Respuesta creación:', data);
    return data;
  },

  // Actualizar una forma de pago existente
  async update(id, cliente) {
    const baseUrl = await this.getBaseUrl();
    const apiKey = await this.getApiKey();
    const url = `${baseUrl}/clientes/${id}?api_key=${apiKey}`;
    console.log('Actualizando cliente, URL:', url);
    console.log('Datos a enviar:', cliente);
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(cliente)
    });

    //if (!response.ok) {
    //  console.error('Error en respuesta:', response.status, response.statusText);
    //  throw new Error(`Error al actualizar cliente: ${response.statusText}`);
    //}

    const data = await response.json();
    console.log('Respuesta actualización:', data);
    return data;
  },

  // Eliminar una forma de pago
  async delete(id) {
    const baseUrl = await this.getBaseUrl();
    const apiKey = await this.getApiKey();
    const url = `${baseUrl}/clientes/${id}?api_key=${apiKey}`;
    console.log('Eliminando cliente, URL:', url);
    
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json'
      }
    });

    //if (!response.ok) {
    //  console.error('Error en respuesta:', response.status, response.statusText);
    //  throw new Error(`Error al eliminar cliente: ${response.statusText}`);
    //}

    const data = await response.json();
    console.log('Respuesta eliminación:', data);
    return data;
  }
};

export default clientesService; 