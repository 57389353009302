<template>
  <div class="container mx-auto py-6">
    <!-- Cabecera con título y botón de nuevo -->
    <div class="flex justify-between items-center mb-6">
      <h1 class="text-2xl font-semibold text-gray-900">Articulos</h1>
      <button
        @click="abrirModalArticulo()"
        class="bg-amber-500 hover:bg-amber-600 text-white px-4 py-2 rounded-lg flex items-center"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
        </svg>
        Nuevo Articulo
      </button>
    </div>

    <!-- Barra de búsqueda -->
    <div class="mb-6 flex gap-4">
      <div class="flex-1">
        <div class="relative">
          <input
            type="text"
            v-model="filtro"
            @input="buscarArticulos"
            placeholder="Buscar por nombre..."
            class="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-amber-500"
          />
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
            </svg>
          </div>
          <button
            v-if="filtro"
            @click="limpiarFiltro"
            class="absolute inset-y-0 right-0 pr-3 flex items-center"
          >
            <svg class="h-5 w-5 text-gray-400 hover:text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
            </svg>
          </button>
        </div>
      </div>
      <button
        @click="buscarArticulos"
        class="bg-amber-500 hover:bg-amber-600 text-white px-6 py-2 rounded-lg"
      >
        Buscar
      </button>
    </div>

    <!-- Tabla de articulos -->
    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div v-if="cargando" class="p-10 text-center">
        <svg class="animate-spin h-10 w-10 mx-auto text-amber-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <p class="mt-3 text-gray-600">Cargando articulos...</p>
      </div>
      <div v-else>
        <!-- Vista de tabla para pantallas medianas y grandes -->
        <div class="hidden md:block">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32">Referencia</th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-full">Nombre</th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-20">Precio Venta</th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-20">Precio Compra</th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-20">Stock</th>
                <th scope="col" class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider w-16">Acciones</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-if="articulosFiltrados.length === 0">
                <td colspan="4" class="px-6 py-4 text-center text-sm text-gray-500">
                  No se encontraron articulos. 
                  <button @click="abrirModalCrear" class="text-amber-600 hover:text-amber-900">Crear uno nuevo</button>
                </td>
              </tr>
              <tr v-for="articulo in articulosFiltrados" :key="articulo.id" class="hover:bg-gray-50">
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{{ articulo.referencia }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ articulo.name }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium w-20">{{ (articulo.precio_venta || 0).toFixed(2) }} €</td>
                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium w-20">{{ (articulo.precio_compra || 0).toFixed(2) }} €</td>
                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium w-20">{{ (articulo.existencias || 0).toFixed(2) }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button 
                    @click="abrirModalArticulo(articulo)" 
                    class="text-amber-600 hover:text-amber-900 mx-2"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                    </svg>
                  </button>
                  <button 
                    @click="confirmarEliminar(articulo)" 
                    class="text-red-600 hover:text-red-900 mx-2"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <!-- Vista de tarjetas para pantallas pequeñas -->
        <div class="md:hidden">
          <ul class="divide-y divide-gray-200">
            <li v-if="articulosFiltrados.length === 0" class="py-4 px-4 text-center text-sm text-gray-500">
              No se encontraron articulos. 
              <button @click="abrirModalCrear" class="text-amber-600 hover:text-amber-900">Crear uno nuevo</button>
            </li>
            <li v-for="articulo in articulosFiltrados" :key="articulo.id" class="py-4 px-4">
              <div class="flex items-center justify-between">
                <div>
                  <p class="text-sm font-medium text-gray-900">ID: {{ articulo.id }}</p>
                  <p class="text-sm text-gray-500">Nombre: {{ articulo.name }}</p>
                </div>
                <div class="flex items-center">
                  <button 
                    @click="abrirModalArticulo(articulo)" 
                    class="text-amber-600 hover:text-amber-900 mx-2"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                    </svg>
                  </button>
                  <button 
                    @click="confirmarEliminar(articulo)" 
                    class="text-red-600 hover:text-red-900 mx-2"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        
        <!-- Paginación -->
        <div v-if="articulos.length > elementosPorPagina" class="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
          <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p class="text-sm text-gray-700">
                Mostrando 
                <span class="font-medium">{{ (paginaActual - 1) * elementosPorPagina + 1 }}</span>
                a
                <span class="font-medium">{{ Math.min(paginaActual * elementosPorPagina, articulos.length) }}</span>
                de
                <span class="font-medium">{{ articulos.length }}</span>
                resultados
              </p>
            </div>
            <div>
              <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Paginación">
                <button
                  @click="cambiarPagina(1)"
                  :disabled="paginaActual === 1"
                  :class="[paginaActual === 1 ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:bg-gray-50', 'relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium']"
                >
                  <span class="sr-only">Primera página</span>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                  </svg>
                </button>
                <button
                  @click="cambiarPagina(paginaActual - 1)"
                  :disabled="paginaActual === 1"
                  :class="[paginaActual === 1 ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:bg-gray-50', 'relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium']"
                >
                  <span class="sr-only">Anterior</span>
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                  </svg>
                </button>
                <button
                  v-for="pagina in paginasVisibles"
                  :key="pagina"
                  @click="cambiarPagina(pagina)"
                  :class="[pagina === paginaActual ? 'z-10 bg-amber-50 border-amber-500 text-amber-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50', 'relative inline-flex items-center px-4 py-2 border text-sm font-medium']"
                >
                  {{ pagina }}
                </button>
                <button
                  @click="cambiarPagina(paginaActual + 1)"
                  :disabled="paginaActual === totalPaginas"
                  :class="[paginaActual === totalPaginas ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:bg-gray-50', 'relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium']"
                >
                  <span class="sr-only">Siguiente</span>
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  </svg>
                </button>
                <button
                  @click="cambiarPagina(totalPaginas)"
                  :disabled="paginaActual === totalPaginas"
                  :class="[paginaActual === totalPaginas ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:bg-gray-50', 'relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium']"
                >
                  <span class="sr-only">Última página</span>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M4.293 15.707a1 1 0 001.414 0l5-5a1 1 0 000-1.414l-5-5a1 1 0 00-1.414 1.414L8.586 10 4.293 14.293a1 1 0 000 1.414zm6 0a1 1 0 001.414 0l5-5a1 1 0 000-1.414l-5-5a1 1 0 00-1.414 1.414L15.586 10l-4.293 4.293a1 1 0 000 1.414z" clip-rule="evenodd" />
                  </svg>
                </button>
              </nav>
            </div>
          </div>
          
          <!-- Paginación simplificada para móviles -->
          <div class="flex flex-1 justify-between sm:hidden">
            <button
              @click="cambiarPagina(paginaActual - 1)"
              :disabled="paginaActual === 1"
              :class="[paginaActual === 1 ? 'text-gray-300 cursor-not-allowed' : 'text-amber-600 hover:text-amber-900', 'relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md bg-white']"
            >
              Anterior
            </button>
            <div class="text-sm text-gray-700 py-2">
              <span class="font-medium">{{ paginaActual }}</span>
              de
              <span class="font-medium">{{ totalPaginas }}</span>
            </div>
            <button
              @click="cambiarPagina(paginaActual + 1)"
              :disabled="paginaActual === totalPaginas"
              :class="[paginaActual === totalPaginas ? 'text-gray-300 cursor-not-allowed' : 'text-amber-600 hover:text-amber-900', 'relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md bg-white']"
            >
              Siguiente
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal de Articulo -->
    <div v-if="mostrarModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div class="relative bg-white rounded-lg shadow-xl max-w-4xl w-full m-4">
        <div class="flex justify-between items-center p-4 border-b">
          <h3 class="text-lg font-medium">{{ modoEdicion ? 'Editar' : 'Nuevo' }} Artículo</h3>
          <button @click="cerrarModal" class="text-gray-400 hover:text-gray-500">
            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <form @submit.prevent="guardarArticulo" class="p-4">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <!-- Datos básicos -->
            <div class="space-y-4">
              <div>
                <label class="block text-sm font-medium text-gray-700">Referencia</label>
                <input
                  type="text"
                  v-model="articuloActual.referencia"
                  required
                  class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-amber-500 focus:border-amber-500"
                />
              </div>

              <div>
                <label class="block text-sm font-medium text-gray-700">Nombre</label>
                <input
                  type="text"
                  v-model="articuloActual.name"
                  required
                  class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-amber-500 focus:border-amber-500"
                />
              </div>


              <div>
                <label class="block text-sm font-medium text-gray-700">Precio Compra</label>
                <input
                  type="number"
                  v-model.number="articuloActual.precio_compra"
                  step="0.01"
                  min="0"
                  pattern="^\d+(\.\d{1,2})?$" 
                  class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-amber-500 focus:border-amber-500"
                />
              </div>

              <div>
                <label class="block text-sm font-medium text-gray-700">Precio Venta</label>
                <input
                  type="number"
                  v-model.number="articuloActual.precio_venta"
                  step="0.01"
                  min="0"
                  pattern="^\d+(\.\d{1,2})?$" 
                  class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-amber-500 focus:border-amber-500"
                />
              </div>

              <div>
                <label class="block text-sm font-medium text-gray-700">Stock</label>
                <input
                  type="number"
                  v-model="articuloActual.existencias"
                  step="0.01"
                  readonly
                  class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-amber-500 focus:border-amber-500"
                />
              </div>

            </div>

            <!-- Datos adicionales -->
            <div class="space-y-4">
              <div>
                <label class="block text-sm font-medium text-gray-700">Proveedor</label>
                <div class="flex flex-row items-center mt-1">
                  <input
                    type="text"
                    :value="proveedorSeleccionado ? proveedorSeleccionado.name : 'No seleccionado'"
                    readonly
                    class="block w-full bg-gray-100 border border-gray-300 rounded-l-md shadow-sm py-2 px-3 focus:outline-none focus:ring-amber-500 focus:border-amber-500"
                  />
                  <button
                    type="button"
                    @click="abrirModalSeleccionProveedor"
                    class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-r-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                    Buscar
                  </button>
                </div>
              </div>


              <div>
                <label class="block text-sm font-medium text-gray-700">Observaciones</label>
                <textarea
                  v-model="articuloActual.descripcion"
                  rows="3"
                  class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-amber-500 focus:border-amber-500"
                ></textarea>
              </div>
            </div>
          </div>

          <div class="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              @click="cerrarModal"
              class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="bg-amber-500 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
            >
              {{ modoEdicion ? 'Actualizar' : 'Crear' }}
            </button>
          </div>
        </form>
      </div>
    </div>

        <!-- Modal de confirmación para eliminar -->
    <div v-if="mostrarModalEliminar" class="fixed inset-0 z-10 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" @click="mostrarModalEliminar = false"></div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-lg">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                  Eliminar Articulo
                </h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    ¿Está seguro que desea eliminar el articulo "{{ articuloEliminar?.name }}"? Esta acción no se puede deshacer.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button 
              type="button" 
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              @click="eliminarArticulo"
              :disabled="eliminando"
            >
              <svg v-if="eliminando" class="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              {{ eliminando ? 'Eliminando...' : 'Eliminar' }}
            </button>
            <button 
              type="button" 
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              @click="mostrarModalEliminar = false"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal de Selección de Proveedor -->
    <seleccionar-proveedor-modal
      v-if="mostrarModalSeleccionProveedor"
      @seleccionar="seleccionarProveedor"
      @cerrar="cerrarModalSeleccionProveedor"
    />
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';
import articulosService from '../services/articulos';
import proveedoresService from '../services/proveedores';
import SeleccionarProveedorModal from './SeleccionarProveedorModal.vue';

 
export default {
  name: 'ArticulosComponent',
  components: {
    SeleccionarProveedorModal
  },
  data() {
    return {
      articulos: [],
      proveedores: [],
      paginaActual: 1,
      elementosPorPagina: 10,
      filtro: '',
      mostrarModal: false,
      modoEdicion: false,
      articuloActual: this.articuloVacio(),
      debounceTimeout: null,
      toast: useToast(),
      mostrarModalEliminar: false,
      articuloEliminar: null,
      eliminando: false,
      cargando: false,
      mostrarModalSeleccionProveedor: false,
      proveedorSeleccionado: null
    };
  },
  computed: {
    // Filtrado de Pro por término de búsqueda para la vista actual
    articulosFiltrados() { 
      if (!this.articulos) return [];
      
      const inicio = (this.paginaActual - 1) * this.elementosPorPagina;
      const fin = inicio + this.elementosPorPagina;
      
      return this.articulos.slice(inicio, fin);
    },
    
    // Total de páginas basado en la cantidad de elementos
    totalPaginas() {
      return Math.ceil(this.articulos.length / this.elementosPorPagina);
    },

    // Genera un array con los números de página a mostrar
    paginasVisibles() {
      const paginas = [];
      const mostrarPaginas = 5; // Número de páginas a mostrar
      
      let inicio = Math.max(1, this.paginaActual - Math.floor(mostrarPaginas / 2));
      let fin = Math.min(this.totalPaginas, inicio + mostrarPaginas - 1);
      
      // Ajustar el inicio si estamos cerca del final
      if (fin === this.totalPaginas && fin > mostrarPaginas) {
        inicio = Math.max(1, fin - mostrarPaginas + 1);
      }
      
      for (let i = inicio; i <= fin; i++) {
        paginas.push(i);
      }
      console.log("paginas", paginas);
      return paginas;
    }
  },
  created() {
    this.cargarDatos();
  },
  methods: {
    articuloVacio() {
      return {
        id: 0,
        name: '',
        referencia: '',
        descripcion: '',
        proveedor: 0,
        precio_compra: 0,
        precio_venta: 0,
        existencias: 0,
        tipo_impuesto: 0,
        compras: false
      };
    },

    async cargarDatos() {
      try {
        this.loading = true;
        const [articulosResponse, proveedoresResponse] = 
          await Promise.all([
            articulosService.getAll(),
            proveedoresService.getAll()
          ]);
        
        this.articulos = articulosResponse.articulos || [];
        this.proveedores = proveedoresResponse.proveedores || [];
      } catch (error) {
        console.error('Error al cargar datos:', error);
        this.$toast.error('Error al cargar los datos');
      } finally {
        this.loading = false;
      }
    },
    async cargarArticulos() {
      this.cargando = true;
      try {
        const response = await articulosService.getAll();
        this.articulos = response.articulos;
      } catch (error) {
        this.mostrarMensaje('Error al cargar articulos: ' + error.message, 'error');
      } finally {
        this.cargando = false;
      }
    },
        // Función para buscar articulos
      async buscarArticulos() {
      if (this.filtro.length > 0) {
        this.cargando = true;
        try {
          const response = await articulosService.getAll({ 
            filter: { parts: this.filtro } 
          });
          this.articulos = response.articulos;
        } catch (error) {
          this.mostrarMensaje('Error al buscar articulos: ' + error.message, 'error');
        } finally {
          this.cargando = false;
        }
      } else {
          this.cargarArticulos();
      }
    },

    limpiarFiltro() {
      this.filtro = '';
      this.cargarArticulos();
    },

    abrirModalArticulo2222(articulo = null) {
      this.modoEdicion = !!articulo;
      this.articuloActual = articulo ? { ...articulo } : this.articuloVacio();
      this.mostrarModal = true;
      
      // Cargar el proveedor seleccionado si existe
      if (this.articuloActual.proveedor) {
        this.cargarProveedorSeleccionado();
      } else {
        this.proveedorSeleccionado = null;
      }
    },
    async abrirModalArticulo333(articulo = null) {
  this.modoEdicion = !!articulo;
  this.articuloActual = articulo ? { ...articulo } : this.articuloVacio();
  
  // Si el artículo tiene un proveedor, cargamos sus datos antes de mostrar el modal
  if (this.articuloActual.proveedor) {
    try {
      const response = await proveedoresService.getById(this.articuloActual.proveedor);
      console.log('RESPUESTA del proveedor:', response);
      if (response && response.proveedores) {
        this.proveedorSeleccionado = response.proveedores;
        console.log('Proveedor cargado:', this.proveedorSeleccionado);
      } else {
        this.proveedorSeleccionado = null;
        console.log('No se encontró información del proveedor');
      }
    } catch (error) {
      console.error('Error al cargar el proveedor:', error);
      this.toast.error('Error al cargar información del proveedor');
      this.proveedorSeleccionado = null;
    }
  } else {
    this.proveedorSeleccionado = null;
  }
  
  // Ahora que tenemos (o no) la información del proveedor, mostramos el modal
  this.mostrarModal = true;
},

async abrirModalArticulo(articulo = null) {
  this.modoEdicion = !!articulo;
  this.articuloActual = articulo ? { ...articulo } : this.articuloVacio();
  
  // Si el artículo tiene un proveedor, cargamos sus datos antes de mostrar el modal
  if (this.articuloActual.proveedor) {
    try {
      const response = await proveedoresService.getById(this.articuloActual.proveedor);
      console.log('RESPUESTA del proveedor:', response);
      
      // Verifica si response.proveedores es un array y tiene elementos
      if (response && response.proveedores && Array.isArray(response.proveedores) && response.proveedores.length > 0) {
        // Asignamos el primer proveedor del array
        this.proveedorSeleccionado = response.proveedores[0];
        console.log('Proveedor cargado:', this.proveedorSeleccionado);
      } else {
        this.proveedorSeleccionado = null;
        console.log('No se encontró información del proveedor');
      }
    } catch (error) {
      console.error('Error al cargar el proveedor:', error);
      this.toast.error('Error al cargar información del proveedor');
      this.proveedorSeleccionado = null;
    }
  } else {
    this.proveedorSeleccionado = null;
  }
  
  // Ahora que tenemos (o no) la información del proveedor, mostramos el modal
  this.mostrarModal = true;
},

    cerrarModal() {
      this.mostrarModal = false;
      this.articuloActual = this.articuloVacio();
    },
    // Cambiar de página
    cambiarPagina(pagina) {
      this.paginaActual = pagina;
    },
    async guardarArticulo() {
      try {
        if (!this.validarFormulario()) return;

        if (this.articuloActual.id) {
          await articulosService.update(this.articuloActual.id, this.articuloActual);
          console.log("Articulo actualizado correctamente",this.articuloActual);
          this.toast.success('Articulo actualizado correctamente');
        } else {
          await articulosService.create(this.articuloActual);
          this.toast.success('Articulo creado correctamente');
        }

        this.cerrarModal();
        await this.cargarDatos();
      } catch (error) {
        console.error('Error al guardar articulo:', error);
        this.toast.error('Error al guardar el articulo');
      }
    },

    // Eliminar articulo
    async eliminarArticulo() {
      if (!this.articuloEliminar) return;
      
      this.eliminando = true;
      try {
        await articulosService.delete(this.articuloEliminar.id);
        this.toast.success(`Articulo "${this.articuloEliminar.name}" eliminado correctamente`);
        this.mostrarModalEliminar = false;
        this.articuloEliminar = null;
        await this.cargarDatos();
      } catch (error) {
        console.error('Error al eliminar articulo:', error);
        this.toast.error('Error al eliminar el articulo');
      } finally {
        this.eliminando = false;
      }
    },
    // Confirmar eliminación de articulo
    confirmarEliminar(articulo) {
      console.log("ESTOY EN confirmarEliminar", articulo);
      this.articuloEliminar = articulo;
      this.mostrarModalEliminar = true;
    },
    validarFormulario() {
      // Implementa la lógica para validar el formulario
      return true; // Devuelve true si el formulario es válido, false en caso contrario
    },
    // Métodos para el modal de selección de proveedor
    abrirModalSeleccionProveedor() {
      this.mostrarModalSeleccionProveedor = true;
    },
    
    cerrarModalSeleccionProveedor() {
      this.mostrarModalSeleccionProveedor = false;
    },
    
    seleccionarProveedor(proveedor) {
      this.proveedorSeleccionado = proveedor;
      this.articuloActual.proveedor = proveedor.id;
      this.mostrarModalSeleccionProveedor = false;
    },
    
    async cargarProveedorSeleccionado() {
      if (this.articuloActual.proveedor) {
        try {
          const response = await proveedoresService.getById(this.articuloActual.proveedor);
          if (response && response.proveedores) {
            this.proveedorSeleccionado = response.proveedores;
          }
        } catch (error) {
          console.error('Error al cargar el proveedor:', error);
          this.toast.error('Error al cargar el proveedor seleccionado');
        }
      } else {
        this.proveedorSeleccionado = null;
      }
    }
  }
};
</script> 