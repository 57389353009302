<template>
  <div class="container mx-auto py-8 px-4 sm:px-6 lg:px-8">
    <!-- Encabezado responsivo -->
    <div class="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-6">
      <h1 class="text-2xl font-semibold text-gray-900 mb-3 sm:mb-0">Años</h1>
      <button 
        @click="abrirModalCrear" 
        class="w-full sm:w-auto px-6 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 inline-flex items-center justify-center"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
        </svg>
        Nuevo Año
      </button>
    </div>
    
    <!-- Búsqueda responsiva -->
    <div class="flex flex-col sm:flex-row sm:items-center mb-6">
      <div class="relative flex-grow mb-4 sm:mb-0 sm:mr-4">
        <input
          type="text"
          v-model="terminoBusqueda"
          placeholder="Buscar por año"
          class="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-amber-500 focus:border-amber-500 text-sm"
          @input="debounceSearch"
        />
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
          </svg>
        </div>
        <button 
          v-if="terminoBusqueda" 
          @click="limpiarBusqueda" 
          class="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-500"
        >
          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
          </svg>
        </button>
      </div>
      <button
        @click="buscarAnos"
        class="w-full sm:w-auto inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
      >
        <svg class="h-4 w-4 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
        </svg>
        Buscar
      </button>
    </div>
    
    <!-- Alerta de mensajes -->
    <div v-if="mensaje" :class="['mb-4 p-4 rounded-md', mensaje.tipo === 'error' ? 'bg-red-50 text-red-700' : 'bg-green-50 text-green-700']">
      <div class="flex">
        <div class="flex-shrink-0">
          <svg v-if="mensaje.tipo === 'error'" class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
          </svg>
          <svg v-else class="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3">
          <p class="text-sm font-medium">{{ mensaje.texto }}</p>
        </div>
        <div class="ml-auto pl-3">
          <div class="-mx-1.5 -my-1.5">
            <button 
              @click="cerrarMensaje" 
              class="inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2"
              :class="mensaje.tipo === 'error' ? 'text-red-500 hover:bg-red-100 focus:ring-red-600' : 'text-green-500 hover:bg-green-100 focus:ring-green-600'"
            >
              <span class="sr-only">Cerrar</span>
              <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Tabla de años -->
    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div v-if="cargando" class="p-10 text-center">
        <svg class="animate-spin h-10 w-10 mx-auto text-amber-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <p class="mt-3 text-gray-600">Cargando años...</p>
      </div>
      
      <!-- Contenido cuando no está cargando -->
      <div v-else>
        <!-- Vista de tabla para pantallas medianas y grandes -->
        <div class="hidden md:block">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Año</th>
                <th scope="col" class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Acciones</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-if="anosFiltrados.length === 0">
                <td colspan="3" class="px-6 py-4 text-center text-sm text-gray-500">
                  No se encontraron años. 
                  <button @click="abrirModalCrear" class="text-amber-600 hover:text-amber-900">Crear uno nuevo</button>
                </td>
              </tr>
              <tr v-for="ano in anosFiltrados" :key="ano.id" class="hover:bg-gray-50">
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{{ ano.id }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ ano.Año }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button 
                    @click="editarAno(ano)" 
                    class="text-amber-600 hover:text-amber-900 mx-2"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                    </svg>
                  </button>
                  <button 
                    @click="confirmarEliminacion(ano)" 
                    class="text-red-600 hover:text-red-900 mx-2"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <!-- Vista de tarjetas para pantallas pequeñas -->
        <div class="md:hidden">
          <ul class="divide-y divide-gray-200">
            <li v-if="anosFiltrados.length === 0" class="py-4 px-4 text-center text-sm text-gray-500">
              No se encontraron años. 
              <button @click="abrirModalCrear" class="text-amber-600 hover:text-amber-900">Crear uno nuevo</button>
            </li>
            <li v-for="ano in anosFiltrados" :key="ano.id" class="py-4 px-4">
              <div class="flex items-center justify-between">
                <div>
                  <p class="text-sm font-medium text-gray-900">ID: {{ ano.id }}</p>
                  <p class="text-sm text-gray-500">Año: {{ ano.Año }}</p>
                </div>
                <div class="flex items-center">
                  <button 
                    @click="editarAno(ano)" 
                    class="text-amber-600 hover:text-amber-900 mx-2"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                    </svg>
                  </button>
                  <button 
                    @click="confirmarEliminacion(ano)" 
                    class="text-red-600 hover:text-red-900 mx-2"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        
        <!-- Paginación -->
        <div v-if="anos.length > elementosPorPagina" class="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
          <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p class="text-sm text-gray-700">
                Mostrando 
                <span class="font-medium">{{ (paginaActual - 1) * elementosPorPagina + 1 }}</span>
                a
                <span class="font-medium">{{ Math.min(paginaActual * elementosPorPagina, anos.length) }}</span>
                de
                <span class="font-medium">{{ anos.length }}</span>
                resultados
              </p>
            </div>
            <div>
              <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Paginación">
                <button
                  @click="cambiarPagina(1)"
                  :disabled="paginaActual === 1"
                  :class="[paginaActual === 1 ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:bg-gray-50', 'relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium']"
                >
                  <span class="sr-only">Primera página</span>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                  </svg>
                </button>
                <button
                  @click="cambiarPagina(paginaActual - 1)"
                  :disabled="paginaActual === 1"
                  :class="[paginaActual === 1 ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:bg-gray-50', 'relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium']"
                >
                  <span class="sr-only">Anterior</span>
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                  </svg>
                </button>
                <button
                  v-for="pagina in paginasVisibles"
                  :key="pagina"
                  @click="cambiarPagina(pagina)"
                  :class="[pagina === paginaActual ? 'z-10 bg-amber-50 border-amber-500 text-amber-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50', 'relative inline-flex items-center px-4 py-2 border text-sm font-medium']"
                >
                  {{ pagina }}
                </button>
                <button
                  @click="cambiarPagina(paginaActual + 1)"
                  :disabled="paginaActual === totalPaginas"
                  :class="[paginaActual === totalPaginas ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:bg-gray-50', 'relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium']"
                >
                  <span class="sr-only">Siguiente</span>
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  </svg>
                </button>
                <button
                  @click="cambiarPagina(totalPaginas)"
                  :disabled="paginaActual === totalPaginas"
                  :class="[paginaActual === totalPaginas ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:bg-gray-50', 'relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium']"
                >
                  <span class="sr-only">Última página</span>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M4.293 15.707a1 1 0 001.414 0l5-5a1 1 0 000-1.414l-5-5a1 1 0 00-1.414 1.414L8.586 10 4.293 14.293a1 1 0 000 1.414zm6 0a1 1 0 001.414 0l5-5a1 1 0 000-1.414l-5-5a1 1 0 00-1.414 1.414L15.586 10l-4.293 4.293a1 1 0 000 1.414z" clip-rule="evenodd" />
                  </svg>
                </button>
              </nav>
            </div>
          </div>
          
          <!-- Paginación simplificada para móviles -->
          <div class="flex flex-1 justify-between sm:hidden">
            <button
              @click="cambiarPagina(paginaActual - 1)"
              :disabled="paginaActual === 1"
              :class="[paginaActual === 1 ? 'text-gray-300 cursor-not-allowed' : 'text-amber-600 hover:text-amber-900', 'relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md bg-white']"
            >
              Anterior
            </button>
            <div class="text-sm text-gray-700 py-2">
              <span class="font-medium">{{ paginaActual }}</span>
              de
              <span class="font-medium">{{ totalPaginas }}</span>
            </div>
            <button
              @click="cambiarPagina(paginaActual + 1)"
              :disabled="paginaActual === totalPaginas"
              :class="[paginaActual === totalPaginas ? 'text-gray-300 cursor-not-allowed' : 'text-amber-600 hover:text-amber-900', 'relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md bg-white']"
            >
              Siguiente
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal para crear/editar año -->
    <div v-if="modalAbierto" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" @click="cerrarModal"></div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <div>
            <div class="flex justify-between items-center mb-4">
              <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                {{ editandoAno ? 'Editar Año' : 'Crear Nuevo Año' }}
              </h3>
              <button 
                @click="cerrarModal" 
                class="text-gray-400 hover:text-gray-500"
              >
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            
            <form @submit.prevent="guardarAno">
              <div class="mb-4">
                <label for="ano" class="block text-sm font-medium text-gray-700">Año</label>
                <input 
                  type="number" 
                  id="ano" 
                  v-model="anoActual.Año" 
                  class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                  placeholder="2023"
                  required
                  min="1900"
                  max="2100"
                >
              </div>
              
              <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button 
                  type="submit"
                  class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-amber-500 text-base font-medium text-white hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 sm:col-start-2 sm:text-sm"
                  :disabled="guardando"
                >
                  <svg v-if="guardando" class="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  {{ guardando ? 'Guardando...' : 'Guardar' }}
                </button>
                <button 
                  type="button" 
                  @click="cerrarModal" 
                  class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Modal de confirmación para eliminar -->
    <div v-if="modalConfirmacionAbierto" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" @click="cerrarModalConfirmacion"></div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <div class="sm:flex sm:items-start">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">Eliminar Año</h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">
                  ¿Está seguro que desea eliminar este año? Esta acción no se puede deshacer.
                </p>
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button 
              type="button" 
              @click="eliminarAno" 
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              :disabled="eliminando"
            >
              <svg v-if="eliminando" class="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              {{ eliminando ? 'Eliminando...' : 'Eliminar' }}
            </button>
            <button 
              type="button" 
              @click="cerrarModalConfirmacion" 
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 sm:mt-0 sm:w-auto sm:text-sm"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import anosService from '../services/anos';
import { useToast } from 'vue-toastification';

export default {
  name: 'AnosComponent',
  data() {
    return {
      anos: [],
      cargando: true,
      modalAbierto: false,
      terminoBusqueda: '',
      paginaActual: 1,
      elementosPorPagina: 10,
      editandoAno: false,
      anoActual: {
        id: 0,
        year: new Date().getFullYear(),
        actual: false
      },
      modalConfirmacionAbierto: false,
      guardando: false,
      eliminando: false,
      searchTimeout: null,
      toast: useToast()
    };
  },
  computed: {
    // Filtrado de años por término de búsqueda para la vista actual
    anosFiltrados() {
      if (!this.anos) return [];
      
      const inicio = (this.paginaActual - 1) * this.elementosPorPagina;
      const fin = inicio + this.elementosPorPagina;
      
      return this.anos.slice(inicio, fin);
    },
    
    // Total de páginas basado en la cantidad de elementos
    totalPaginas() {
      return Math.ceil(this.anos.length / this.elementosPorPagina);
    },
    
    // Genera un array con los números de página a mostrar
    paginasVisibles() {
      const paginas = [];
      const mostrarPaginas = 5; // Número de páginas a mostrar
      
      let inicio = Math.max(1, this.paginaActual - Math.floor(mostrarPaginas / 2));
      let fin = Math.min(this.totalPaginas, inicio + mostrarPaginas - 1);
      
      // Ajustar el inicio si estamos cerca del final
      if (fin === this.totalPaginas && fin > mostrarPaginas) {
        inicio = Math.max(1, fin - mostrarPaginas + 1);
      }
      
      for (let i = inicio; i <= fin; i++) {
        paginas.push(i);
      }
      
      return paginas;
    }
  },
  async mounted() {
    await this.cargarAnos();
  },
  methods: {
    // Cargar todos los años desde el API
    async cargarAnos() {
      this.cargando = true;
      try {
        const response = await anosService.getAll();
        this.anos = response.años || [];
      } catch (error) {
        this.mostrarMensaje('Error al cargar los años: ' + error.message, 'error');
      } finally {
        this.cargando = false;
      }
    },
    
    // Buscar años según el término introducido
    debounceSearch() {
      // Cancelar búsqueda pendiente
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      
      // Programar nueva búsqueda
      this.searchTimeout = setTimeout(() => {
        this.buscarAnos();
      }, 500);
    },
    
    // Limpiar búsqueda
    limpiarBusqueda() {
      this.terminoBusqueda = '';
      this.cargarAnos();
    },
    
    // Abrir modal para crear un nuevo año
    abrirModalCrear() {
      this.editandoAno = false;
      this.anoActual = {
        id: null,
        Año: ''
      };
      this.modalAbierto = true;
    },
    
    // Abrir modal para editar un año existente
    editarAno(ano) {
      this.editandoAno = true;
      this.anoActual = { ...ano };
      this.modalAbierto = true;
    },
    
    // Cerrar modal de creación/edición
    cerrarModal() {
      this.modalAbierto = false;
    },
    
    // Guardar el año (crear o actualizar)
    async guardarAno() {
      this.guardando = true;
      try {
        if (this.editandoAno) {
          // Actualizar año existente
          await anosService.update(this.anoActual.id, this.anoActual);
          this.mostrarMensaje('Año actualizado correctamente', 'exito');
        } else {
          // Crear nuevo año
          await anosService.create(this.anoActual);
          this.mostrarMensaje('Año creado correctamente', 'exito');
        }
        
        // Cerrar modal y recargar datos
        this.cerrarModal();
        await this.cargarAnos();
      } catch (error) {
        this.mostrarMensaje('Error al guardar el año: ' + error.message, 'error');
      } finally {
        this.guardando = false;
      }
    },
    
    // Abrir modal de confirmación para eliminar
    confirmarEliminacion(ano) {
      this.anoActual = { ...ano };
      this.modalConfirmacionAbierto = true;
    },
    
    // Cerrar modal de confirmación
    cerrarModalConfirmacion() {
      this.modalConfirmacionAbierto = false;
    },
    
    // Eliminar el año
    async eliminarAno() {
      this.eliminando = true;
      try {
        await anosService.delete(this.anoActual.id);
        this.mostrarMensaje('Año eliminado correctamente', 'exito');
        
        // Cerrar modal y recargar datos
        this.cerrarModalConfirmacion();
        await this.cargarAnos();
      } catch (error) {
        this.mostrarMensaje('Error al eliminar el año: ' + error.message, 'error');
      } finally {
        this.eliminando = false;
      }
    },
    
    // Mostrar mensaje
    mostrarMensaje(texto, tipo) {
      if (tipo === 'exito') {
        this.toast.success(texto);
      } else if (tipo === 'error') {
        this.toast.error(texto);
      } else if (tipo === 'info') {
        this.toast.info(texto);
      } else if (tipo === 'warning') {
        this.toast.warning(texto);
      }
    },
    
    // Cambiar de página
    cambiarPagina(pagina) {
      if (pagina >= 1 && pagina <= this.totalPaginas) {
        this.paginaActual = pagina;
      }
    },

    // Función para buscar años
    async buscarAnos() {
      this.cargando = true;
      try {
        const response = await anosService.getAll({ filter: { parts: this.terminoBusqueda } });
        this.anos = response.años || [];
      } catch (error) {
        this.mostrarMensaje('Error al buscar años: ' + error.message, 'error');
      } finally {
        this.cargando = false;
      }
    },

    // Este método se mantiene por compatibilidad
    cerrarMensaje() {
      // No hace nada ya que toast se maneja automáticamente
    }
  }
}
</script> 