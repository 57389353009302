/**
 * Servicio para obtener la configuración de la instancia
 */
export default {
  // Almacén de configuración
  _config: null,

  /**
   * Obtiene la configuración de la instancia
   * @returns {Promise<Object>} Objeto con la configuración
   */
  async getConfig() {
    // Si ya cargamos la configuración, la devolvemos directamente
    if (this._config) {
      return this._config;
    }

    try {
      // Hacemos una petición para cargar el archivo instancia.json
      const response = await fetch('/instancia.json');
      
      if (!response.ok) {
        throw new Error(`Error al cargar la configuración: ${response.status}`);
      }
      
      // Guardamos la configuración en memoria
      this._config = await response.json();
      return this._config;
    } catch (error) {
      console.error('Error al cargar la configuración:', error);
      // En caso de error, devolvemos una configuración por defecto
      return {
        servidor: 'https://aolaol.ddns.net',
        instancia: 'simpleges',
        api_key: '123456'
      };
    }
  },

  /**
   * Método de utilidad para obtener la URL base de la API
   * @returns {Promise<string>} URL base de la API
   */
  async getApiBaseUrl() {
    const config = await this.getConfig();
    return `${config.servidor}/${config.instancia}`;
  },

  /**
   * Método para obtener el API Key
   * @returns {Promise<string>} API Key para autenticación
   */
  async getApiKey() {
    const config = await this.getConfig();
    return config.api_key;
  }
} 