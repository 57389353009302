import configService from './config';

// Servicio para gestionar tipos de impuesto
const tiposImpuestoService = {
  // Obtener la URL base con los datos de configuración
  async getBaseUrl() {
    // Obtenemos la configuración
    const config = await configService.getConfig();
    
    // Formamos la URL correcta usando las variables de configuración
    return `${config.servidor}/${config.instancia}/simplegest_dat_dat/v1`;
  },

  // Obtener la API key desde la configuración
  async getApiKey() {
    return await configService.getApiKey();
  },

  // Obtener todos los tipos de impuesto
  async getAll(params = {}) {
    const baseUrl = await this.getBaseUrl();
    const apiKey = await this.getApiKey();
    
    // Construir la URL con los parámetros de búsqueda
    let url = `${baseUrl}/tipos_impuestos?api_key=${apiKey}`;
    
    // Añadir parámetros de filtro si existen
    if (params.filter && params.filter.parts) {
      url += `&filter%5Bparts%5D=${encodeURIComponent(params.filter.parts)}`;
    }
    
    console.log('Obteniendo tipos de impuestos, URL:', url);
    
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    });

    if (!response.ok) {
      console.error('Error en respuesta:', response.status, response.statusText);
      throw new Error(`Error al obtener tipos de impuestos: ${response.statusText}`);
    }

    const data = await response.json();
    console.log('Respuesta obtención tipos de impuestos:', data);
    return data;
  },

  // Obtener un tipo de impuesto por ID
  async getById(id) {
    const baseUrl = await this.getBaseUrl();
    const apiKey = await this.getApiKey();
    const url = `${baseUrl}/tipos_impuestos/${id}?api_key=${apiKey}`;
    console.log('Obteniendo tipo de impuesto por ID, URL:', url);
    
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    });

    if (!response.ok) {
      console.error('Error en respuesta:', response.status, response.statusText);
      throw new Error(`Error al obtener tipo de impuesto: ${response.statusText}`);
    }

    const data = await response.json();
    console.log('Respuesta obtención tipo de impuesto:', data);
    return data;
  },

  // Crear un nuevo tipo de impuesto
  async create(tipoImpuesto) {
    const baseUrl = await this.getBaseUrl();
    const apiKey = await this.getApiKey();
    const url = `${baseUrl}/tipos_impuestos?api_key=${apiKey}`;
    console.log('Creando tipo de impuesto, URL:', url);
    console.log('Datos a enviar:', tipoImpuesto);
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(tipoImpuesto)
    });

    if (!response.ok) {
      console.error('Error en respuesta:', response.status, response.statusText);
      throw new Error(`Error al crear tipo de impuesto: ${response.statusText}`);
    }

    const data = await response.json();
    console.log('Respuesta creación:', data);
    return data;
  },

  // Actualizar un tipo de impuesto existente
  async update(id, tipoImpuesto) {
    const baseUrl = await this.getBaseUrl();
    const apiKey = await this.getApiKey();
    const url = `${baseUrl}/tipos_impuestos/${id}?api_key=${apiKey}`;
    console.log('Actualizando tipo de impuesto, URL:', url);
    console.log('Datos a enviar:', tipoImpuesto);
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(tipoImpuesto)
    });

    if (!response.ok) {
      console.error('Error en respuesta:', response.status, response.statusText);
      throw new Error(`Error al actualizar tipo de impuesto: ${response.statusText}`);
    }

    const data = await response.json();
    console.log('Respuesta actualización:', data);
    return data;
  },

  // Eliminar un tipo de impuesto
  async delete(id) {
    const baseUrl = await this.getBaseUrl();
    const apiKey = await this.getApiKey();
    const url = `${baseUrl}/tipos_impuestos/${id}?api_key=${apiKey}`;
    console.log('Eliminando tipo de impuesto, URL:', url);
    
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json'
      }
    });

    if (!response.ok) {
      console.error('Error en respuesta:', response.status, response.statusText);
      throw new Error(`Error al eliminar tipo de impuesto: ${response.statusText}`);
    }

    const data = await response.json();
    console.log('Respuesta eliminación:', data);
    return data;
  }
};

export default tiposImpuestoService; 