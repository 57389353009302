<!-- Modal para crear o editar una línea de factura de compra -->
<template>
  <div class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center" style="z-index: 50;">
    <div class="relative bg-white rounded-lg shadow-xl max-w-2xl w-full m-4">
      <div class="flex justify-between items-center p-4 border-b">
        <h3 class="text-lg font-medium">{{ modoEdicion ? 'Editar' : 'Nueva' }} Línea de Factura</h3>
        <button @click="cerrar" class="text-gray-400 hover:text-gray-500">
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      
      <div class="p-6">
        <form @submit.prevent="guardarLinea">
          <!-- Artículo -->
          <div class="mb-4">
            <label class="block text-sm font-medium text-gray-700 mb-1">Artículo *</label>
            <div class="flex">
              <div class="flex-grow">
                <input 
                  type="text" 
                  v-model="articuloSeleccionadoNombre" 
                  readonly
                  placeholder="Seleccionar artículo..."
                  required
                  class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-amber-500 focus:border-amber-500"
                />
              </div>
              <button 
                type="button"
                @click="abrirModalSeleccionarArticulo"
                class="ml-2 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
              >
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </button>
            </div>
          </div>
          
          <!-- Descripción -->
          <div class="mb-4">
            <label for="descripcion" class="block text-sm font-medium text-gray-700 mb-1">Descripción *</label>
            <input 
              type="text"
              id="descripcion"
              v-model="lineaForm.descripcion"
              required
              class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-amber-500 focus:border-amber-500"
            />
          </div>
          
          <!-- Descripción larga -->
          <div class="mb-4">
            <label for="descripcion_larga" class="block text-sm font-medium text-gray-700 mb-1">Descripción larga</label>
            <textarea 
              id="descripcion_larga"
              v-model="lineaForm.descripcion_larga"
              rows="2"
              class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-amber-500 focus:border-amber-500"
            ></textarea>
          </div>
          
          <!-- Cantidad, precio y descuento -->
          <div class="grid grid-cols-1 gap-4 mb-4 md:grid-cols-3">
            <div>
              <label for="cantidad" class="block text-sm font-medium text-gray-700 mb-1">Cantidad *</label>
              <input 
                type="number"
                id="cantidad"
                v-model="lineaForm.cantidad"
                required
                min="0.01"
                step="0.01"
                class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-amber-500 focus:border-amber-500"
                @input="calcularImporte"
              />
            </div>
            
            <div>
              <label for="precio" class="block text-sm font-medium text-gray-700 mb-1">Precio *</label>
              <input 
                type="number"
                id="precio"
                v-model="lineaForm.precio"
                required
                min="0"
                step="0.01"
                class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-amber-500 focus:border-amber-500"
                @input="calcularImporte"
              />
            </div>
            
            <div>
              <label for="porcentaje_descuento" class="block text-sm font-medium text-gray-700 mb-1">% Descuento</label>
              <input 
                type="number"
                id="porcentaje_descuento"
                v-model="lineaForm.porcentaje_descuento"
                min="0"
                max="100"
                step="0.01"
                class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-amber-500 focus:border-amber-500"
                @input="calcularImporte"
              />
            </div>
          </div>
          
          <!-- Importe, tipo de impuesto y % impuesto -->
          <div class="grid grid-cols-1 gap-4 mb-6 md:grid-cols-3">
            <div>
              <label for="importe" class="block text-sm font-medium text-gray-700 mb-1">Importe (€)</label>
              <input 
                type="number"
                id="importe"
                v-model="lineaForm.importe"
                readonly
                min="0"
                step="0.01"
                class="w-full px-3 py-2 border border-gray-300 rounded-md bg-gray-50"
              />
            </div>
            
            <div>
              <label for="tipo_impuesto" class="block text-sm font-medium text-gray-700 mb-1">Tipo de impuesto *</label>
              <select 
                id="tipo_impuesto"
                v-model="lineaForm.tipo_impuesto"
                required
                class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-amber-500 focus:border-amber-500"
                @change="actualizarPorcentajeImpuesto"
              >
                <option value="" disabled>Selecciona tipo de impuesto</option>
                <option v-for="tipoImpuesto in tiposImpuesto" :key="tipoImpuesto.id" :value="tipoImpuesto.id">{{ tipoImpuesto.name }}</option>
              </select>
            </div>
            
            <div>
              <label for="porcentaje_impuesto" class="block text-sm font-medium text-gray-700 mb-1">% Impuesto</label>
              <input 
                type="number"
                id="porcentaje_impuesto"
                v-model="lineaForm.porcentaje_impuesto"
                readonly
                min="0"
                max="100"
                step="0.01"
                class="w-full px-3 py-2 border border-gray-300 rounded-md bg-gray-50"
              />
            </div>
          </div>
          
          <!-- Opción de no sumar a retención -->
          <div class="mb-4">
            <div class="flex items-center">
              <input 
                type="checkbox"
                id="retencion_no"
                v-model="lineaForm.retencion_no"
                class="h-4 w-4 text-amber-600 focus:ring-amber-500 border-gray-300 rounded"
              />
              <label for="retencion_no" class="ml-2 block text-sm text-gray-900">
                No sumar a la base de retención
              </label>
            </div>
          </div>
          
          <div class="flex justify-end">
            <button 
              type="button"
              @click="cerrar" 
              class="mr-2 px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
            >
              Cancelar
            </button>
            <button 
              type="submit"
              class="px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
            >
              {{ modoEdicion ? 'Actualizar' : 'Crear' }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  
  <!-- Modal para seleccionar artículo -->
  <seleccionar-articulo-modal
    v-if="mostrarModalSeleccionarArticulo"
    @seleccionar="seleccionarArticulo"
    @cerrar="mostrarModalSeleccionarArticulo = false"
    style="z-index: 60;"
  />
</template>

<script>
import { useToast } from 'vue-toastification';
import SeleccionarArticuloModal from './SeleccionarArticuloModal.vue';
import tiposImpuestoService from '../services/tipos-impuesto';
import articulosService from '../services/articulos';
import movimientosAlmacenService from '../services/movimientos-almacen';

export default {
  name: 'LineaFacturaCompraModal',
  components: {
    SeleccionarArticuloModal
  },
  props: {
    facturaId: {
      type: Number,
      required: true
    },
    proveedorId: {
      type: Number,
      required: true
    },
    fechaFactura: {
      type: String,
      required: true
    },
    linea: {
      type: Object,
      default: null
    }
  },
  emits: ['guardar', 'cerrar'],
  data() {
    return {
      modoEdicion: false,
      lineaForm: {
        id: 0,
        tipo_movimiento: 'C', // Compra
        fecha: '',
        articulo: null,
        descripcion_larga: '',
        descripcion: '',
        cantidad: 1,
        precio: 0,
        porcentaje_descuento: 0,
        importe: 0,
        porcentaje_impuesto: 21, // Valor por defecto
        tipo_impuesto: '',
        factura_compra: 0,
        numero_linea_factura_compra: 0,
        factura_venta: 0,
        numero_linea_factura_venta: 0,
        cliente: 0,
        proveedor: 0,
        retencion_no: false
      },
      articuloSeleccionadoNombre: '',
      mostrarModalSeleccionarArticulo: false,
      tiposImpuesto: [],
      cargando: false,
      toast: useToast()
    };
  },
  async created() {
    // Cargar datos iniciales
    this.cargando = true;
    try {
      await this.cargarTiposImpuesto();
      
      // Si se está editando una línea existente
      if (this.linea) {
        this.modoEdicion = true;
        this.lineaForm = { ...this.linea };
        
        // Cargar detalles del artículo
        if (this.lineaForm.articulo) {
          await this.cargarDatosArticulo(this.lineaForm.articulo);
        }
      } else {
        // Nueva línea
        this.modoEdicion = false;
        this.lineaForm.factura_compra = this.facturaId;
        this.lineaForm.proveedor = this.proveedorId;
        this.lineaForm.fecha = this.fechaFactura;
      }
    } catch (error) {
      console.error('Error al cargar datos iniciales:', error);
      this.toast.error('Error al cargar datos iniciales');
    } finally {
      this.cargando = false;
    }
  },
  methods: {
    // Cargar los tipos de impuesto
    async cargarTiposImpuesto() {
      try {
        const response = await tiposImpuestoService.getAll();
        this.tiposImpuesto = response.tipos_impuestos || [];
      } catch (error) {
        console.error('Error al cargar tipos de impuesto:', error);
        this.toast.error('Error al cargar tipos de impuesto');
        throw error;
      }
    },
    
    // Cargar datos del artículo seleccionado
    async cargarDatosArticulo(articuloId) {
      try {
        const response = await articulosService.getById(articuloId);
        if (response && response.articulos && response.articulos.length > 0) {
          const articulo = response.articulos[0];
          this.articuloSeleccionadoNombre = articulo.name;
          
          // Si es una nueva línea, completar campos con datos del artículo
          if (!this.modoEdicion) {
            this.lineaForm.descripcion = articulo.name;
            this.lineaForm.precio = articulo.precio_compra || 0;
            
            // Establecer el tipo de impuesto y su porcentaje si existe
            if (articulo.tipo_impuesto) {
              this.lineaForm.tipo_impuesto = articulo.tipo_impuesto;
              // Actualizar el porcentaje de impuesto según el tipo seleccionado
              this.actualizarPorcentajeImpuesto();
            }
            
            // Recalcular el importe
            this.calcularImporte();
          }
        }
      } catch (error) {
        console.error('Error al cargar datos del artículo:', error);
        this.toast.error('Error al cargar datos del artículo');
      }
    },
    
    // Abrir modal para seleccionar artículo
    abrirModalSeleccionarArticulo() {
      this.mostrarModalSeleccionarArticulo = true;
    },
    
    // Seleccionar artículo desde el modal
    async seleccionarArticulo(articulo) {
      this.lineaForm.articulo = articulo.id;
      this.articuloSeleccionadoNombre = articulo.name;
      this.mostrarModalSeleccionarArticulo = false;
      
      try {
        // Cargar datos completos del artículo para obtener todos sus detalles
        const response = await articulosService.getById(articulo.id);
        if (response && response.articulos && response.articulos.length > 0) {
          const articuloCompleto = response.articulos[0];
          
          // Completar campos con datos del artículo
          this.lineaForm.descripcion = articuloCompleto.name;
          
          // Establecer el precio de compra si existe
          if (articuloCompleto.precio_compra) {
            this.lineaForm.precio = articuloCompleto.precio_compra;
          }
          
          // Establecer el tipo de impuesto y su porcentaje si existe
          if (articuloCompleto.tipo_impuesto) {
            this.lineaForm.tipo_impuesto = articuloCompleto.tipo_impuesto;
            // Actualizar el porcentaje de impuesto según el tipo seleccionado
            this.actualizarPorcentajeImpuesto();
          }
          
          // Recalcular el importe
          this.calcularImporte();
        }
      } catch (error) {
        console.error('Error al cargar detalles completos del artículo:', error);
        this.toast.error('Error al cargar detalles del artículo');
      }
    },
    
    // Actualizar el porcentaje de impuesto según el tipo seleccionado
    actualizarPorcentajeImpuesto() {
      if (this.lineaForm.tipo_impuesto) {
        const tipoImpuesto = this.tiposImpuesto.find(t => t.id === this.lineaForm.tipo_impuesto);
        if (tipoImpuesto) {
          this.lineaForm.porcentaje_impuesto = tipoImpuesto.porcentaje || 0;
        }
      } else {
        this.lineaForm.porcentaje_impuesto = 0;
      }
    },
    
    // Calcular el importe según cantidad, precio y descuento
    calcularImporte() {
      const cantidad = parseFloat(this.lineaForm.cantidad) || 0;
      const precio = parseFloat(this.lineaForm.precio) || 0;
      const porcentajeDescuento = parseFloat(this.lineaForm.porcentaje_descuento) || 0;
      
      // Calcular importe con descuento
      const importeBruto = cantidad * precio;
      const descuento = (importeBruto * porcentajeDescuento) / 100;
      const importeNeto = importeBruto - descuento;
      
      this.lineaForm.importe = parseFloat(importeNeto.toFixed(2));
    },
    
    // Guardar la línea de factura
    async guardarLinea() {
      if (!this.lineaForm.articulo) {
        this.toast.error('Debe seleccionar un artículo');
        return;
      }
      
      this.cargando = true;
      try {
        let response;
        
        if (this.modoEdicion) {
          // Actualizar línea existente
          response = await movimientosAlmacenService.update(this.lineaForm.id, this.lineaForm);
          //this.toast.success('Línea actualizada correctamente');
        } else {
          // Crear nueva línea
          response = await movimientosAlmacenService.create(this.lineaForm);
          //this.toast.success('Línea creada correctamente');
        }
        
          // Emitir evento con la línea guardada
          this.$emit('guardar', {
              response,
              modoEdicion: this.modoEdicion
          });


        this.cerrar();
      } catch (error) {
        console.error('Error al guardar línea:', error);
        this.toast.error('Error al guardar la línea');
      } finally {
        this.cargando = false;
      }
    },
    
    // Cerrar el modal
    cerrar() {
      this.$emit('cerrar');
    }
  }
};
</script> 