<template>
  <div>
    <div class="py-6">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <facturas-compra></facturas-compra>
      </div>
    </div>
  </div>
</template>

<script>
import FacturasCompra from '../components/FacturasCompra.vue';

export default {
  name: 'FacturasCompraView',
  components: {
    FacturasCompra
  }
}
</script> 