<template>
  <div class="container mx-auto px-4 py-8">
    <series-component />
  </div>
</template>

<script>
import SeriesComponent from '@/components/Series.vue'

export default {
  name: 'SeriesView',
  components: {
    SeriesComponent
  }
}
</script> 