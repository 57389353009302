import configService from './config';

// Servicio para gestionar la configuración de la empresa
const configuracionService = {
  // Obtener la URL base con los datos de configuración
  async getBaseUrl() {
    // Obtenemos la configuración
    const config = await configService.getConfig();
    
    // Formamos la URL correcta usando las variables de configuración
    return `${config.servidor}/${config.instancia}/simplegest_dat_dat/v1`;
  },

  // Obtener la API key desde la configuración
  async getApiKey() {
    return await configService.getApiKey();
  },

  // Obtener la configuración actual
  async getConfiguracion() {
    const baseUrl = await this.getBaseUrl();
    const apiKey = await this.getApiKey();
    const url = `${baseUrl}/configuracion/1?api_key=${apiKey}`;
    
    console.log('Obteniendo configuración, URL:', url);
    
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    });

    //if (!response.ok) {
    //  console.error('Error en respuesta:', response.status, response.statusText);
    //  throw new Error(`Error al obtener la configuración: ${response.statusText}`);
    //}

    const data = await response.json();
    console.log('Respuesta obtención configuración:', data);
    return data;
  },

  // Actualizar la configuración
  async updateConfiguracion(configuracion) {
    const baseUrl = await this.getBaseUrl();
    const apiKey = await this.getApiKey();
    const url = `${baseUrl}/configuracion/1?api_key=${apiKey}`;
    
    console.log('Actualizando configuración, URL:', url);
    console.log('Datos a enviar:', configuracion);
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(configuracion)
    });

    if (!response.ok) {
      console.error('Error en respuesta:', response.status, response.statusText);
      throw new Error(`Error al actualizar la configuración: ${response.statusText}`);
    }

    const data = await response.json();
    console.log('Respuesta actualización:', data);
    return data;
  }
};

export default configuracionService; 