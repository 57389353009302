<template>
  <div>
    <div class="py-6">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <tipos-gasto-component></tipos-gasto-component>
      </div>
    </div>
  </div>
</template>

<script>
import TiposGastoComponent from '../components/TiposGasto.vue';

export default {
  name: 'TiposGastoView',
  components: {
    TiposGastoComponent
  }
}
</script> 