<template>
  <header class="bg-white py-4">
    <div class="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between items-center">
        <div class="flex items-center">
          <div class="flex-shrink-0 flex items-center">
            <div class="flex items-center">
              <div class="flex h-10 w-10 items-center justify-center rounded-md bg-amber-500 text-white">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
  <!-- Fondo cuadrado con esquinas muy redondeadas -->
  <rect width="200" height="200" rx="60" ry="60" fill="#f8b133"/>
  
  <!-- Letra S estilizada en blanco, centrada vertical y horizontalmente -->
  <path d="M70 70 C50 70, 45 90, 45 110 C45 130, 55 140, 75 140 C95 140, 105 140, 125 140 C145 140, 155 150, 155 170 C155 190, 140 190, 120 190" 
        transform="translate(0,-30)"
        stroke="#ffffff" stroke-width="25" stroke-linecap="round" fill="none"/>
  
  <!-- Círculo decorativo en la parte superior, reposicionado -->
  <circle cx="140" cy="60" r="12" fill="#ffffff"/>
</svg>


                
              </div>
              <span class="ml-2 text-xl font-bold text-amber-500">Simple</span><span class="text-xl font-bold text-gray-800">Ges</span>
            </div>
          </div>
          
          <!-- Menú de navegación para escritorio -->
          <nav class="hidden md:ml-10 md:flex md:space-x-4">
            <a href="#" class="text-amber-500 font-medium px-3 py-2 text-sm border-b-2 border-amber-500">Inicio</a>
            
            <!-- Opciones del menú solo visibles cuando el usuario está autenticado -->
            <template v-if="isAuthenticated">
              <!-- Cuadro de Mando -->
              <div class="relative group">
                <a href="#" class="text-gray-600 hover:text-gray-800 px-3 py-2 text-sm font-medium hover:border-b-2 hover:border-amber-500">
                  Dashboard
                </a>
              </div>
              
              <!-- Ventas -->
              <div class="relative group">
                <a href="#" class="text-gray-600 hover:text-gray-800 px-3 py-2 text-sm font-medium hover:border-b-2 hover:border-amber-500 flex items-center">
                  Ventas
                  <svg class="ml-1 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </a>
                <!-- Submenú de Ventas -->
                <div class="absolute z-10 left-0 mt-1 w-56 origin-top-left bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition">
                  <div class="py-1">
                    <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Presupuesto de Venta</a>
                    <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Factura de Venta</a>
                    <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Cobros</a>
                  </div>
                </div>
              </div>
              
              <!-- Compras -->
              <div class="relative group">
                <a href="#" class="text-gray-600 hover:text-gray-800 px-3 py-2 text-sm font-medium hover:border-b-2 hover:border-amber-500 flex items-center">
                  Compras
                  <svg class="ml-1 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </a>
                <!-- Submenú de Compras -->
                <div class="absolute z-10 left-0 mt-1 w-56 origin-top-left bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition">
                  <div class="py-1">
                    <router-link to="/facturas-compra" class="block px-4 py-2 text-sm text-amber-600 font-medium hover:bg-amber-50">Facturas de Compra</router-link>
                    <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Pagos</a>
                  </div>
                </div>
              </div>
              
              <!-- Almacén -->
              <div class="relative group">
                <a href="#" class="text-gray-600 hover:text-gray-800 px-3 py-2 text-sm font-medium hover:border-b-2 hover:border-amber-500 flex items-center">
                  Almacén
                  <svg class="ml-1 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </a>
                <!-- Submenú de Almacén -->
                <div class="absolute z-10 left-0 mt-1 w-56 origin-top-left bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition">
                  <div class="py-1">
                    <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Movimientos de Almacén</a>
                  </div>
                </div>
              </div>
              
              <!-- Maestros -->
              <div class="relative group">
                <a href="#" class="text-gray-600 hover:text-gray-800 px-3 py-2 text-sm font-medium hover:border-b-2 hover:border-amber-500 flex items-center">
                  Maestros
                  <svg class="ml-1 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </a>
                <!-- Submenú de Maestros -->
                <div class="absolute z-10 left-0 mt-1 w-56 origin-top-left bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition">
                  <div class="py-1 max-h-96 overflow-y-auto">
                    <router-link to="/clientes" class="block px-4 py-2 text-sm text-amber-600 font-medium hover:bg-amber-50">Clientes</router-link>
                    <router-link to="/proveedores" class="block px-4 py-2 text-sm text-amber-600 font-medium hover:bg-amber-50">Proveedores</router-link>
                    <router-link to="/articulos" class="block px-4 py-2 text-sm text-amber-600 font-medium hover:bg-amber-50">Artículos</router-link>
                    <router-link to="/tipos-gasto" class="block px-4 py-2 text-sm text-amber-600 font-medium hover:bg-amber-50">Tipos de Gasto</router-link>
                    <router-link to="/tipos-impuesto" class="block px-4 py-2 text-sm text-amber-600 font-medium hover:bg-amber-50">Tipos de Impuesto</router-link>
                    <router-link to="/tipos-retencion" class="block px-4 py-2 text-sm text-amber-600 font-medium hover:bg-amber-50">Tipos de Retención</router-link>
                    <router-link to="/anos" class="block px-4 py-2 text-sm text-amber-600 font-medium hover:bg-amber-50">Años</router-link>
                    <router-link to="/series" class="block px-4 py-2 text-sm text-amber-600 font-medium hover:bg-amber-50">Series</router-link>
                    <router-link to="/formas-pago" class="block px-4 py-2 text-sm text-amber-600 font-medium hover:bg-amber-50">Formas de Pago</router-link>
                    <router-link to="/configuracion" class="block px-4 py-2 text-sm text-amber-600 font-medium hover:bg-amber-50">Configuración</router-link>
                  </div>
                </div>
              </div>
            </template>
            
            <a href="#" class="text-gray-600 hover:text-gray-800 px-3 py-2 text-sm font-medium hover:border-b-2 hover:border-amber-500">Contacto</a>
          </nav>
        </div>
        
        <div class="hidden md:flex md:items-center">
          <!-- Botón de login/logout según estado de autenticación -->
          <template v-if="isAuthenticated">
            <div class="mr-4 text-right">
              <p class="text-sm font-medium text-gray-800">{{ userName }}</p>
              <p class="text-xs text-gray-500">{{ userEmail }}</p>
            </div>
            <button 
              @click="closeSession" 
              class="inline-flex items-center px-4 py-2 border border-red-300 text-sm font-medium rounded-md text-red-700 bg-white hover:bg-red-50"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
              </svg>
              Cerrar Sesión
            </button>
          </template>
          <button v-else
            @click="openLoginModal" 
            class="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
            </svg>
            Iniciar Sesión
          </button>
        </div>
        
        <!-- Botón de menú móvil -->
        <div class="flex items-center md:hidden">
          <button @click="toggleMenu" type="button" 
                  class="inline-flex items-center justify-center p-2 rounded-md text-gray-600 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-amber-500">
            <span class="sr-only">Abrir menú</span>
            <svg v-if="!isMenuOpen" class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            <svg v-else class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    </div>
    
    <!-- Menú móvil -->
    <div v-if="isMenuOpen" class="md:hidden">
      <div class="pt-2 pb-3 space-y-1">
        <a href="#" class="block px-3 py-2 text-base font-medium text-amber-500 bg-gray-50 border-l-4 border-amber-500">Inicio</a>
        
        <!-- Opciones de menú móvil solo para usuarios autenticados -->
        <template v-if="isAuthenticated">
          <!-- Cuadro de Mando -->
          <a href="#" class="block px-3 py-2 text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-l-4 hover:border-amber-500">
            Dashboard
          </a>
          
          <!-- Ventas (acordeón) -->
          <div>
            <button 
              @click="toggleSubmenu('ventas')" 
              class="w-full flex justify-between items-center px-3 py-2 text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50"
            >
              <span>Ventas</span>
              <svg :class="{'rotate-180': openSubmenus.ventas}" class="h-5 w-5 transition-transform" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </button>
            <div v-show="openSubmenus.ventas" class="pl-6 space-y-1">
              <a href="#" class="block px-3 py-2 text-sm font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-50">
                Presupuesto de Venta
              </a>
              <a href="#" class="block px-3 py-2 text-sm font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-50">
                Factura de Venta
              </a>
              <a href="#" class="block px-3 py-2 text-sm font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-50">
                Cobros
              </a>
            </div>
          </div>
          
          <!-- Compras (acordeón) -->
          <div>
            <button 
              @click="toggleSubmenu('compras')" 
              class="w-full flex justify-between items-center px-3 py-2 text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50"
            >
              <span>Compras</span>
              <svg :class="{'rotate-180': openSubmenus.compras}" class="h-5 w-5 transition-transform" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </button>
            <div v-show="openSubmenus.compras" class="pl-6 space-y-1">
              <router-link to="/facturas-compra" class="block px-3 py-2 text-sm font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-50">
                Facturas de Compra
              </router-link>
              <a href="#" class="block px-3 py-2 text-sm font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-50">
                Pagos
              </a>
            </div>
          </div>
          
          <!-- Almacén (acordeón) -->
          <div>
            <button 
              @click="toggleSubmenu('almacen')" 
              class="w-full flex justify-between items-center px-3 py-2 text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50"
            >
              <span>Almacén</span>
              <svg :class="{'rotate-180': openSubmenus.almacen}" class="h-5 w-5 transition-transform" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </button>
            <div v-show="openSubmenus.almacen" class="pl-6 space-y-1">
              <a href="#" class="block px-3 py-2 text-sm font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-50">
                Movimientos de Almacén
              </a>
            </div>
          </div>
          
          <!-- Maestros (acordeón) -->
          <div>
            <button 
              @click="toggleSubmenu('maestros')" 
              class="w-full flex justify-between items-center px-3 py-2 text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50"
            >
              <span>Maestros</span>
              <svg :class="{'rotate-180': openSubmenus.maestros}" class="h-5 w-5 transition-transform" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </button>
            <div v-show="openSubmenus.maestros" class="pl-6 space-y-1">
              <a href="#" class="block px-3 py-2 text-sm font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-50">Clientes</a>
              <router-link to="/proveedores" class="block px-3 py-2 text-sm font-medium text-amber-600 hover:text-amber-700 hover:bg-amber-50">Proveedores</router-link>
              <a href="#" class="block px-3 py-2 text-sm font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-50">Artículos</a>
              <router-link to="/tipos-gasto" class="block px-3 py-2 text-sm font-medium text-amber-600 hover:text-amber-700 hover:bg-amber-50">Tipos de Gasto</router-link>
              <router-link to="/tipos-impuesto" class="block px-3 py-2 text-sm font-medium text-amber-600 hover:text-amber-700 hover:bg-amber-50">Tipos de Impuesto</router-link>
              <router-link to="/tipos-retencion" class="block px-3 py-2 text-sm font-medium text-amber-600 hover:text-amber-700 hover:bg-amber-50">Tipos de Retención</router-link>
              <router-link to="/anos" class="block px-3 py-2 text-sm font-medium text-amber-600 hover:text-amber-700 hover:bg-amber-50">Años</router-link>
              <router-link to="/series" class="block px-3 py-2 text-sm font-medium text-amber-600 hover:text-amber-700 hover:bg-amber-50">Series</router-link>
              <router-link to="/formas-pago" class="block px-3 py-2 text-sm font-medium text-amber-600 hover:text-amber-700 hover:bg-amber-50">Formas de Pago</router-link>
              <router-link to="/configuracion" class="block px-3 py-2 text-sm font-medium text-amber-600 hover:text-amber-700 hover:bg-amber-50">Configuración</router-link>
            </div>
          </div>
        </template>
        
        <a href="#" class="block px-3 py-2 text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-l-4 hover:border-amber-500">Contacto</a>
      </div>
      
      <div class="pt-4 pb-3 border-t border-gray-200">
        <div class="flex flex-col space-y-3 px-4">
          <!-- Información del usuario y botón de logout para móvil -->
          <div v-if="isAuthenticated" class="space-y-3">
            <div class="flex items-center px-4 py-3 rounded-md bg-gray-50">
              <div class="flex-shrink-0">
                <div class="h-10 w-10 rounded-full bg-amber-100 flex items-center justify-center text-amber-600 font-bold">
                  {{ userName.charAt(0).toUpperCase() }}
                </div>
              </div>
              <div class="ml-3">
                <div class="text-base font-medium text-gray-800">{{ userName }}</div>
                <div class="text-sm font-medium text-gray-500">{{ userEmail }}</div>
              </div>
            </div>
            <button
              @click="closeSession" 
              class="w-full inline-flex justify-center items-center px-4 py-2 border border-red-300 text-sm font-medium rounded-md text-red-700 bg-white hover:bg-red-50"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
              </svg>
              Cerrar Sesión
            </button>
          </div>
          <button v-else
            @click="openLoginModal" 
            class="w-full inline-flex justify-center items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
            </svg>
            Iniciar Sesión
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderComponent',
  props: {
    isAuthenticated: {
      type: Boolean,
      default: false
    },
    userName: {
      type: String,
      default: ''
    },
    userEmail: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isMenuOpen: false,
      openSubmenus: {
        ventas: false,
        compras: false,
        almacen: false,
        maestros: false
      }
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    toggleSubmenu(menu) {
      this.openSubmenus[menu] = !this.openSubmenus[menu];
    },
    closeSession() {
      // Emitimos el evento para que el componente padre lo maneje
      this.$emit('logout');
    },
    openLoginModal() {
      // Emitimos el evento para que el componente padre abra el modal de login
      this.$emit('open-login');
    },
    isActive(path) {
      return this.$route.path === path;
    }
  }
}
</script>

<style scoped>
/* Animación para submenús */
.transition {
  transition: all 0.2s ease-in-out;
}

/* Rotación de flechas en los acordeones */
.rotate-180 {
  transform: rotate(180deg);
}
</style> 