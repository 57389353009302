<template>
  <div class="container mx-auto py-8 px-4 sm:px-6 lg:px-8">
    <!-- Encabezado responsivo -->
    <div class="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-6">
      <h1 class="text-2xl font-semibold text-gray-900 mb-3 sm:mb-0">Tipos de Retención</h1>
      <button 
        @click="abrirModalCrear" 
        class="w-full sm:w-auto px-6 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 inline-flex items-center justify-center"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
        </svg>
        Nuevo Tipo de Retención
      </button>
    </div>
    
    <!-- Búsqueda responsiva -->
    <div class="flex flex-col sm:flex-row sm:items-center mb-6">
      <div class="relative flex-grow mb-4 sm:mb-0 sm:mr-4">
        <input
          type="text"
          v-model="terminoBusqueda"
          placeholder="Buscar por código o nombre"
          class="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-amber-500 focus:border-amber-500 text-sm"
          @input="debounceSearch"
        />
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
          </svg>
        </div>
        <button 
          v-if="terminoBusqueda" 
          @click="limpiarBusqueda" 
          class="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-500"
        >
          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
          </svg>
        </button>
      </div>
      <button
        @click="buscarTiposRetencion"
        class="w-full sm:w-auto inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
      >
        <svg class="h-4 w-4 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
        </svg>
        Buscar
      </button>
    </div>
    
    <!-- Notificación de éxito tipo toast -->
    <div 
      v-if="mensaje && mensaje.tipo === 'exito'" 
      class="fixed top-4 right-4 z-50 bg-green-50 text-green-800 p-4 rounded-md shadow-lg border-l-4 border-green-500 max-w-sm transform transition-all duration-500 ease-in-out"
      :class="toastVisible ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'"
    >
      <div class="flex">
        <div class="flex-shrink-0">
          <svg class="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3">
          <p class="text-sm font-medium">{{ mensaje.texto }}</p>
        </div>
        <div class="ml-auto pl-3">
          <div class="-mx-1.5 -my-1.5">
            <button 
              type="button" 
              class="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              @click="cerrarMensaje"
            >
              <span class="sr-only">Cerrar</span>
              <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Tabla de tipos de retención -->
    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div v-if="cargando" class="p-10 text-center">
        <svg class="animate-spin h-10 w-10 mx-auto text-amber-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <p class="mt-3 text-gray-600">Cargando tipos de retención...</p>
      </div>
      <div v-else>
        <!-- Vista de tabla para pantallas medianas y grandes -->
        <div class="hidden md:block">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nombre</th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Porcentaje</th>
                <th scope="col" class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Acciones</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-if="tiposRetencionFiltrados.length === 0">
                <td colspan="4" class="px-6 py-4 text-center text-sm text-gray-500">
                  No se encontraron tipos de retención. 
                  <button @click="abrirModalCrear" class="text-amber-600 hover:text-amber-900">Crear uno nuevo</button>
                </td>
              </tr>
              <tr v-for="(tipoRetencion, index) in tiposRetencionFiltrados" :key="tipoRetencion.id || index" class="hover:bg-gray-50">
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{{ tipoRetencion.id || 'N/A' }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ tipoRetencion.name || 'Sin nombre' }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ (tipoRetencion.porcentaje !== undefined ? tipoRetencion.porcentaje : 0) }}%</td>
                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button 
                    @click="editarTipoRetencion(tipoRetencion)" 
                    class="text-amber-600 hover:text-amber-900 mx-2"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                    </svg>
                  </button>
                  <button 
                    @click="confirmarEliminacion(tipoRetencion)" 
                    class="text-red-600 hover:text-red-900 mx-2"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <!-- Vista de tarjetas para pantallas pequeñas -->
        <div class="md:hidden">
          <ul class="divide-y divide-gray-200">
            <li v-if="tiposRetencionFiltrados.length === 0" class="py-4 px-4 text-center text-sm text-gray-500">
              No se encontraron tipos de retención. 
              <button @click="abrirModalCrear" class="text-amber-600 hover:text-amber-900">Crear uno nuevo</button>
            </li>
            <li v-for="(tipoRetencion, index) in tiposRetencionFiltrados" :key="tipoRetencion.id || index" class="py-4 px-4">
              <div class="flex items-center justify-between">
                <div>
                  <p class="text-sm font-medium text-gray-900">ID: {{ tipoRetencion.id || 'N/A' }}</p>
                  <p class="text-sm text-gray-500">Nombre: {{ tipoRetencion.name || 'Sin nombre' }}</p>
                  <p class="text-sm text-gray-500">Porcentaje: {{ (tipoRetencion.porcentaje !== undefined ? tipoRetencion.porcentaje : 0) }}%</p>
                </div>
                <div class="flex items-center">
                  <button 
                    @click="editarTipoRetencion(tipoRetencion)" 
                    class="text-amber-600 hover:text-amber-900 mx-2"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                    </svg>
                  </button>
                  <button 
                    @click="confirmarEliminacion(tipoRetencion)" 
                    class="text-red-600 hover:text-red-900 mx-2"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        
        <!-- Paginación -->
        <div v-if="tiposRetencion.length > elementosPorPagina" class="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
          <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p class="text-sm text-gray-700">
                Mostrando 
                <span class="font-medium">{{ (paginaActual - 1) * elementosPorPagina + 1 }}</span>
                a
                <span class="font-medium">{{ Math.min(paginaActual * elementosPorPagina, tiposRetencion.length) }}</span>
                de
                <span class="font-medium">{{ tiposRetencion.length }}</span>
                resultados
              </p>
            </div>
            <div>
              <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Paginación">
                <button
                  @click="cambiarPagina(1)"
                  :disabled="paginaActual === 1"
                  :class="[paginaActual === 1 ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:bg-gray-50', 'relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium']"
                >
                  <span class="sr-only">Primera página</span>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                  </svg>
                </button>
                <button
                  @click="cambiarPagina(paginaActual - 1)"
                  :disabled="paginaActual === 1"
                  :class="[paginaActual === 1 ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:bg-gray-50', 'relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium']"
                >
                  <span class="sr-only">Anterior</span>
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                  </svg>
                </button>
                <button
                  v-for="pagina in paginasVisibles"
                  :key="pagina"
                  @click="cambiarPagina(pagina)"
                  :class="[pagina === paginaActual ? 'z-10 bg-amber-50 border-amber-500 text-amber-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50', 'relative inline-flex items-center px-4 py-2 border text-sm font-medium']"
                >
                  {{ pagina }}
                </button>
                <button
                  @click="cambiarPagina(paginaActual + 1)"
                  :disabled="paginaActual === totalPaginas"
                  :class="[paginaActual === totalPaginas ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:bg-gray-50', 'relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium']"
                >
                  <span class="sr-only">Siguiente</span>
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  </svg>
                </button>
                <button
                  @click="cambiarPagina(totalPaginas)"
                  :disabled="paginaActual === totalPaginas"
                  :class="[paginaActual === totalPaginas ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:bg-gray-50', 'relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium']"
                >
                  <span class="sr-only">Última página</span>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M4.293 15.707a1 1 0 001.414 0l5-5a1 1 0 000-1.414l-5-5a1 1 0 00-1.414 1.414L8.586 10 4.293 14.293a1 1 0 000 1.414zm6 0a1 1 0 001.414 0l5-5a1 1 0 000-1.414l-5-5a1 1 0 00-1.414 1.414L15.586 10l-4.293 4.293a1 1 0 000 1.414z" clip-rule="evenodd" />
                  </svg>
                </button>
              </nav>
            </div>
          </div>
          
          <!-- Paginación simplificada para móviles -->
          <div class="flex flex-1 justify-between sm:hidden">
            <button
              @click="cambiarPagina(paginaActual - 1)"
              :disabled="paginaActual === 1"
              :class="[paginaActual === 1 ? 'text-gray-300 cursor-not-allowed' : 'text-amber-600 hover:text-amber-900', 'relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md bg-white']"
            >
              Anterior
            </button>
            <div class="text-sm text-gray-700 py-2">
              <span class="font-medium">{{ paginaActual }}</span>
              de
              <span class="font-medium">{{ totalPaginas }}</span>
            </div>
            <button
              @click="cambiarPagina(paginaActual + 1)"
              :disabled="paginaActual === totalPaginas"
              :class="[paginaActual === totalPaginas ? 'text-gray-300 cursor-not-allowed' : 'text-amber-600 hover:text-amber-900', 'relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md bg-white']"
            >
              Siguiente
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal para crear/editar tipo de retención -->
    <div v-if="modalVisible" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" @click="cerrarModal"></div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <!-- Encabezado con X para cerrar -->
          <div class="flex justify-between items-center p-4 border-b">
            <h3 class="text-lg font-medium text-gray-900">
              {{ modoEdicion ? 'Editar Tipo de Retención' : 'Crear Tipo de Retención' }}
            </h3>
            <button @click="cerrarModal" class="text-gray-400 hover:text-gray-500">
              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          
          <div class="bg-white p-4">
            <div class="space-y-4">
              <div>
                <label for="nombre" class="block text-sm font-medium text-gray-700 mb-1">Nombre</label>
                <input 
                  type="text" 
                  id="nombre" 
                  v-model="formulario.name" 
                  class="w-full p-2 border border-gray-300 rounded-md" 
                >
              </div>

              <div>
                <label for="porcentaje" class="block text-sm font-medium text-gray-700 mb-1">Porcentaje (%)</label>
                <input 
                  type="number" 
                  id="porcentaje" 
                  v-model.number="formulario.porcentaje" 
                  step="0.01"
                  min="0"
                  max="100"
                  class="w-full p-2 border border-gray-300 rounded-md" 
                >
              </div>
            </div>
          </div>
          
          <div class="bg-gray-50 px-4 py-3 flex flex-row-reverse gap-2">
            <button 
              type="button" 
              @click="guardarTipoRetencion" 
              class="px-4 py-2 bg-amber-500 hover:bg-amber-600 text-white rounded-md"
              :disabled="guardando"
            >
              {{ guardando ? 'Guardando...' : 'Guardar' }}
            </button>
            <button 
              type="button" 
              @click="cerrarModal" 
              class="px-4 py-2 bg-white border border-gray-300 text-gray-700 rounded-md"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal de confirmación para eliminar -->
    <div v-if="modalConfirmacionVisible" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" @click="cerrarModalConfirmacion"></div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <!-- Encabezado con X para cerrar -->
          <div class="flex justify-between items-center p-4 border-b">
            <h3 class="text-lg font-medium text-gray-900">Eliminar Tipo de Retención</h3>
            <button @click="cerrarModalConfirmacion" class="text-gray-400 hover:text-gray-500">
              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          
          <div class="bg-white p-6">
            <p class="text-sm text-gray-500">
              ¿Está seguro que desea eliminar el tipo de retención "{{ tipoRetencionAEliminar?.name }}"? Esta acción no se puede deshacer.
            </p>
          </div>
          
          <div class="bg-gray-50 px-4 py-3 flex flex-row-reverse gap-2">
            <button 
              type="button" 
              @click="eliminarTipoRetencion" 
              class="px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded-md"
              :disabled="eliminando"
            >
              {{ eliminando ? 'Eliminando...' : 'Eliminar' }}
            </button>
            <button 
              type="button" 
              @click="cerrarModalConfirmacion" 
              class="px-4 py-2 bg-white border border-gray-300 text-gray-700 rounded-md"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue';
import tiposRetencionService from '../services/tipos-retencion';
import { useToast } from 'vue-toastification';

export default defineComponent({
  name: 'TiposRetencion',
  setup() {
    // Variables de estado
    const tiposRetencion = ref([]);
    const cargando = ref(true);
    const terminoBusqueda = ref('');
    const paginaActual = ref(1);
    const elementosPorPagina = ref(10);
    
    // Estados para modal de crear/editar
    const modalVisible = ref(false);
    const modoEdicion = ref(false);
    const formulario = ref({
      id: 0,
      name: '',
      porcentaje: 0
    });
    const guardando = ref(false);
    
    // Estados para modal de eliminar
    const modalConfirmacionVisible = ref(false);
    const tipoRetencionAEliminar = ref(null);
    const eliminando = ref(false);
    
    // Estados para mensajes
    const mensaje = ref({ texto: '', tipo: 'success' });
    const toastVisible = ref(false);
    
    // Instancia del toast
    const toast = useToast();
    
    // Métodos computed
    const tiposRetencionFiltrados = computed(() => {
      console.log('Calculando tiposRetencionFiltrados...');
      console.log('tiposRetencion.value:', tiposRetencion.value);
      console.log('¿Es array?', Array.isArray(tiposRetencion.value));
      
      // Asegurar que tiposRetencion.value es siempre un array
      let resultado = Array.isArray(tiposRetencion.value) ? [...tiposRetencion.value] : [];
      console.log('resultado después de asegurar array:', resultado);
      
      // Filtrar por término de búsqueda
      if (terminoBusqueda.value.trim()) {
        const termino = terminoBusqueda.value.toLowerCase();
        resultado = resultado.filter(tipo => 
          tipo.name.toLowerCase().includes(termino) || 
          (tipo.Código && tipo.Código.toLowerCase().includes(termino))
        );
        console.log('resultado después de filtrar:', resultado);
      }
      
      // Paginación - cálculo del índice inicial y final para mostrar los elementos
      const indiceInicial = (paginaActual.value - 1) * elementosPorPagina.value;
      const indiceFinal = indiceInicial + elementosPorPagina.value;
      
      const resultadoPaginado = resultado.slice(indiceInicial, indiceFinal);
      console.log('resultado paginado:', resultadoPaginado);
      
      return resultadoPaginado;
    });
    
    const totalPaginas = computed(() => {
      // Asegurar que tiposRetencion.value es siempre un array
      const total = Array.isArray(tiposRetencion.value) ? tiposRetencion.value.length : 0;
      return Math.ceil(total / elementosPorPagina.value);
    });
    
    const paginasVisibles = computed(() => {
      // Calcular qué números de página mostrar (por ejemplo, mostrar 5 páginas)
      const paginasTotales = totalPaginas.value;
      let paginaInicio = Math.max(1, paginaActual.value - 2);
      const paginaFin = Math.min(paginasTotales, paginaInicio + 4);
      
      // Ajustar paginaInicio si estamos cerca del final
      paginaInicio = Math.max(1, paginaFin - 4);
      
      const paginas = [];
      for (let i = paginaInicio; i <= paginaFin; i++) {
        paginas.push(i);
      }
      
      return paginas;
    });
    
    // Métodos
    const cargarTiposRetencion = async () => {
      cargando.value = true;
      try {
        console.log('Solicitando tipos de retención al servicio...');
        const response = await tiposRetencionService.getAll();
        console.log('Respuesta completa del servicio:', response);
        
        // Asumimos una estructura similar a tiposImpuesto donde los datos vienen en una propiedad específica
        if (response.tipos_retencion && Array.isArray(response.tipos_retencion)) {
          console.log('Datos encontrados en response.tipos_retencion:', response.tipos_retencion.length, 'elementos');
          tiposRetencion.value = response.tipos_retencion;
        } else if (response.data && Array.isArray(response.data)) {
          console.log('Datos encontrados en response.data:', response.data.length, 'elementos');
          tiposRetencion.value = response.data;
        } else if (Array.isArray(response)) {
          console.log('La respuesta ya es un array con', response.length, 'elementos');
          tiposRetencion.value = response;
        } else {
          console.log('No se encontró un formato de respuesta reconocible, inicializando array vacío');
          tiposRetencion.value = [];
        }
        
        console.log('tiposRetencion.value después de asignar:', tiposRetencion.value);
        console.log('¿Es array?', Array.isArray(tiposRetencion.value));
        console.log('Longitud:', tiposRetencion.value.length);
      } catch (error) {
        console.error('Error al cargar tipos de retención:', error);
        toast.error('Error al cargar tipos de retención');
        tiposRetencion.value = [];
      } finally {
        cargando.value = false;
      }
    };
    
    const abrirModalCrear = () => {
      modoEdicion.value = false;
      formulario.value = {
        id: 0,
        name: '',
        porcentaje: 0
      };
      modalVisible.value = true;
    };
    
    const editarTipoRetencion = (tipoRetencion) => {
      modoEdicion.value = true;
      formulario.value = { ...tipoRetencion };
      modalVisible.value = true;
    };
    
    const cerrarModal = () => {
      modalVisible.value = false;
    };
    
    const guardarTipoRetencion = async () => {
      guardando.value = true;
      try {
        if (modoEdicion.value) {
          // Corregir la llamada al método update pasando el ID como primer parámetro
          await tiposRetencionService.update(formulario.value.id, formulario.value);
          mostrarMensaje('Tipo de retención actualizado correctamente', 'success');
        } else {
          await tiposRetencionService.create(formulario.value);
          mostrarMensaje('Tipo de retención creado correctamente', 'success');
        }
        await cargarTiposRetencion();
        cerrarModal();
      } catch (error) {
        console.error('Error al guardar tipo de retención:', error);
        mostrarMensaje(`Error al ${modoEdicion.value ? 'actualizar' : 'crear'} tipo de retención`, 'error');
      } finally {
        guardando.value = false;
      }
    };
    
    const confirmarEliminacion = (tipoRetencion) => {
      tipoRetencionAEliminar.value = tipoRetencion;
      modalConfirmacionVisible.value = true;
    };
    
    const cerrarModalConfirmacion = () => {
      modalConfirmacionVisible.value = false;
      tipoRetencionAEliminar.value = null;
    };
    
    const eliminarTipoRetencion = async () => {
      if (!tipoRetencionAEliminar.value) return;
      
      eliminando.value = true;
      try {
        await tiposRetencionService.delete(tipoRetencionAEliminar.value.id);
        mostrarMensaje('Tipo de retención eliminado correctamente', 'success');
        await cargarTiposRetencion();
        cerrarModalConfirmacion();
      } catch (error) {
        console.error('Error al eliminar tipo de retención:', error);
        mostrarMensaje('Error al eliminar tipo de retención', 'error');
      } finally {
        eliminando.value = false;
      }
    };
    
    const mostrarMensaje = (texto, tipo = 'success') => {
      mensaje.value = { texto, tipo };
      toastVisible.value = true;
      
      // Mostrar toast
      toast[tipo](texto);
      
      // Ocultar mensaje después de un tiempo
      setTimeout(() => {
        toastVisible.value = false;
      }, 5000);
    };
    
    const cerrarMensaje = () => {
      toastVisible.value = false;
    };
    
    const limpiarBusqueda = () => {
      terminoBusqueda.value = '';
      paginaActual.value = 1;
      cargarTiposRetencion();
    };
    
    const debounceSearch = (() => {
      let timeout = null;
      return () => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          paginaActual.value = 1;
          buscarTiposRetencion();
        }, 300);
      };
    })();
    
    const cambiarPagina = (pagina) => {
      paginaActual.value = pagina;
    };
    
    const buscarTiposRetencion = async () => {
      cargando.value = true;
      try {
        console.log('Buscando tipos de retención con término:', terminoBusqueda.value);
        const response = await tiposRetencionService.getAll({ filter: { parts: terminoBusqueda.value } });
        console.log('Respuesta de búsqueda:', response);
        
        // Asumimos una estructura similar a tiposImpuesto donde los datos vienen en una propiedad específica
        if (response.tipos_retencion && Array.isArray(response.tipos_retencion)) {
          tiposRetencion.value = response.tipos_retencion;
        } else if (response.data && Array.isArray(response.data)) {
          tiposRetencion.value = response.data;
        } else if (Array.isArray(response)) {
          tiposRetencion.value = response;
        } else {
          tiposRetencion.value = [];
        }
      } catch (error) {
        console.error('Error al buscar tipos de retención:', error);
        toast.error('Error al buscar tipos de retención');
        tiposRetencion.value = [];
      } finally {
        cargando.value = false;
      }
    };
    
    // Cargar datos al montar el componente
    onMounted(() => {
      cargarTiposRetencion();
    });
    
    return {
      tiposRetencion,
      tiposRetencionFiltrados,
      cargando,
      terminoBusqueda,
      paginaActual,
      elementosPorPagina,
      totalPaginas,
      paginasVisibles,
      modalVisible,
      modoEdicion,
      formulario,
      guardando,
      modalConfirmacionVisible,
      tipoRetencionAEliminar,
      eliminando,
      mensaje,
      toastVisible,
      cargarTiposRetencion,
      abrirModalCrear,
      editarTipoRetencion,
      cerrarModal,
      guardarTipoRetencion,
      confirmarEliminacion,
      cerrarModalConfirmacion,
      eliminarTipoRetencion,
      mostrarMensaje,
      cerrarMensaje,
      limpiarBusqueda,
      debounceSearch,
      cambiarPagina,
      buscarTiposRetencion
    };
  }
});
</script> 