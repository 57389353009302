import configService from './config';

// Servicio para gestionar facturas de compra
const facturasCompraService = {
  // Obtener la URL base con los datos de configuración
  async getBaseUrl() {
    // Obtenemos la configuración
    const config = await configService.getConfig();
    
    // Formamos la URL correcta usando las variables de configuración
    return `${config.servidor}/${config.instancia}/simplegest_dat_dat/v1`;
  },

  // Obtener la API key desde la configuración
  async getApiKey() {
    return await configService.getApiKey();
  },

  // Obtener todas las facturas de compra con posibilidad de filtrado
  async getAll(params = {}) {
    const baseUrl = await this.getBaseUrl();
    const apiKey = await this.getApiKey();
    
    // Construir la URL con los parámetros de búsqueda
    let url = `${baseUrl}/facturas_compra?api_key=${apiKey}`;
    
    // Añadir parámetros de filtro si existen
    if (params.filter) {
      if (params.filter.proveedor) {
        url += `&filter%5Bproveedor%5D=${encodeURIComponent(params.filter.proveedor)}`;
      }
      if (params.filter.año) {
        url += `&filter%5Ba%C3%B1o%5D=${encodeURIComponent(params.filter.año)}`;
      }
      if (params.filter.serie) {
        url += `&filter%5Bserie%5D=${encodeURIComponent(params.filter.serie)}`;
      }
    }
    
    console.log('Obteniendo facturas de compra, URL:', url);
    
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    });

    const data = await response.json();
    console.log('Respuesta obtención facturas de compra:', data);
    return data;
  },

  // Obtener una factura de compra por ID
  async getById(id) {
    const baseUrl = await this.getBaseUrl();
    const apiKey = await this.getApiKey();
    const url = `${baseUrl}/facturas_compra/${id}?api_key=${apiKey}`;
    console.log('Obteniendo factura de compra por ID, URL:', url);
    
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    });

    const data = await response.json();
    console.log('Respuesta obtención factura de compra:', data);
    return data;
  },

  // Crear una nueva factura de compra
  async create(facturaCompra) {
    const baseUrl = await this.getBaseUrl();
    const apiKey = await this.getApiKey();
    const url = `${baseUrl}/facturas_compra?api_key=${apiKey}`;

    // Eliminar las propiedades
    delete facturaCompra.name;
    delete facturaCompra.cif;
    delete facturaCompra.base_total;
    delete facturaCompra.impuesto_total;
    delete facturaCompra.base_retencion;
    delete facturaCompra.porcentaje_retencion;
    delete facturaCompra.retencion_total;
    delete facturaCompra.total;

    console.log('Creando factura de compra, URL:', url);
    console.log('Datos a enviar:', facturaCompra);
    

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(facturaCompra)
    });

    const data = await response.json();
    console.log('Respuesta creación factura:', data);
    return data;
  },

  // Actualizar una factura de compra existente
  async update(id, facturaCompra) {
    const baseUrl = await this.getBaseUrl();
    const apiKey = await this.getApiKey();
    const url = `${baseUrl}/facturas_compra/${id}?api_key=${apiKey}`;

    // Eliminar las propiedades
    delete facturaCompra.name;
    delete facturaCompra.cif;
    delete facturaCompra.base_total;
    delete facturaCompra.impuesto_total;
    delete facturaCompra.base_retencion;
    delete facturaCompra.porcentaje_retencion;
    delete facturaCompra.retencion_total;
    delete facturaCompra.total;


    console.log('Actualizando factura de compra, URL:', url);
    console.log('Datos a enviar:', facturaCompra);
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(facturaCompra)
    });

    const data = await response.json();
    console.log('Respuesta actualización factura:', data);
    return data;
  },

  // Eliminar una factura de compra
  async delete(id) {
    const baseUrl = await this.getBaseUrl();
    const apiKey = await this.getApiKey();
    const url = `${baseUrl}/facturas_compra/${id}?api_key=${apiKey}`;
    console.log('Eliminando factura de compra, URL:', url);
    
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json'
      }
    });

    const data = await response.json();
    console.log('Respuesta eliminación factura:', data);
    return data;
  }
};

export default facturasCompraService; 