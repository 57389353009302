<!-- Modal para seleccionar un articulo con búsqueda y paginación -->
<template>
  <div class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
    <div class="relative bg-white rounded-lg shadow-xl max-w-4xl w-full m-4">
      <div class="flex justify-between items-center p-4 border-b">
        <h3 class="text-lg font-medium">Seleccionar Articulo</h3>
        <button @click="cerrar" class="text-gray-400 hover:text-gray-500">
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <!-- Barra de búsqueda - Ahora con input y botón en línea -->
      <div class="p-4 border-b">
        <div class="flex items-center">
          <div class="relative flex-grow">
            <input
              type="text"
              v-model="filtro"
              placeholder="Buscar por nombre o CIF..."
              class="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-amber-500"
            />
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
              </svg>
            </div>
            <button
              v-if="filtro"
              @click="limpiarFiltro"
              class="absolute inset-y-0 right-0 pr-3 flex items-center"
            >
              <svg class="h-5 w-5 text-gray-400 hover:text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
              </svg>
            </button>
          </div>
          <button
            @click="buscarArticulos"
            class="ml-3 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
          >
            <svg class="h-4 w-4 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
            Buscar
          </button>
        </div>
      </div>

      <!-- Tabla de articulos -->
      <div class="max-h-80 overflow-y-auto">
        <div v-if="cargando" class="p-10 text-center">
          <svg class="animate-spin h-10 w-10 mx-auto text-amber-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          <p class="mt-3 text-gray-600">Cargando articulos...</p>
        </div>
        <div v-else>
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nombre</th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">CIF</th>
                <th scope="col" class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Acción</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-if="articulosFiltrados.length === 0">
                <td colspan="4" class="px-6 py-4 text-center text-sm text-gray-500">
                  No se encontraron articulos con los criterios de búsqueda.
                </td>
              </tr>
              <tr v-for="articulo in articulosFiltrados" :key="articulo.id" class="hover:bg-gray-50 cursor-pointer" @click="seleccionarArticulo(articulo)">
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{{ articulo.id }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ articulo.name }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ articulo.cif }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button 
                    @click.stop="seleccionarArticulo(articulo)" 
                    class="text-amber-600 hover:text-amber-900"
                  >
                    Seleccionar
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Paginación -->
      <div class="px-4 py-3 border-t border-gray-200">
        <div class="flex items-center justify-between">
          <div>
            <p class="text-sm text-gray-700">
              Mostrando 
              <span class="font-medium">{{ inicioPagina }}</span>
              a
              <span class="font-medium">{{ finPagina }}</span>
              de
              <span class="font-medium">{{ totalArticulosFiltrados }}</span>
              resultados
            </p>
          </div>
          <div>
            <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Paginación">
              <button
                @click="cambiarPagina(1)"
                :disabled="paginaActual === 1"
                :class="[
                  'relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500',
                  paginaActual === 1 
                    ? 'opacity-50 cursor-not-allowed' 
                    : 'hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-amber-500 focus:border-amber-500'
                ]"
              >
                <span class="sr-only">Primera</span>
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
              </button>
              <button
                @click="cambiarPagina(paginaActual - 1)"
                :disabled="paginaActual === 1"
                :class="[
                  'relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500',
                  paginaActual === 1 
                    ? 'opacity-50 cursor-not-allowed' 
                    : 'hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-amber-500 focus:border-amber-500'
                ]"
              >
                <span class="sr-only">Anterior</span>
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
              </button>
              
              <!-- Números de página -->
              <template v-for="pagina in paginasVisibles" :key="pagina">
                <span
                  v-if="pagina === '...'"
                  class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"
                >
                  ...
                </span>
                <button
                  v-else
                  @click="cambiarPagina(pagina)"
                  :class="[
                    'relative inline-flex items-center px-4 py-2 border text-sm font-medium',
                    pagina === paginaActual
                      ? 'z-10 bg-amber-50 border-amber-500 text-amber-600'
                      : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                  ]"
                >
                  {{ pagina }}
                </button>
              </template>
              
              <button
                @click="cambiarPagina(paginaActual + 1)"
                :disabled="paginaActual === totalPaginas"
                :class="[
                  'relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500',
                  paginaActual === totalPaginas 
                    ? 'opacity-50 cursor-not-allowed' 
                    : 'hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-amber-500 focus:border-amber-500'
                ]"
              >
                <span class="sr-only">Siguiente</span>
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                </svg>
              </button>
              <button
                @click="cambiarPagina(totalPaginas)"
                :disabled="paginaActual === totalPaginas"
                :class="[
                  'relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500',
                  paginaActual === totalPaginas 
                    ? 'opacity-50 cursor-not-allowed' 
                    : 'hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-amber-500 focus:border-amber-500'
                ]"
              >
                <span class="sr-only">Última</span>
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                </svg>
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';
import articulosService from '../services/articulos';

export default {
  name: 'SeleccionarArticuloModal',
  emits: ['seleccionar', 'cerrar'],
  data() {
    return {
      todosLosArticulos: [], // Almacena todos los articulos sin paginar
      articulos: [], // Almacena los articulos de la página actual (ya no se usa, mantenido por compatibilidad)
      filtro: '',
      filtroAplicado: '', // Guarda el último filtro aplicado
      cargando: false,
      toast: useToast(),
      paginaActual: 1,
      registrosPorPagina: 5,
      totalRegistros: 0
    };
  },
  computed: {
    // Filtra los articulos según el término de búsqueda
    articulosFiltrados() {
      // Obtener los articulos de la página actual
      if (!this.todosLosArticulos || this.todosLosArticulos.length === 0) {
        return [];
      }
      
      const inicio = (this.paginaActual - 1) * this.registrosPorPagina;
      const fin = inicio + this.registrosPorPagina;
      return this.todosLosArticulos.slice(inicio, fin);
    },
    
    // Número total de articulos después de filtrar
    totalArticulosFiltrados() {
      return this.todosLosArticulos.length;
    },
    
    // Calcular el número total de páginas
    totalPaginas() {
      return Math.max(1, Math.ceil(this.totalArticulosFiltrados / this.registrosPorPagina));
    },
    
    // Calcular el índice del primer elemento de la página actual
    inicioPagina() {
      return this.totalArticulosFiltrados > 0 
        ? (this.paginaActual - 1) * this.registrosPorPagina + 1 
        : 0;
    },
    
    // Calcular el índice del último elemento de la página actual
    finPagina() {
      const inicio = (this.paginaActual - 1) * this.registrosPorPagina;
      return Math.min(inicio + this.articulosFiltrados.length, this.totalArticulosFiltrados);
    },
    
    // Generar un array con los números de página visibles
    paginasVisibles() {
      const paginasVisibles = [];
      const totalPags = this.totalPaginas;
      
      if (totalPags <= 7) {
        // Si hay 7 o menos páginas, mostrar todas
        for (let i = 1; i <= totalPags; i++) {
          paginasVisibles.push(i);
        }
      } else {
        // Siempre incluir la primera página
        paginasVisibles.push(1);
        
        // Si la página actual está entre las primeras 3, mostrar 1-5 ... ultima
        if (this.paginaActual <= 3) {
          for (let i = 2; i <= 5; i++) {
            paginasVisibles.push(i);
          }
          paginasVisibles.push('...');
          paginasVisibles.push(totalPags);
        } 
        // Si la página actual está entre las últimas 3, mostrar 1 ... ultima-4 - ultima
        else if (this.paginaActual >= totalPags - 2) {
          paginasVisibles.push('...');
          for (let i = totalPags - 4; i < totalPags; i++) {
            paginasVisibles.push(i);
          }
          paginasVisibles.push(totalPags);
        } 
        // Si la página actual está en medio, mostrar 1 ... actual-1, actual, actual+1 ... ultima
        else {
          paginasVisibles.push('...');
          paginasVisibles.push(this.paginaActual - 1);
          paginasVisibles.push(this.paginaActual);
          paginasVisibles.push(this.paginaActual + 1);
          paginasVisibles.push('...');
          paginasVisibles.push(totalPags);
        }
      }
      
      return paginasVisibles;
    }
  },
  created() {
    //this.cargarArticulos();
  },
  methods: {
    async cargarArticulos() {
      this.cargando = true;
      try {
        // Recuperamos todos los articulos sin paginación del servidor
        // o utilizamos params sin page/per_page para obtener todos
        const params = {};
        
        if (this.filtroAplicado.length > 0) {
          params.filter = { parts: this.filtroAplicado };
        }
        
        // Podríamos solicitar un límite alto para obtener todos los registros
        // params.per_page = 1000; 
        
        const response = await articulosService.getAll(params);
        console.log('Respuesta de articulos:', response);
        
        // Determinar la estructura correcta de la respuesta
        if (response && response.data) {
          this.todosLosArticulos = response.data;
          this.totalRegistros = response.data.length;
        } else if (response && response.articulos) {
          this.todosLosArticulos = response.articulos;
          this.totalRegistros = response.articulos.length;
        } else if (Array.isArray(response)) {
          this.todosLosArticulos = response;
          this.totalRegistros = response.length;
        } else {
          this.todosLosArticulos = [];
          this.totalRegistros = 0;
        }
        
        // Reiniciar a la primera página cuando se recargan los datos
        this.paginaActual = 1;
        
        console.log('Debug paginación:', {
          todosLosArticulos: this.todosLosArticulos.length,
          articulosFiltrados: this.articulosFiltrados.length,
          paginaActual: this.paginaActual,
          totalPaginas: this.totalPaginas
        });
      } catch (error) {
        console.error('Error al cargar articulos:', error);
        this.toast.error('Error al cargar los articulos');
        this.todosLosArticulos = [];
        this.totalRegistros = 0;
      } finally {
        this.cargando = false;
      }
    },
    
    // Método para buscar articulos cuando se hace clic en el botón de búsqueda
    buscarArticulos() {
      this.filtroAplicado = this.filtro;
      this.cargarArticulos();
    },
    
    limpiarFiltro() {
      this.filtro = '';
      this.filtroAplicado = '';
      this.cargarArticulos();
    },
    
    // Cambia la página sin hacer una nueva solicitud al servidor
    cambiarPagina(pagina) {
      if (pagina >= 1 && pagina <= this.totalPaginas && pagina !== this.paginaActual) {
        this.paginaActual = pagina;
        // No se llama a cargarArticulos() aquí
      }
    },
    
    seleccionarArticulo(articulo) {
      this.$emit('seleccionar', articulo);
    },
    
    cerrar() {
      this.$emit('cerrar');
    }
  }
};
</script>