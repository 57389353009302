<template>
  <section class="bg-gray-50 py-16">
    <div class="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="text-center mb-16">
        <h2 class="text-3xl font-extrabold text-primary sm:text-4xl">
          Funcionalidades principales
        </h2>
        <p class="mt-4 max-w-2xl mx-auto text-xl text-secondary">
          Todo lo que necesitas para gestionar tu negocio de manera eficiente
        </p>
      </div>
      
      <div class="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
        <div v-for="(feature, index) in features" :key="index" 
             class="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
          <div class="lazy-image-container h-48">
            <img :src="feature.image" :alt="feature.title" 
                 class="w-full h-full object-cover lazy-image-loading"
                 loading="lazy"
                 @load="handleImageLoad">
          </div>
          <div class="p-6">
            <h3 class="font-bold text-xl mb-2 text-primary">{{ feature.title }}</h3>
            <p class="text-secondary">{{ feature.description }}</p>
          </div>
        </div>
      </div>
      
      <div class="mt-12 text-center">
        <a href="#" class="btn btn-primary">Ver todas las funcionalidades</a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FeaturesGallery',
  data() {
    return {
      features: [
        {
          title: 'Gestión de artículos y stock',
          description: 'Control completo de inventario, categorías de productos y precios. Alertas de stock mínimo y estadísticas de rotación.',
          image: '/img/imagen02.jpg'
        },
        {
          title: 'Clientes y proveedores',
          description: 'Administra toda la información de contacto, historial de compras y condiciones comerciales personalizadas.',
          image: '/img/imagen03.jpg'
        },
        {
          title: 'Facturación completa',
          description: 'Genera presupuestos, albaranes y facturas de forma sencilla. Controla pagos y vencimientos desde el cuadro de mando.',
          image: '/img/imagen04.jpg'
        }
      ]
    };
  },
  methods: {
    handleImageLoad(event) {
      event.target.classList.remove('lazy-image-loading');
      event.target.classList.add('lazy-image-loaded');
    }
  }
}
</script> 