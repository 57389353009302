<template>
  <section class="bg-white py-16">
    <div class="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
        <div>
          <h1 class="text-5xl font-extrabold text-gray-800 tracking-tight mb-6">
            Gestión Comercial<br>
            <span class="flex items-center">simple <span class="mx-2 text-gray-500">y</span> eficiente</span>
          </h1>
          <p class="text-lg text-gray-600 mb-8">
            Simplifica la gestión de tu empresa con nuestro software intuitivo. Gestiona artículos, clientes, proveedores, facturas y más con facilidad.
          </p>
          <div class="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
            <a href="#" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-amber-500 hover:bg-amber-600">
              Comenzar ahora
            </a>
            <a href="#" class="inline-flex items-center justify-center px-5 py-3 border border-gray-300 text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
              Ver demo
            </a>
          </div>
        </div>
        <div class="rounded-lg overflow-hidden shadow-xl bg-gray-50">
          <img src="/img/imagen01.jpg" alt="Dashboard de gestión empresarial" class="w-full rounded-lg shadow-lg" loading="lazy">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HeroSection'
}
</script> 