<template>
  <div class="container mx-auto py-6 px-4 sm:px-6 lg:px-8">
    <h1 class="text-3xl font-semibold text-gray-900 mb-8">Configuración</h1>
    
    <div v-if="cargando" class="flex justify-center items-center py-12">
      <div class="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-amber-500"></div>
    </div>
    
    <form v-else @submit.prevent="guardarConfiguracion" class="bg-white shadow-lg rounded-lg overflow-hidden">
      <!-- Datos de la empresa -->
      <div class="px-6 py-4 border-b border-gray-200">
        <h2 class="text-xl font-medium text-gray-800 mb-2">Datos de la empresa</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label for="name" class="block text-sm font-medium text-gray-700">Nombre</label>
            <input 
              type="text" 
              id="name" 
              v-model="configuracion.name" 
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
              required
            >
          </div>
          
          <div>
            <label for="cif" class="block text-sm font-medium text-gray-700">CIF</label>
            <input 
              type="text" 
              id="cif" 
              v-model="configuracion.cif" 
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
              required
            >
          </div>
          
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
            <input 
              type="email" 
              id="email" 
              v-model="configuracion.email" 
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
            >
          </div>
          
          <div>
            <label for="telefono" class="block text-sm font-medium text-gray-700">Teléfono</label>
            <input 
              type="text" 
              id="telefono" 
              v-model="configuracion.telefono" 
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
            >
          </div>
          
          <div class="md:col-span-2">
            <label for="direccion" class="block text-sm font-medium text-gray-700">Dirección</label>
            <textarea 
              id="direccion" 
              v-model="configuracion.direccion" 
              rows="3" 
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
            ></textarea>
          </div>
        </div>
      </div>
      
      <!-- Datos comerciales -->
      <div class="px-6 py-4 border-b border-gray-200">
        <h2 class="text-xl font-medium text-gray-800 mb-2">Datos comerciales</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label for="serie_compras" class="block text-sm font-medium text-gray-700">Serie de compras</label>
            <div class="relative">
              <select 
                id="serie_compras" 
                v-model.number="configuracion.serie_compras" 
                class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 pl-3 pr-10 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                required
              >
                <option v-for="serie in series" :key="serie.id" :value="Number(serie.id)">{{ serie.name }}</option>
              </select>
              <button type="button" class="absolute inset-y-0 right-0 flex items-center px-2" @click="abrirSelectorSeries('compras')">
                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>
          </div>
          
          <div>
            <label for="serie_ventas" class="block text-sm font-medium text-gray-700">Serie de ventas</label>
            <div class="relative">
              <select 
                id="serie_ventas" 
                v-model.number="configuracion.serie_ventas" 
                class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 pl-3 pr-10 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                required
              >
                <option v-for="serie in series" :key="serie.id" :value="Number(serie.id)">{{ serie.name }}</option>
              </select>
              <button type="button" class="absolute inset-y-0 right-0 flex items-center px-2" @click="abrirSelectorSeries('ventas')">
                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>
          </div>
          
          <div>
            <label for="forma_pago_clientes" class="block text-sm font-medium text-gray-700">Forma de pago para clientes</label>
            <div class="relative">
              <select 
                id="forma_pago_clientes" 
                v-model.number="configuracion.forma_pago_clientes" 
                class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 pl-3 pr-10 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                required
              >
                <option v-for="forma in formasPago" :key="forma.id" :value="Number(forma.id)">{{ forma.name }}</option>
              </select>
              <button type="button" class="absolute inset-y-0 right-0 flex items-center px-2" @click="abrirSelectorFormasPago('clientes')">
                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>
          </div>
          
          <div>
            <label for="forma_pago_proveedores" class="block text-sm font-medium text-gray-700">Forma de pago para proveedores</label>
            <div class="relative">
              <select 
                id="forma_pago_proveedores" 
                v-model.number="configuracion.forma_pago_proveedores" 
                class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 pl-3 pr-10 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                required
              >
                <option v-for="forma in formasPago" :key="forma.id" :value="Number(forma.id)">{{ forma.name }}</option>
              </select>
              <button type="button" class="absolute inset-y-0 right-0 flex items-center px-2" @click="abrirSelectorFormasPago('proveedores')">
                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>
          </div>
          
          <div>
            <label for="tipo_impuesto_exento" class="block text-sm font-medium text-gray-700">Tipo de impuesto exento</label>
            <div class="relative">
              <select 
                id="tipo_impuesto_exento" 
                v-model.number="configuracion.tipo_impuesto_exento" 
                class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 pl-3 pr-10 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                required
              >
                <option v-for="tipo in tiposImpuesto" :key="tipo.id" :value="Number(tipo.id)">{{ tipo.name }}</option>
              </select>
              <button type="button" class="absolute inset-y-0 right-0 flex items-center px-2" @click="abrirSelectorTiposImpuesto">
                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Observaciones -->
      <div class="px-6 py-4 border-b border-gray-200">
        <h2 class="text-xl font-medium text-gray-800 mb-2">Observaciones</h2>
        <div>
          <textarea 
            id="observaciones" 
            v-model="configuracion.observaciones" 
            rows="3" 
            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
          ></textarea>
        </div>
      </div>
      
      <!-- Logotipo (placeholder para futura implementación) -->
      <div class="px-6 py-4 border-b border-gray-200">
        <h2 class="text-xl font-medium text-gray-800 mb-2">Logotipo</h2>
        <div class="border-2 border-dashed border-gray-300 rounded-lg p-6 flex flex-col items-center justify-center">
          <div v-if="configuracion.logotipo" class="mb-4">
            <img :src="configuracion.logotipo" alt="Logotipo de la empresa" class="max-h-32">
          </div>
          <div class="text-center">
            <button 
              type="button" 
              class="mt-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
            >
              <svg class="-ml-1 mr-2 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
              </svg>
              Seleccionar logotipo
            </button>
          </div>
        </div>
      </div>
      
      <!-- Botones de acción -->
      <div class="px-6 py-4 bg-gray-50 flex flex-col sm:flex-row-reverse gap-3">
        <button 
          type="submit" 
          class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
          :disabled="guardando"
        >
          <svg v-if="guardando" class="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          {{ guardando ? 'Guardando...' : 'Aceptar' }}
        </button>
        <button 
          type="button" 
          class="inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
          @click="restaurarValores"
        >
          Cancelar
        </button>
      </div>
    </form>
  </div>
</template>
 
<script>
import { useToast } from 'vue-toastification';
import configuracionService from '../services/configuracion';
import seriesService from '../services/series';
import formasPagoService from '../services/formasPago';
import tiposImpuestoService from '../services/tipos-impuesto';

export default {
  name: 'ConfiguracionView',
  data() {
    return {
      cargando: true,
      guardando: false,
      configuracion: {
        id: 1,
        name: '',
        cif: '',
        email: '',
        telefono: '',
        direccion: '',
        observaciones: '',
        logotipo: '',
        año: 0,
        serie_compras: 1,
        serie_ventas: 1,
        forma_pago_clientes: 1,
        forma_pago_proveedores: 1,
        tipo_impuesto_exento: 1
      },
      configuracionOriginal: {},
      series: [],
      formasPago: [],
      tiposImpuesto: [],
      toast: useToast()
    };
  },
  created() {
    this.cargarDatos();
  },
  methods: {
    async cargarDatos() {
      this.cargando = true;
      try {
        // Cargar la configuración y los datos asociados en paralelo
        const [configResponse, seriesResponse, formasPagoResponse, tiposImpuestoResponse] = await Promise.all([
          configuracionService.getConfiguracion(),
          seriesService.getAll(),
          formasPagoService.getAll(),
          tiposImpuestoService.getAll()
        ]);
        
        // Mostrar en consola los datos recibidos para depuración
        console.log('Datos de configuración recibidos:', configResponse);
        
        // Actualizar la configuración
        if (configResponse.count > 0 && configResponse.configuracion && configResponse.configuracion.length > 0) {
          console.log('DATOS de configuración recibidos:', configResponse);
          const confData = configResponse.configuracion[0]; // Acceder al primer elemento del array
          
          // Asegurar que todos los campos de la configuración estén correctamente mapeados
          this.configuracion = {
            id: confData.id || 1,
            name: confData.name || '',
            cif: confData.cif || '',
            email: confData.email || '',
            telefono: confData.telefono || '',
            direccion: confData.direccion || '',
            observaciones: confData.observaciones || '',
            logotipo: confData.logotipo || '',
            año: confData.año || 0,
            serie_compras: Number(confData.serie_compras) || 1,
            serie_ventas: Number(confData.serie_ventas) || 1,
            forma_pago_clientes: Number(confData.forma_pago_clientes) || 1,
            forma_pago_proveedores: Number(confData.forma_pago_proveedores) || 1,
            tipo_impuesto_exento: Number(confData.tipo_impuesto_exento) || 1
          };
          this.configuracionOriginal = JSON.parse(JSON.stringify(this.configuracion));
        }
        
        // Mostrar en consola las series, formas de pago y tipos de impuesto recibidos
        console.log('Series recibidas:', seriesResponse);
        console.log('Formas de pago recibidas:', formasPagoResponse);
        console.log('Tipos de impuesto recibidos:', tiposImpuestoResponse);
        
        // Actualizar las series
        if (seriesResponse && seriesResponse.series) {
          this.series = seriesResponse.series;
        }
        
        // Actualizar las formas de pago
        if (formasPagoResponse && formasPagoResponse.formas_pago) {
          this.formasPago = formasPagoResponse.formas_pago;
        }
        
        // Actualizar los tipos de impuesto
        if (tiposImpuestoResponse && tiposImpuestoResponse.tipos_impuestos) {
          this.tiposImpuesto = tiposImpuestoResponse.tipos_impuestos;
        }
        
        // Verificar que los valores de los selectores estén correctamente asignados
        console.log('Valores finales de configuración:', this.configuracion);
        console.log('Valores utilizados en los selectores:',
          'serie_compras:', this.configuracion.serie_compras,
          'serie_ventas:', this.configuracion.serie_ventas,
          'forma_pago_clientes:', this.configuracion.forma_pago_clientes,
          'forma_pago_proveedores:', this.configuracion.forma_pago_proveedores,
          'tipo_impuesto_exento:', this.configuracion.tipo_impuesto_exento
        );
        
        // Esperar a que Vue actualice las propiedades computadas
        this.$nextTick(() => {
          // Mostrar las descripciones calculadas
          console.log('Descripciones de los selectores:',
            'serie_compras:', this.serieComprasDescripcion,
            'serie_ventas:', this.serieVentasDescripcion,
            'forma_pago_clientes:', this.formaPagoClientesDescripcion,
            'forma_pago_proveedores:', this.formaPagoProveedoresDescripcion,
            'tipo_impuesto_exento:', this.tipoImpuestoExentoDescripcion
          );
        });
      } catch (error) {
        this.mostrarMensaje('Error al cargar los datos: ' + error.message, 'error');
      } finally {
        this.cargando = false;
      }
    },
    
    async guardarConfiguracion() {
      if (!this.validarFormulario()) return;
      
      this.guardando = true;
      try {
        // Preparar objeto de configuración para enviar al servidor
        const configToSave = {
          ...this.configuracion,
          // Asegurar que los campos numéricos se envían como números
          id: Number(this.configuracion.id),
          serie_compras: Number(this.configuracion.serie_compras),
          serie_ventas: Number(this.configuracion.serie_ventas),
          forma_pago_clientes: Number(this.configuracion.forma_pago_clientes),
          forma_pago_proveedores: Number(this.configuracion.forma_pago_proveedores),
          tipo_impuesto_exento: Number(this.configuracion.tipo_impuesto_exento),
          año: Number(this.configuracion.año || 0)
        };
        
        // Mostrar en consola los datos que se van a enviar
        console.log('Configuración a guardar:', configToSave);
        
        await configuracionService.updateConfiguracion(configToSave);
        this.mostrarMensaje('Configuración guardada correctamente', 'exito');
        this.configuracionOriginal = JSON.parse(JSON.stringify(this.configuracion));
      } catch (error) {
        this.mostrarMensaje('Error al guardar la configuración: ' + error.message, 'error');
      } finally {
        this.guardando = false;
      }
    },
    
    restaurarValores() {
      this.configuracion = JSON.parse(JSON.stringify(this.configuracionOriginal));
    },
    
    eliminarConfiguracion() {
      this.mostrarMensaje('Esta función no está disponible. No es posible eliminar la configuración del sistema.', 'warning');
    },
    
    validarFormulario() {
      if (!this.configuracion.name) {
        this.mostrarMensaje('El nombre de la empresa es obligatorio', 'error');
        return false;
      }
      
      if (!this.configuracion.cif) {
        this.mostrarMensaje('El CIF/NIF es obligatorio', 'error');
        return false;
      }
      
      return true;
    },
    
    mostrarMensaje(texto, tipo) {
      if (tipo === 'exito') {
        this.toast.success(texto);
      } else if (tipo === 'error') {
        this.toast.error(texto);
      } else if (tipo === 'info') {
        this.toast.info(texto);
      } else if (tipo === 'warning') {
        this.toast.warning(texto);
      }
    },
    
    abrirSelectorSeries(tipo) {
      // Placeholder para futuras mejoras en la selección de series
      console.log(`Abrir selector de series para ${tipo}`);
    },
    
    abrirSelectorFormasPago(tipo) {
      // Placeholder para futuras mejoras en la selección de formas de pago
      console.log(`Abrir selector de formas de pago para ${tipo}`);
    },
    
    abrirSelectorTiposImpuesto() {
      // Placeholder para futuras mejoras en la selección de tipos de impuesto
      console.log('Abrir selector de tipos de impuesto');
    }
  },
  computed: {
    serieComprasDescripcion() {
      const serieCompras = this.series.find(serie => serie.id === Number(this.configuracion.serie_compras));
      return serieCompras ? serieCompras.description : 'No seleccionado';
    },
    
    serieVentasDescripcion() {
      const serieVentas = this.series.find(serie => serie.id === Number(this.configuracion.serie_ventas));
      return serieVentas ? serieVentas.description : 'No seleccionado';
    },
    
    formaPagoClientesDescripcion() {
      const formaPagoClientes = this.formasPago.find(fp => fp.id === Number(this.configuracion.forma_pago_clientes));
      return formaPagoClientes ? formaPagoClientes.name : 'No seleccionado';
    },
    
    formaPagoProveedoresDescripcion() {
      const formaPagoProveedores = this.formasPago.find(fp => fp.id === Number(this.configuracion.forma_pago_proveedores));
      return formaPagoProveedores ? formaPagoProveedores.name : 'No seleccionado';
    },
    
    tipoImpuestoExentoDescripcion() {
      const tipoImpuestoExento = this.tiposImpuesto.find(ti => ti.id === Number(this.configuracion.tipo_impuesto_exento));
      return tipoImpuestoExento ? tipoImpuestoExento.name : 'No seleccionado';
    }
  }
}
</script> 