<template>
  <div>
    <div class="py-6">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <articulos></articulos>
      </div>
    </div>
  </div>
</template>

<script>
import Articulos from '../components/Articulos.vue';

export default {
  name: 'ArticulosView',
  components: {
    Articulos
  }
}
</script> 