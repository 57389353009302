<template>
  <div>
    <!-- Hero Section -->
    <hero-section></hero-section>
       
    <!-- Galería de funcionalidades -->
    <features-gallery></features-gallery>
  </div>
</template>

<script>
import HeroSection from '../components/HeroSection.vue';
import FeaturesGallery from '../components/FeaturesGallery.vue';

export default {
  name: 'HomeView',
  components: {
    HeroSection,
    FeaturesGallery
  },
  data() {
    return {
      mostrarEjemplo: true
    };
  }
}
</script> 