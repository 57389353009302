<template>
  <div>
    <div class="py-6">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <proveedores></proveedores>
      </div>
    </div>
  </div>
</template>

<script>
import Proveedores from '../components/Proveedores.vue';

export default {
  name: 'ProveedoresView',
  components: {
    Proveedores
  }
}
</script> 