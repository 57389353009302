import configService from './config';

// Servicio para gestionar tipos de retención
const tiposRetencionService = {
  // Obtener la URL base con los datos de configuración
  async getBaseUrl() {
    // Obtenemos la configuración
    const config = await configService.getConfig();
    
    // Formamos la URL correcta usando las variables de configuración
    return `${config.servidor}/${config.instancia}/simplegest_dat_dat/v1`;
  },

  // Obtener la API key desde la configuración
  async getApiKey() {
    return await configService.getApiKey();
  },

  // Obtener todos los tipos de retención
  async getAll(params = {}) {
    try {
      const baseUrl = await this.getBaseUrl();
      const apiKey = await this.getApiKey(); 
      
      // Construir la URL con los parámetros de búsqueda
      let url = `${baseUrl}/tipos_retencion?api_key=${apiKey}`;
      
      // Añadir parámetros de filtro si existen
      if (params.filter && params.filter.parts) {
        url += `&filter%5Bparts%5D=${encodeURIComponent(params.filter.parts)}`;
      }
      
      console.log('Obteniendo tipos de retención, URL:', url);
      
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      });

      if (!response.ok) {
        console.error('Error en respuesta:', response.status, response.statusText);
        throw new Error(`Error al obtener tipos de retención: ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Respuesta obtención tipos de retención:', data);
      
      // Si la API devuelve un objeto, posiblemente contenga los datos en alguna propiedad
      if (data && typeof data === 'object' && !Array.isArray(data)) {
        // Intentamos detectar una propiedad que podría contener los datos
        // Si existe una propiedad "tipos_retencion", la usamos
        if (data.tipos_retencion) {
          return data;
        }
        
        // Si no hay una propiedad específica pero hay una propiedad "data", la devolvemos envuelta
        if (data.data) {
          return { tipos_retencion: data.data };
        }
      }
      
      // Si la respuesta es directamente un array, la envolvemos para mantener consistencia
      if (Array.isArray(data)) {
        return { tipos_retencion: data };
      }
      
      // En cualquier otro caso, devolvemos un objeto con array vacío
      return { tipos_retencion: [] };
    } catch (error) {
      console.warn('Error al obtener tipos de retención desde la API, usando datos de prueba locales:', error);
      
      
      // Si todo falla, devolver objeto con array vacío
      return { tipos_retencion: [] };
    }
  },
 
  // Obtener un tipo de retención por ID
  async getById(id) {
    const baseUrl = await this.getBaseUrl();
    const apiKey = await this.getApiKey();
    const url = `${baseUrl}/tipos_retencion/${id}?api_key=${apiKey}`;
    console.log('Obteniendo tipo de retención por ID, URL:', url);
    
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    });

    if (!response.ok) {
      console.error('Error en respuesta:', response.status, response.statusText);
      throw new Error(`Error al obtener tipo de retención: ${response.statusText}`);
    }

    const data = await response.json();
    console.log('Respuesta obtención tipo de retención:', data);
    return data;
  },

  // Crear un nuevo tipo de retención
  async create(tipoRetencion) {
    const baseUrl = await this.getBaseUrl();
    const apiKey = await this.getApiKey();
    const url = `${baseUrl}/tipos_retencion?api_key=${apiKey}`;
    console.log('Creando tipo de retención, URL:', url);
    console.log('Datos a enviar:', tipoRetencion);
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(tipoRetencion)
    });

    if (!response.ok) {
      console.error('Error en respuesta:', response.status, response.statusText);
      throw new Error(`Error al crear tipo de retención: ${response.statusText}`);
    }

    const data = await response.json();
    console.log('Respuesta creación:', data);
    return data;
  },

  // Actualizar un tipo de retención existente
  async update(id, tipoRetencion) {
    const baseUrl = await this.getBaseUrl();
    const apiKey = await this.getApiKey();
    const url = `${baseUrl}/tipos_retencion/${id}?api_key=${apiKey}`;
    console.log('Actualizando tipo de retención, URL:', url);
    console.log('Datos a enviar:', tipoRetencion);
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(tipoRetencion)
    });

    if (!response.ok) {
      console.error('Error en respuesta:', response.status, response.statusText);
      throw new Error(`Error al actualizar tipo de retención: ${response.statusText}`);
    }

    const data = await response.json();
    console.log('Respuesta actualización:', data);
    return data;
  },

  // Eliminar un tipo de retención
  async delete(id) {
    const baseUrl = await this.getBaseUrl();
    const apiKey = await this.getApiKey();
    const url = `${baseUrl}/tipos_retencion/${id}?api_key=${apiKey}`;
    console.log('Eliminando tipo de retención, URL:', url);
    
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json'
      }
    });

    if (!response.ok) {
      console.error('Error en respuesta:', response.status, response.statusText);
      throw new Error(`Error al eliminar tipo de retención: ${response.statusText}`);
    }

    const data = await response.json();
    console.log('Respuesta eliminación:', data);
    return data;
  }
};

export default tiposRetencionService; 