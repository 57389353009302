<template>
  <div class="container mx-auto py-6">
    <!-- Cabecera con título y botón de nuevo -->
    <div class="flex justify-between items-center mb-6">
      <h1 class="text-2xl font-semibold text-gray-900">Clientes</h1>
      <button
        @click="abrirModalCliente()"
        class="bg-amber-500 hover:bg-amber-600 text-white px-4 py-2 rounded-lg flex items-center"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
        </svg>
        Nuevo Cliente
      </button>
    </div>

    <!-- Barra de búsqueda -->
    <div class="mb-6 flex gap-4">
      <div class="flex-1">
        <div class="relative">
          <input
            type="text"
            v-model="filtro"
            @input="buscarClientes"
            placeholder="Buscar por nombre o CIF..."
            class="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-amber-500"
          />
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
            </svg>
          </div>
          <button
            v-if="filtro"
            @click="limpiarFiltro"
            class="absolute inset-y-0 right-0 pr-3 flex items-center"
          >
            <svg class="h-5 w-5 text-gray-400 hover:text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
            </svg>
          </button>
        </div>
      </div>
      <button
        @click="buscarClientes"
        class="bg-amber-500 hover:bg-amber-600 text-white px-6 py-2 rounded-lg"
      >
        Buscar
      </button>
    </div>

    <!-- Tabla de tipos de gasto -->
    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div v-if="cargando" class="p-10 text-center">
        <svg class="animate-spin h-10 w-10 mx-auto text-amber-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <p class="mt-3 text-gray-600">Cargando clientes...</p>
      </div>
      <div v-else>
        <!-- Vista de tabla para pantallas medianas y grandes -->
        <div class="hidden md:block">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nombre</th>
                <th scope="col" class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Acciones</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-if="clientesFiltrados.length === 0">
                <td colspan="4" class="px-6 py-4 text-center text-sm text-gray-500">
                  No se encontraron clientes. 
                  <button @click="abrirModalCrear" class="text-amber-600 hover:text-amber-900">Crear uno nuevo</button>
                </td>
              </tr>
              <tr v-for="cliente in clientesFiltrados" :key="cliente.id" class="hover:bg-gray-50">
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{{ cliente.id }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ cliente.name }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button 
                    @click="abrirModalCliente(cliente)" 
                    class="text-amber-600 hover:text-amber-900 mx-2"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                    </svg>
                  </button>
                  <button 
                    @click="confirmarEliminar(cliente)" 
                    class="text-red-600 hover:text-red-900 mx-2"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <!-- Vista de tarjetas para pantallas pequeñas -->
        <div class="md:hidden">
          <ul class="divide-y divide-gray-200">
            <li v-if="clientesFiltrados.length === 0" class="py-4 px-4 text-center text-sm text-gray-500">
              No se encontraron clientes. 
              <button @click="abrirModalCrear" class="text-amber-600 hover:text-amber-900">Crear uno nuevo</button>
            </li>
            <li v-for="cliente in clientesFiltrados" :key="cliente.id" class="py-4 px-4">
              <div class="flex items-center justify-between">
                <div>
                  <p class="text-sm font-medium text-gray-900">ID: {{ cliente.id }}</p>
                  <p class="text-sm text-gray-500">Nombre: {{ cliente.name }}</p>
                </div>
                <div class="flex items-center">
                  <button 
                    @click="abrirModalCliente(cliente)" 
                    class="text-amber-600 hover:text-amber-900 mx-2"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                    </svg>
                  </button>
                  <button 
                    @click="confirmarEliminar(cliente)" 
                    class="text-red-600 hover:text-red-900 mx-2"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        
        <!-- Paginación -->
        <div v-if="clientes.length > elementosPorPagina" class="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
          <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p class="text-sm text-gray-700">
                Mostrando 
                <span class="font-medium">{{ (paginaActual - 1) * elementosPorPagina + 1 }}</span>
                a
                <span class="font-medium">{{ Math.min(paginaActual * elementosPorPagina, clientes.length) }}</span>
                de
                <span class="font-medium">{{ clientes.length }}</span>
                resultados
              </p>
            </div>
            <div>
              <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Paginación">
                <button
                  @click="cambiarPagina(1)"
                  :disabled="paginaActual === 1"
                  :class="[paginaActual === 1 ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:bg-gray-50', 'relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium']"
                >
                  <span class="sr-only">Primera página</span>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                  </svg>
                </button>
                <button
                  @click="cambiarPagina(paginaActual - 1)"
                  :disabled="paginaActual === 1"
                  :class="[paginaActual === 1 ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:bg-gray-50', 'relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium']"
                >
                  <span class="sr-only">Anterior</span>
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                  </svg>
                </button>
                <button
                  v-for="pagina in paginasVisibles"
                  :key="pagina"
                  @click="cambiarPagina(pagina)"
                  :class="[pagina === paginaActual ? 'z-10 bg-amber-50 border-amber-500 text-amber-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50', 'relative inline-flex items-center px-4 py-2 border text-sm font-medium']"
                >
                  {{ pagina }}
                </button>
                <button
                  @click="cambiarPagina(paginaActual + 1)"
                  :disabled="paginaActual === totalPaginas"
                  :class="[paginaActual === totalPaginas ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:bg-gray-50', 'relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium']"
                >
                  <span class="sr-only">Siguiente</span>
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  </svg>
                </button>
                <button
                  @click="cambiarPagina(totalPaginas)"
                  :disabled="paginaActual === totalPaginas"
                  :class="[paginaActual === totalPaginas ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:bg-gray-50', 'relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium']"
                >
                  <span class="sr-only">Última página</span>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M4.293 15.707a1 1 0 001.414 0l5-5a1 1 0 000-1.414l-5-5a1 1 0 00-1.414 1.414L8.586 10 4.293 14.293a1 1 0 000 1.414zm6 0a1 1 0 001.414 0l5-5a1 1 0 000-1.414l-5-5a1 1 0 00-1.414 1.414L15.586 10l-4.293 4.293a1 1 0 000 1.414z" clip-rule="evenodd" />
                  </svg>
                </button>
              </nav>
            </div>
          </div>
          
          <!-- Paginación simplificada para móviles -->
          <div class="flex flex-1 justify-between sm:hidden">
            <button
              @click="cambiarPagina(paginaActual - 1)"
              :disabled="paginaActual === 1"
              :class="[paginaActual === 1 ? 'text-gray-300 cursor-not-allowed' : 'text-amber-600 hover:text-amber-900', 'relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md bg-white']"
            >
              Anterior
            </button>
            <div class="text-sm text-gray-700 py-2">
              <span class="font-medium">{{ paginaActual }}</span>
              de
              <span class="font-medium">{{ totalPaginas }}</span>
            </div>
            <button
              @click="cambiarPagina(paginaActual + 1)"
              :disabled="paginaActual === totalPaginas"
              :class="[paginaActual === totalPaginas ? 'text-gray-300 cursor-not-allowed' : 'text-amber-600 hover:text-amber-900', 'relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md bg-white']"
            >
              Siguiente
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal de Cliente -->
    <div v-if="mostrarModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div class="relative bg-white rounded-lg shadow-xl max-w-4xl w-full m-4">
        <div class="flex justify-between items-center p-4 border-b">
          <h3 class="text-lg font-medium">{{ modoEdicion ? 'Editar' : 'Nuevo' }} Cliente</h3>
          <button @click="cerrarModal" class="text-gray-400 hover:text-gray-500">
            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <form @submit.prevent="guardarCliente" class="p-4">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <!-- Datos básicos -->
            <div class="space-y-4">
              <div>
                <label class="block text-sm font-medium text-gray-700">Nombre</label>
                <input
                  type="text"
                  v-model="clienteActual.name"
                  required
                  class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-amber-500 focus:border-amber-500"
                />
              </div>

              <div>
                <label class="block text-sm font-medium text-gray-700">CIF</label>
                <input
                  type="text"
                  v-model="clienteActual.cif"
                  required
                  class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-amber-500 focus:border-amber-500"
                />
              </div>

              <div>
                <label class="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  v-model="clienteActual.email"
                  class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-amber-500 focus:border-amber-500"
                />
              </div>

              <div>
                <label class="block text-sm font-medium text-gray-700">Teléfono</label>
                <input
                  type="text"
                  v-model="clienteActual.telefono"
                  class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-amber-500 focus:border-amber-500"
                />
              </div>

              <div>
                <label class="block text-sm font-medium text-gray-700">% Descuento</label>
                <input
                  type="number"
                  v-model.number="clienteActual.porcentaje_descuento"
                  step="0.01"
                  class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-amber-500 focus:border-amber-500"
                />
              </div>

              <div class="flex items-center">
                <input
                  type="checkbox"
                  v-model="clienteActual.exento_impuestos"
                  class="h-4 w-4 text-amber-600 focus:ring-amber-500 border-gray-300 rounded"
                />
                <label class="ml-2 block text-sm text-gray-900">Exento de impuestos</label>
              </div>
            </div>

            <!-- Datos adicionales -->
            <div class="space-y-4">
              <div>
                <label class="block text-sm font-medium text-gray-700">Serie</label>
                <select
                  v-model.number="clienteActual.serie"
                  required
                  class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-amber-500 focus:border-amber-500"
                >
                  <option v-for="serie in seriesVentas" :key="serie.id" :value="serie.id">
                    {{ serie.name }}
                  </option>
                </select>
              </div>

              <div>
                <label class="block text-sm font-medium text-gray-700">Forma de pago</label>
                <select
                  v-model.number="clienteActual.forma_pago"
                  required
                  class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-amber-500 focus:border-amber-500"
                >
                  <option v-for="forma in formasPago" :key="forma.id" :value="forma.id">
                    {{ forma.name }}
                  </option>
                </select>
              </div>

              <div>
                <label class="block text-sm font-medium text-gray-700">Tipo de retención</label>
                <select
                  v-model.number="clienteActual.tipo_retencion"
                  required
                  class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-amber-500 focus:border-amber-500"
                >
                  <option v-for="tipo in tiposRetencion" :key="tipo.id" :value="tipo.id">
                    {{ tipo.name }}
                  </option>
                </select>
              </div>

              <div>
                <label class="block text-sm font-medium text-gray-700">Dirección</label>
                <textarea
                  v-model="clienteActual.direccion"
                  rows="3"
                  class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-amber-500 focus:border-amber-500"
                ></textarea>
              </div>

              <div>
                <label class="block text-sm font-medium text-gray-700">Observaciones</label>
                <textarea
                  v-model="clienteActual.observaciones"
                  rows="3"
                  class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-amber-500 focus:border-amber-500"
                ></textarea>
              </div>
            </div>
          </div>

          <div class="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              @click="cerrarModal"
              class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="bg-amber-500 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
            >
              {{ modoEdicion ? 'Actualizar' : 'Crear' }}
            </button>
          </div>
        </form>
      </div>
    </div>

        <!-- Modal de confirmación para eliminar -->
    <div v-if="mostrarModalEliminar" class="fixed inset-0 z-10 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" @click="mostrarModalEliminar = false"></div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-lg">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                  Eliminar Cliente
                </h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    ¿Está seguro que desea eliminar el cliente "{{ clienteEliminar?.name }}"? Esta acción no se puede deshacer.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button 
              type="button" 
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              @click="eliminarCliente"
              :disabled="eliminando"
            >
              <svg v-if="eliminando" class="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              {{ eliminando ? 'Eliminando...' : 'Eliminar' }}
            </button>
            <button 
              type="button" 
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              @click="mostrarModalEliminar = false"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';
import clientesService from '../services/clientes';
import seriesService from '../services/series';
import formasPagoService from '../services/formasPago';
import tiposGastoService from '../services/tipos-gasto';
import tiposRetencionService from '../services/tipos-retencion';
 
export default {
  name: 'ClientesComponent',
  data() {
    return {
      clientes: [],
      series: [],
      formasPago: [],
      tiposGasto: [],
      tiposRetencion: [],
      paginaActual: 1,
      elementosPorPagina: 10,
      filtro: '',
      mostrarModal: false,
      modoEdicion: false,
      clienteActual: this.clienteVacio(),
      debounceTimeout: null,
      toast: useToast(),
      mostrarModalEliminar: false,
      clienteEliminar: null,
      eliminando: false,
      cargando: false
    };
  },
  computed: {
    // Filtrado de Pro por término de búsqueda para la vista actual
    clientesFiltrados() { 
      if (!this.clientes) return [];
      
      const inicio = (this.paginaActual - 1) * this.elementosPorPagina;
      const fin = inicio + this.elementosPorPagina;
      
      return this.clientes.slice(inicio, fin);
    },

    
    // Filtrar series que solo tengan tipos_movimientos 'V'
    seriesVentas() {
      return this.series.filter(serie => serie.tipos_movimientos === 'V');
    },

    // Total de páginas basado en la cantidad de elementos
    totalPaginas() {
      return Math.ceil(this.clientes.length / this.elementosPorPagina);
    },

    // Genera un array con los números de página a mostrar
    paginasVisibles() {
      const paginas = [];
      const mostrarPaginas = 5; // Número de páginas a mostrar
      
      let inicio = Math.max(1, this.paginaActual - Math.floor(mostrarPaginas / 2));
      let fin = Math.min(this.totalPaginas, inicio + mostrarPaginas - 1);
      
      // Ajustar el inicio si estamos cerca del final
      if (fin === this.totalPaginas && fin > mostrarPaginas) {
        inicio = Math.max(1, fin - mostrarPaginas + 1);
      }
      
      for (let i = inicio; i <= fin; i++) {
        paginas.push(i);
      }
      console.log("paginas", paginas);
      return paginas;
    }
  },
  created() {
    this.cargarDatos();
  },
  methods: {
    clienteVacio() {
      return {
        id: 0,
        name: '',
        cif: '',
        email: '',
        telefono: '',
        direccion: '',
        observaciones: '',
        porcentaje_descuento: 0,
        exento_impuestos: false,
        forma_pago: null,
        serie: null,
        tipo_retencion: null
      };
    },

    async cargarDatos() {
      try {
        this.loading = true;
        const [clientesResponse, formasPagoResponse, seriesResponse, tiposGastoResponse, tiposRetencionResponse] = 
          await Promise.all([
            clientesService.getAll(),
            formasPagoService.getAll(),
            seriesService.getAll(),
            tiposGastoService.getAll(),
            tiposRetencionService.getAll()
          ]);
        
        this.clientes = clientesResponse.clientes || [];
        this.series = seriesResponse.series || [];
        this.formasPago = formasPagoResponse.formas_pago || [];
        this.tiposGasto = tiposGastoResponse.tipos_gasto || [];
        this.tiposRetencion = tiposRetencionResponse.tipos_retencion || [];
      } catch (error) {
        console.error('Error al cargar datos:', error);
        this.$toast.error('Error al cargar los datos');
      } finally {
        this.loading = false;
      }
    },
    async cargarClientes() {
      this.cargando = true;
      try {
        const response = await clientesService.getAll();
        this.clientes = response.clientes;
      } catch (error) {
        this.mostrarMensaje('Error al cargar clientes: ' + error.message, 'error');
      } finally {
        this.cargando = false;
      }
    },
        // Función para buscar formas de pago
      async buscarClientes() {
      if (this.filtro.length > 0) {
        this.cargando = true;
        try {
          const response = await clientesService.getAll({ 
            filter: { parts: this.filtro } 
          });
          this.clientes = response.clientes;
        } catch (error) {
          this.mostrarMensaje('Error al buscar clientes: ' + error.message, 'error');
        } finally { 
          this.cargando = false;
        }
      } else {
        this.cargarClientes();
      }
    },

    limpiarFiltro() {
      this.filtro = '';
      this.cargarClientes();
    },

    abrirModalCliente(cliente = null) {
      this.modoEdicion = !!cliente;
      this.clienteActual = cliente ? { ...cliente } : this.clienteVacio();
      this.mostrarModal = true;
    },

    cerrarModal() {
      this.mostrarModal = false;
      this.clienteActual = this.clienteVacio();
    },
    // Cambiar de página
    cambiarPagina(pagina) {
      this.paginaActual = pagina;
    },
    async guardarCliente() {
      try {
        if (!this.validarFormulario()) return;

        if (this.clienteActual.id) {
          await clientesService.update(this.clienteActual.id, this.clienteActual);
          console.log("Cliente actualizado correctamente",this.clienteActual);
          this.toast.success('Cliente actualizado correctamente');
        } else {
          await clientesService.create(this.clienteActual);
          this.toast.success('Cliente creado correctamente');
        }

        this.cerrarModal();
        await this.cargarDatos();
      } catch (error) {
        console.error('Error al guardar cliente:', error);
        this.toast.error('Error al guardar el cliente');
      }
    },

    // Eliminar forma de pago
    async eliminarCliente() {
      if (!this.clienteEliminar) return;
      
      this.eliminando = true;
      try {
        await clientesService.delete(this.clienteEliminar.id);
        this.toast.success(`Cliente "${this.clienteEliminar.name}" eliminado correctamente`);
        this.mostrarModalEliminar = false;
        this.clienteEliminar = null;
        await this.cargarDatos();
      } catch (error) {
        console.error('Error al eliminar cliente:', error);
        this.toast.error('Error al eliminar el cliente');
      } finally {
        this.eliminando = false;
      }
    },
    // Confirmar eliminación de cliente
    confirmarEliminar(cliente) {
      console.log("ESTOY EN confirmarEliminar", cliente);
      this.clienteEliminar = cliente;
      this.mostrarModalEliminar = true;
    },
    validarFormulario() {
      // Implementa la lógica para validar el formulario
      return true; // Devuelve true si el formulario es válido, false en caso contrario
    }
  }
};
</script> 