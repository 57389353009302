import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Home.vue'
import FormasPagoView from '../views/FormasPagoView.vue'
import SeriesView from '../views/SeriesView.vue'
import AnosView from '../views/AnosView.vue'
import TiposGastoView from '../views/TiposGastoView.vue'
import TiposImpuestoView from '../views/TiposImpuestoView.vue'
import TiposRetencionView from '../views/TiposRetencionView.vue'
import ConfiguracionView from '../views/ConfiguracionView.vue'
import ProveedoresView from '../views/ProveedoresView.vue'
import ClientesView from '../views/ClientesView.vue'
import ArticulosView from '../views/ArticulosView.vue'
import FacturasCompraView from '../views/FacturasCompraView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/clientes',
    name: 'Clientes',
    component: ClientesView,
    meta: { requiresAuth: true }
  },
  {
    path: '/articulos',
    name: 'Articulos',
    component: ArticulosView,
    meta: { requiresAuth: true }
  },
  {
    path: '/proveedores',
    name: 'Proveedores',
    component: ProveedoresView,
    meta: { requiresAuth: true }
  },
  {
    path: '/facturas-compra',
    name: 'FacturasCompra',
    component: FacturasCompraView,
    meta: { requiresAuth: true }
  },
  {
    path: '/formas-pago',
    name: 'FormasPago',
    component: FormasPagoView,
    meta: { requiresAuth: true }
  },
  {
    path: '/series',
    name: 'Series',
    component: SeriesView,
    meta: { requiresAuth: true }
  },
  {
    path: '/anos',
    name: 'Anos',
    component: AnosView,
    meta: { requiresAuth: true }
  },
  {
    path: '/tipos-gasto',
    name: 'TiposGasto',
    component: TiposGastoView,
    meta: { requiresAuth: true }
  },
  {
    path: '/tipos-impuesto',
    name: 'TiposImpuesto',
    component: TiposImpuestoView,
    meta: { requiresAuth: true }
  },
  {
    path: '/tipos-retencion',
    name: 'TiposRetencion',
    component: TiposRetencionView,
    meta: { requiresAuth: true }
  },
  {
    path: '/configuracion',
    name: 'Configuracion',
    component: ConfiguracionView,
    meta: { requiresAuth: true }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Guardia de navegación para verificar autenticación
router.beforeEach((to, from, next) => {
  const authService = require('../services/auth').default;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = authService.isAuthenticated();

  if (requiresAuth && !isAuthenticated) {
    // Si la ruta requiere autenticación y el usuario no está autenticado,
    // mostrar el modal de login (esto se maneja en App.vue)
    next('/');
  } else {
    next();
  }
})

export default router 