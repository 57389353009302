<template>
  <div class="container mx-auto py-6">
    <!-- Cabecera con título y botón de nuevo -->
    <div class="flex justify-between items-center mb-6">
      <h1 class="text-2xl font-semibold text-gray-900">Facturas de Compra</h1>
      <button
        @click="abrirModalFactura()"
        class="bg-amber-500 hover:bg-amber-600 text-white px-4 py-2 rounded-lg flex items-center"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
        </svg>
        Nueva Factura
      </button>
    </div>

    <!-- Filtros -->
    <div class="mb-6 bg-white p-4 rounded-lg shadow">
      <h2 class="text-lg font-medium mb-4">Filtros</h2>
      <div class="flex flex-wrap items-end gap-4">
        <!-- Proveedor -->
        <div class="flex-grow min-w-[200px]">
          <label class="block text-sm font-medium text-gray-700 mb-1">Proveedor</label>
          <div class="flex">
            <div class="flex-grow">
              <input 
                type="text" 
                v-model="filtroProveedorNombre" 
                readonly
                placeholder="Seleccionar proveedor..."
                class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-amber-500 focus:border-amber-500"
              />
            </div>
            <button 
              @click="abrirModalSeleccionarProveedor"
              class="ml-2 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
            </button>
            <button 
              v-if="filtroProveedor"
              @click="limpiarFiltroProveedor"
              class="ml-2 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
        
        <!-- Serie -->
        <div class="w-full sm:w-[180px]">
          <label class="block text-sm font-medium text-gray-700 mb-1">Serie</label>
          <select 
            v-model="filtroSerie"
            class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-amber-500 focus:border-amber-500"
          >
            <option value="">Todas las series</option>
            <option v-for="serie in series" :key="serie.id" :value="serie.id">{{ serie.name }}</option>
          </select>
        </div>

        <!-- Fecha Desde -->
        <div class="w-full sm:w-[180px]">
          <label class="block text-sm font-medium text-gray-700 mb-1">Fecha Desde</label>
          <input 
            type="date"
            v-model="filtroFechaDesde"
            class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-amber-500 focus:border-amber-500"
          />
        </div>

        <!-- Fecha Hasta -->
        <div class="w-full sm:w-[180px]">
          <label class="block text-sm font-medium text-gray-700 mb-1">Fecha Hasta</label>
          <input 
            type="date"
            v-model="filtroFechaHasta"
            class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-amber-500 focus:border-amber-500"
          />
        </div>

        <!-- Botones de acción -->
        <div class="flex gap-2 w-full sm:w-auto sm:ml-auto mt-4 sm:mt-0">
          <button 
            @click="limpiarFiltros"
            class="flex-1 sm:flex-none inline-flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
          >
            Limpiar
          </button>
          <button 
            @click="buscarFacturas"
            class="flex-1 sm:flex-none inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
          >
            Buscar
          </button>
        </div>
      </div>
    </div>

    <!-- Tabla de facturas -->
    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div v-if="cargando" class="p-10 text-center">
        <svg class="animate-spin h-10 w-10 mx-auto text-amber-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <p class="mt-3 text-gray-600">Cargando facturas...</p>
      </div>
      <div v-else>
        <!-- Vista de tabla para pantallas medianas y grandes -->
        <div class="hidden md:block">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nº Factura</th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Fecha</th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Proveedor</th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Base Imponible</th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total</th>
                <th scope="col" class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Acciones</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-if="facturasFiltradas.length === 0">
                <td colspan="7" class="px-6 py-4 text-center text-sm text-gray-500">
                  No se encontraron facturas. 
                  <button @click="abrirModalFactura()" class="text-amber-600 hover:text-amber-900">Crear una nueva</button>
                </td>
              </tr>
              <tr v-for="factura in facturasFiltradas" :key="factura.id" class="hover:bg-gray-50">
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ factura.numero_factura_editado }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ formatearFecha(factura.fecha_factura) }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ obtenerNombreProveedor(factura.proveedor) }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ formatearImporte(factura.base_total) }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{{ formatearImporte(factura.total) }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button 
                    @click="abrirModalFactura(factura)" 
                    class="text-amber-600 hover:text-amber-900 mx-2"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                    </svg>
                  </button>
                  <button 
                    @click="confirmarEliminar(factura)" 
                    class="text-red-600 hover:text-red-900 mx-2"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <!-- Vista de tarjetas para pantallas pequeñas -->
        <div class="md:hidden">
          <ul class="divide-y divide-gray-200">
            <li v-if="facturasFiltradas.length === 0" class="py-4 px-4 text-center text-sm text-gray-500">
              No se encontraron facturas. 
              <button @click="abrirModalFactura()" class="text-amber-600 hover:text-amber-900">Crear una nueva</button>
            </li>
            <li v-for="factura in facturasFiltradas" :key="factura.id" class="py-4 px-4">
              <div class="flex flex-col">
                <div class="flex justify-between items-center mb-2">
                  <span class="text-sm font-medium text-gray-900">{{ obtenerNombreSerie(factura.serie) }} - {{ factura.numero_factura_editado }}</span>
                  <span class="text-sm text-gray-500">{{ formatearFecha(factura.fecha_factura) }}</span>
                </div>
                <p class="text-sm text-gray-500 mb-1">Proveedor: {{ obtenerNombreProveedor(factura.proveedor) }}</p>
                <div class="flex justify-between items-center">
                  <span class="text-sm font-medium text-gray-900">Total: {{ formatearImporte(factura.total) }}</span>
                  <div class="flex items-center">
                    <button 
                      @click="abrirModalFactura(factura)" 
                      class="text-amber-600 hover:text-amber-900 mx-2"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                      </svg>
                    </button>
                    <button 
                      @click="confirmarEliminar(factura)" 
                      class="text-red-600 hover:text-red-900 mx-2"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Paginación -->
    <div v-if="totalFacturas > elementosPorPagina" class="px-4 py-3 flex items-center justify-between border-t border-gray-200 bg-white sm:px-6 mt-4 rounded-lg shadow">
      <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p class="text-sm text-gray-700">
            Mostrando 
            <span class="font-medium">{{ (paginaActual - 1) * elementosPorPagina + 1 }}</span>
            a
            <span class="font-medium">{{ Math.min(paginaActual * elementosPorPagina, totalFacturas) }}</span>
            de
            <span class="font-medium">{{ totalFacturas }}</span>
            resultados
          </p>
        </div>
        <div>
          <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Paginación">
            <button
              @click="cambiarPagina(1)"
              :disabled="paginaActual === 1"
              :class="[paginaActual === 1 ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:bg-gray-50', 'relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium']"
            >
              <span class="sr-only">Primera página</span>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
              </svg>
            </button>
            <button
              @click="cambiarPagina(paginaActual - 1)"
              :disabled="paginaActual === 1"
              :class="[paginaActual === 1 ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:bg-gray-50', 'relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium']"
            >
              <span class="sr-only">Anterior</span>
              <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
              </svg>
            </button>
            <button
              v-for="pagina in paginasVisibles"
              :key="pagina"
              @click="cambiarPagina(pagina)"
              :class="[pagina === paginaActual ? 'z-10 bg-amber-50 border-amber-500 text-amber-600' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50', 'relative inline-flex items-center px-4 py-2 border text-sm font-medium']"
            >
              {{ pagina }}
            </button>
            <button
              @click="cambiarPagina(paginaActual + 1)"
              :disabled="paginaActual === totalPaginas"
              :class="[paginaActual === totalPaginas ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:bg-gray-50', 'relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium']"
            >
              <span class="sr-only">Siguiente</span>
              <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
              </svg>
            </button>
            <button
              @click="cambiarPagina(totalPaginas)"
              :disabled="paginaActual === totalPaginas"
              :class="[paginaActual === totalPaginas ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:bg-gray-50', 'relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium']"
            >
              <span class="sr-only">Última página</span>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M4.293 15.707a1 1 0 001.414 0l5-5a1 1 0 000-1.414l-5-5a1 1 0 00-1.414 1.414L8.586 10 4.293 14.293a1 1 0 000 1.414zm6 0a1 1 0 001.414 0l5-5a1 1 0 000-1.414l-5-5a1 1 0 00-1.414 1.414L15.586 10l-4.293 4.293a1 1 0 000 1.414z" clip-rule="evenodd" />
              </svg>
            </button>
          </nav>
        </div>
      </div>
      
      <!-- Paginación simplificada para móviles -->
      <div class="flex flex-1 justify-between sm:hidden">
        <button
          @click="cambiarPagina(paginaActual - 1)"
          :disabled="paginaActual === 1"
          :class="[paginaActual === 1 ? 'text-gray-300 cursor-not-allowed' : 'text-amber-600 hover:text-amber-900', 'relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md bg-white']"
        >
          Anterior
        </button>
        <div class="text-sm text-gray-700 py-2">
          <span class="font-medium">{{ paginaActual }}</span>
          de
          <span class="font-medium">{{ totalPaginas }}</span>
        </div>
        <button
          @click="cambiarPagina(paginaActual + 1)"
          :disabled="paginaActual === totalPaginas"
          :class="[paginaActual === totalPaginas ? 'text-gray-300 cursor-not-allowed' : 'text-amber-600 hover:text-amber-900', 'relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md bg-white']"
        >
          Siguiente
        </button>
      </div>
    </div>
    
    <!-- Modal de confirmación para eliminar -->
    <div v-if="mostrarModalConfirmacion" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div class="relative bg-white rounded-lg shadow-xl max-w-md w-full m-4 p-6">
        <div class="mb-4">
          <h3 class="text-lg font-medium text-gray-900">Confirmar eliminación</h3>
          <p class="text-sm text-gray-500 mt-2">
            ¿Estás seguro de que deseas eliminar esta factura de compra? Esta acción no se puede deshacer.
          </p>
        </div>
        <div class="flex justify-end">
          <button 
            @click="mostrarModalConfirmacion = false" 
            class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
          >
            Cancelar
          </button>
          <button 
            @click="eliminarFactura" 
            class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Eliminar
          </button>
        </div>
      </div>
    </div>

    <!-- Modal de Factura de Compra con campos reorganizados -->
    <div v-if="mostrarModalFactura" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center" style="z-index: 40;">
      <div class="relative bg-white rounded-lg shadow-xl max-w-6xl w-full m-4" style="min-height: 85vh; max-height: 90vh; display: flex; flex-direction: column;">
        <div class="flex justify-between items-center p-4 border-b">
          <h3 class="text-lg font-medium">{{ modoEdicion ? 'Editar' : 'Nueva' }} Factura de Compra</h3>
          <button @click="cerrarModalFactura" class="text-gray-400 hover:text-gray-500">
            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        
        <div class="p-4 sm:p-6 overflow-y-auto flex-grow" style="display: flex; flex-direction: column;">
          <form @submit.prevent="guardarFactura" class="flex flex-col h-full">
            <!-- Sección superior de datos de factura -->
            <div class="flex-none">
              <!-- Primera línea: Solo Proveedor -->
              <div class="mb-6">
                <label class="block text-sm font-medium text-gray-700 mb-1">Proveedor *</label>
                <div class="flex">
                  <div class="flex-grow">
                    <input 
                      type="text" 
                      v-model="proveedorSeleccionadoNombre" 
                      readonly
                      placeholder="Seleccionar proveedor..."
                      required
                      class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-amber-500 focus:border-amber-500"
                    />
                  </div>
                  <button 
                    type="button"
                    @click="abrirModalSeleccionarProveedorFactura"
                    class="ml-2 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                  </button>
                </div>
              </div>
              
              <!-- Segunda línea: Serie, Número, Forma de Pago y Fecha -->
              <div class="grid grid-cols-1 gap-4 sm:gap-6 mb-6 sm:grid-cols-2 md:grid-cols-4">  
                <!-- Serie -->
                <div>
                  <label for="serie" class="block text-sm font-medium text-gray-700 mb-1">Serie *</label>
                  <select 
                    id="serie"
                    v-model="facturaForm.serie"
                    required
                    class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-amber-500 focus:border-amber-500"
                  >
                    <option value="" disabled>Selecciona una serie</option>
                    <option v-for="serie in series" :key="serie.id" :value="serie.id">{{ serie.name }}</option>
                  </select>
                </div>
                
                <!-- Fecha factura -->
                <div>
                  <label for="fecha_factura" class="block text-sm font-medium text-gray-700 mb-1">Fecha Factura *</label>
                  <input 
                    type="date"
                    id="fecha_factura"
                    v-model="facturaForm.fecha_factura"
                    required
                    class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-amber-500 focus:border-amber-500"
                  />
                </div>

                <!-- Número de factura (solo edición) -->
                <div v-if="modoEdicion">
                  <label for="numero_factura" class="block text-sm font-medium text-gray-700 mb-1">Nº Factura</label>
                  <input 
                    type="text"
                    id="numero_factura"
                    v-model="facturaForm.numero_factura_editado"
                    disabled
                    class="w-full px-3 py-2 border border-gray-300 rounded-md bg-gray-100"
                  />
                </div>
                <div v-else class="hidden sm:block">
                  <!-- Espacio vacío para mantener la alineación en pantallas más grandes -->
                  <div class="h-full"></div>
                </div>
                
                <!-- Forma de pago -->
                <div>
                  <label for="forma_pago" class="block text-sm font-medium text-gray-700 mb-1">Forma de Pago *</label>
                  <select 
                    id="forma_pago"
                    v-model="facturaForm.forma_pago"
                    required
                    class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-amber-500 focus:border-amber-500"
                  >
                    <option value="" disabled>Selecciona forma de pago</option>
                    <option v-for="formaPago in formasPago" :key="formaPago.id" :value="formaPago.id">{{ formaPago.name }}</option>
                  </select>
                </div>
              </div>
            </div>
             
            <!-- Sección de Líneas de Factura - Solo visible en modo edición -->
            <div v-if="modoEdicion && facturaForm.id" class="flex-grow flex flex-col min-h-0 mt-4">
              <div class="flex flex-wrap justify-between items-center mb-4">
                <h3 class="text-lg font-medium text-gray-900 mb-2 sm:mb-0">Líneas de Factura</h3>
                <button 
                  type="button"
                  @click="abrirModalNuevaLinea" 
                  class="w-full sm:w-auto inline-flex items-center justify-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
                  </svg>
                  Nueva Línea
                </button>
              </div>
              
              <div class="bg-white overflow-auto border border-gray-200 rounded-md flex-grow">
                <div v-if="cargando" class="p-6 text-center h-full flex flex-col justify-center">
                  <svg class="animate-spin h-8 w-8 mx-auto text-amber-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  <p class="mt-2 text-gray-600">Cargando líneas...</p>
                </div>
                <div v-else>
                  <!-- Vista de tabla para pantallas medianas y grandes -->
                  <div class="hidden md:block">
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead class="bg-gray-50 sticky top-0 z-10">
                        <tr>
                          <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Artículo</th>
                          <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Descripción</th>
                          <th scope="col" class="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Cantidad</th>
                          <th scope="col" class="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Precio</th>
                          <th scope="col" class="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">% Dto.</th>
                          <th scope="col" class="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Importe</th>
                          <th scope="col" class="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">% IVA</th>
                          <th scope="col" class="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Acciones</th>
                        </tr>
                      </thead>
                      <tbody class="bg-white divide-y divide-gray-200">
                        <tr v-if="getLineasPaginadas().length === 0">
                          <td colspan="8" class="px-4 py-4 text-center text-sm text-gray-500">
                            No hay líneas en esta factura. 
                            <button type="button" @click="abrirModalNuevaLinea" class="text-amber-600 hover:text-amber-900">
                              Añadir una línea
                            </button>
                          </td>
                        </tr>
                        <tr v-for="linea in getLineasPaginadas()" :key="linea.id" class="hover:bg-gray-50">
                          <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">{{ linea.articulo }}</td>
                          <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-900">{{ linea.descripcion }}</td>
                          <td class="px-4 py-4 whitespace-nowrap text-sm text-right text-gray-500">{{ linea.cantidad }}</td>
                          <td class="px-4 py-4 whitespace-nowrap text-sm text-right text-gray-500">{{ formatearImporte(linea.precio) }}</td>
                          <td class="px-4 py-4 whitespace-nowrap text-sm text-right text-gray-500">{{ linea.porcentaje_descuento }}%</td>
                          <td class="px-4 py-4 whitespace-nowrap text-sm text-right font-medium text-gray-900">{{ formatearImporte(linea.importe) }}</td>
                          <td class="px-4 py-4 whitespace-nowrap text-sm text-right text-gray-500">{{ linea.porcentaje_impuesto }}%</td>
                          <td class="px-4 py-4 whitespace-nowrap text-right text-sm">
                            <div class="flex justify-end">
                              <button 
                                type="button"
                                @click="abrirModalEditarLinea(linea)" 
                                class="text-amber-600 hover:text-amber-900 mr-3"
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                </svg>
                              </button>
                              <button 
                                type="button"
                                @click="confirmarEliminarLinea(linea)" 
                                class="text-red-600 hover:text-red-900"
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                </svg>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <!-- Vista de tarjetas para pantallas pequeñas (móviles) -->
                  <div class="md:hidden">
                    <ul class="divide-y divide-gray-200">
                      <li v-if="getLineasPaginadas().length === 0" class="py-4 px-4 text-center text-sm text-gray-500">
                        No hay líneas en esta factura. 
                        <button type="button" @click="abrirModalNuevaLinea" class="text-amber-600 hover:text-amber-900">
                          Añadir una línea
                        </button>
                      </li>
                      <li v-for="linea in getLineasPaginadas()" :key="linea.id" class="py-4 px-4">
                        <div class="flex flex-col">
                          <div class="flex justify-between items-center mb-2">
                            <span class="text-sm font-medium text-gray-900">{{ linea.articulo }}</span>
                            <div class="flex items-center">
                              <button 
                                type="button"
                                @click="abrirModalEditarLinea(linea)" 
                                class="text-amber-600 hover:text-amber-900 mx-1"
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                </svg>
                              </button>
                              <button 
                                type="button"
                                @click="confirmarEliminarLinea(linea)" 
                                class="text-red-600 hover:text-red-900 mx-1"
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                </svg>
                              </button>
                            </div>
                          </div>
                          <p class="text-sm text-gray-500 mb-1 line-clamp-1">{{ linea.descripcion }}</p>
                          
                          <div class="grid grid-cols-2 gap-2 mt-2 text-sm">
                            <div>
                              <span class="text-gray-500">Cantidad:</span>
                              <span class="ml-1 font-medium">{{ linea.cantidad }}</span>
                            </div>
                            <div>
                              <span class="text-gray-500">Precio:</span>
                              <span class="ml-1 font-medium">{{ formatearImporte(linea.precio) }}</span>
                            </div>
                            <div>
                              <span class="text-gray-500">Dto:</span>
                              <span class="ml-1 font-medium">{{ linea.porcentaje_descuento }}%</span>
                            </div>
                            <div>
                              <span class="text-gray-500">IVA:</span>
                              <span class="ml-1 font-medium">{{ linea.porcentaje_impuesto }}%</span>
                            </div>
                          </div>
                          
                          <div class="mt-2 flex justify-end">
                            <span class="text-sm font-medium text-gray-900">Importe: {{ formatearImporte(linea.importe) }}</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              
              <!-- Paginación para líneas - adaptada para móviles -->
              <div v-if="totalLineasFactura > elementosPorPaginaLineas" class="flex flex-wrap items-center justify-between py-3 px-1">
                <div class="w-full sm:w-auto mb-3 sm:mb-0">
                  <p class="text-sm text-gray-700 text-center sm:text-left">
                    Mostrando 
                    <span class="font-medium">{{ ((paginaActualLineas - 1) * elementosPorPaginaLineas) + 1 }}</span>
                    a
                    <span class="font-medium">{{ Math.min(paginaActualLineas * elementosPorPaginaLineas, totalLineasFactura) }}</span>
                    de
                    <span class="font-medium">{{ totalLineasFactura }}</span>
                    líneas
                  </p>
                </div>
                
                <!-- Paginación para pantallas medianas y grandes -->
                <div class="hidden sm:block">
                  <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Paginación">
                    <button
                      type="button"
                      @click="cambiarPaginaLineas(paginaActualLineas - 1)"
                      :disabled="paginaActualLineas === 1"
                      :class="[
                        'relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium',
                        paginaActualLineas === 1 ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:bg-gray-50'
                      ]"
                    >
                      <span class="sr-only">Anterior</span>
                      <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                      </svg>
                    </button>
                    
                    <button
                      v-for="pagina in calcularTotalPaginasLineas()"
                      :key="pagina"
                      type="button"
                      @click="cambiarPaginaLineas(pagina)"
                      :class="[
                        'relative inline-flex items-center px-4 py-2 border text-sm font-medium',
                        pagina === paginaActualLineas 
                          ? 'z-10 bg-amber-50 border-amber-500 text-amber-600' 
                          : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                      ]"
                    >
                      {{ pagina }}
                    </button>
                    
                    <button
                      type="button"
                      @click="cambiarPaginaLineas(paginaActualLineas + 1)"
                      :disabled="paginaActualLineas === calcularTotalPaginasLineas()"
                      :class="[
                        'relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium',
                        paginaActualLineas === calcularTotalPaginasLineas() ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:bg-gray-50'
                      ]"
                    >
                      <span class="sr-only">Siguiente</span>
                      <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                      </svg>
                    </button>
                  </nav>
                </div>
                
                <!-- Paginación simplificada para móviles -->
                <div class="w-full sm:hidden flex justify-between">
                  <button
                    type="button"
                    @click="cambiarPaginaLineas(paginaActualLineas - 1)"
                    :disabled="paginaActualLineas === 1"
                    :class="[
                      'inline-flex items-center px-3 py-1 border border-gray-300 text-sm font-medium rounded-md',
                      paginaActualLineas === 1 ? 'text-gray-300 bg-gray-50 cursor-not-allowed' : 'text-amber-600 bg-white hover:bg-gray-50'
                    ]"
                  >
                    Anterior
                  </button>
                  
                  <div class="text-sm text-gray-700 py-1">
                    {{ paginaActualLineas }} / {{ calcularTotalPaginasLineas() }}
                  </div>
                  
                  <button
                    type="button"
                    @click="cambiarPaginaLineas(paginaActualLineas + 1)"
                    :disabled="paginaActualLineas === calcularTotalPaginasLineas()"
                    :class="[
                      'inline-flex items-center px-3 py-1 border border-gray-300 text-sm font-medium rounded-md',
                      paginaActualLineas === calcularTotalPaginasLineas() ? 'text-gray-300 bg-gray-50 cursor-not-allowed' : 'text-amber-600 bg-white hover:bg-gray-50'
                    ]"
                  >
                    Siguiente
                  </button>
                </div>
              </div>
            </div>
            
            <!-- Botones de acción con totales - adaptados para móviles -->
            <div class="flex flex-col md:flex-row justify-between items-start md:items-center mt-4 flex-none bg-gray-50 p-4 rounded-md">
              <!-- Totales de la factura (solo lectura) -->
              <div class="flex flex-wrap justify-center md:justify-start gap-4 md:gap-6 w-full md:w-auto mb-4 md:mb-0">
                <div class="text-sm flex flex-col items-center">
                  <span class="text-gray-500 mb-1">Base</span>
                  <span class="font-medium">{{ formatearImporte(facturaForm.base_total) }}</span>
                </div>
                <div class="text-sm flex flex-col items-center">
                  <span class="text-gray-500 mb-1">IVA</span>
                  <span class="font-medium">{{ formatearImporte(facturaForm.impuesto_total) }}</span>
                </div>
                <div class="text-sm flex flex-col items-center" v-if="facturaForm.retencion_total > 0">
                  <span class="text-gray-500 mb-1">Retención</span>
                  <span class="font-medium">{{ formatearImporte(facturaForm.retencion_total) }}</span>
                </div>
                <div class="text-sm flex flex-col items-center">
                  <span class="text-gray-700 font-bold mb-1">Total</span>
                  <span class="text-amber-600 font-bold">{{ formatearImporte(facturaForm.total) }}</span>
                </div>
              </div>
              
              <!-- Botones -->
              <div class="flex w-full md:w-auto">
                <button 
                  type="button"
                  @click="cerrarModalFactura" 
                  class="flex-1 md:flex-none mr-2 px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                >
                  Cancelar
                </button>
                <button 
                  type="submit"
                  class="flex-1 md:flex-none px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                >
                  {{ modoEdicion ? 'Actualizar' : 'Crear' }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Componente para seleccionar proveedor para el filtro -->
    <seleccionar-proveedor-modal
      v-if="mostrarModalSeleccionarProveedor"
      @seleccionar="seleccionarProveedor"
      @cerrar="mostrarModalSeleccionarProveedor = false"
    />

    <!-- Componente para seleccionar proveedor dentro del formulario de factura -->
    <seleccionar-proveedor-modal
      v-if="mostrarModalSeleccionarProveedorFactura"
      @seleccionar="seleccionarProveedorFactura"
      @cerrar="mostrarModalSeleccionarProveedorFactura = false"
      style="z-index: 50;"
    />
    
    <!-- Modal para línea de factura -->
    <linea-factura-compra-modal
      v-if="mostrarModalLineaFactura"
      :facturaId="facturaForm.id"
      :proveedorId="facturaForm.proveedor"
      :fechaFactura="facturaForm.fecha_factura"
      :linea="lineaFacturaSeleccionada"
      @guardar="guardarLineaFactura"
      @cerrar="cerrarModalLineaFactura"
    />
    
    <!-- Modal de confirmación para eliminar línea -->
    <div v-if="mostrarModalConfirmacionLinea" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center" style="z-index: 60;">
      <div class="relative bg-white rounded-lg shadow-xl max-w-md w-full m-4 p-6">
        <div class="mb-4">
          <h3 class="text-lg font-medium text-gray-900">Confirmar eliminación</h3>
          <p class="text-sm text-gray-500 mt-2">
            ¿Estás seguro de que deseas eliminar esta línea de factura? Esta acción no se puede deshacer.
          </p>
        </div>
        <div class="flex justify-end">
          <button 
            @click="mostrarModalConfirmacionLinea = false" 
            class="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
          >
            Cancelar
          </button>
          <button 
            @click="eliminarLineaFactura" 
            class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Eliminar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { useToast } from 'vue-toastification';
import facturasCompraService from '../services/facturas_compra';
import SeleccionarProveedorModal from './SeleccionarProveedorModal.vue';
import LineaFacturaCompraModal from './LineaFacturaCompraModal.vue';
import seriesService from '../services/series';
import anosService from '../services/anos';
import proveedoresService from '../services/proveedores'; 
import formasPagoService from '../services/formasPago';
import tiposGastoService from '../services/tipos-gasto';
import tiposRetencionService from '../services/tipos-retencion';
import tiposImpuestoService from '../services/tipos-impuesto';
import movimientosAlmacenService from '../services/movimientos-almacen';

export default {
  name: 'FacturasCompra',
  components: {
    SeleccionarProveedorModal,
    LineaFacturaCompraModal
  },
  data() {
    const fechaActual = new Date().toISOString().split('T')[0];
    return {
      // Datos principales
      facturas: [],
      facturasFiltradas: [],
      facturasCompra: [],
      totalFacturas: 0,
      
      // Filtros
      filtroProveedor: null,
      filtroProveedorNombre: '',
      filtroSerie: '',
      filtroAno: '',
      filtroFechaDesde: '',
      filtroFechaHasta: '',
      
      // Datos para los selectores
      series: [],
      anos: [],
      proveedores: [],
      formasPago: [],
      tiposGasto: [],
      tiposRetencion: [],
      tiposImpuesto: [],
      
      // Estado
      cargando: false,
      facturaSiendoEliminada: null,
      mostrarModalConfirmacion: false,
      mostrarModalFactura: false,
      mostrarModalSeleccionarProveedor: false,
      
      // Paginación
      paginaActual: 1,
      elementosPorPagina: 10,
      
      // Modal factura
      modoEdicion: false,
      facturaForm: {
        id: 0,
        serie: '',
        numero_factura: 0,
        numero_factura_editado: '',
        fecha: fechaActual,
        fecha_factura: fechaActual,
        proveedor: null,
        name: '' ,
        cif: '',
        forma_pago: '',
        tipo_gasto: '',
        tipo_retencion: '',
        base_total: 0,
        impuesto_total: 0,
        base_retencion: 0,
        porcentaje_retencion: 0,
        retencion_total: 0,
        total: 0
      },
      proveedorSeleccionadoNombre: '',
      mostrarModalSeleccionarProveedorFactura: false,
      
      // Líneas de factura
      lineasFactura: [],
      lineaFacturaSeleccionada: null,
      mostrarModalLineaFactura: false,
      paginaActualLineas: 1,
      elementosPorPaginaLineas: 5,
      mostrarModalConfirmacionLinea: false,
      lineaSiendoEliminada: null,
      totalLineasFactura: 0
    };
  },
  computed: {
    // Calcular el número total de páginas
    totalPaginas() {
      return Math.ceil(this.totalFacturas / this.elementosPorPagina);
    },
    
    // Calcular las páginas que se deben mostrar en la paginación
    paginasVisibles() {
      const paginas = [];
      const totalPaginas = this.totalPaginas;
      const paginaActual = this.paginaActual;
      
      // Si hay menos de 8 páginas, mostrar todas
      if (totalPaginas <= 8) {
        for (let i = 1; i <= totalPaginas; i++) {
          paginas.push(i);
        }
      } else {
        // Siempre mostrar la primera página
        paginas.push(1);
        
        // Si la página actual está cerca del principio
        if (paginaActual <= 4) {
          for (let i = 2; i <= 6; i++) {
            paginas.push(i);
          }
          paginas.push('...');
          paginas.push(totalPaginas);
        } 
        // Si la página actual está cerca del final
        else if (paginaActual >= totalPaginas - 3) {
          paginas.push('...');
          for (let i = totalPaginas - 5; i <= totalPaginas; i++) {
            paginas.push(i);
          }
        } 
        // Si la página actual está en el medio
        else {
          paginas.push('...');
          for (let i = paginaActual - 1; i <= paginaActual + 1; i++) {
            paginas.push(i);
          }
          paginas.push('...');
          paginas.push(totalPaginas);
        }
      }
      
      return paginas;
    }
  },
  async created() {
    // Cargar datos iniciales
    this.cargando = true;
    try {
      // Cargar datos maestros para los filtros y selects
      await this.cargarDatosMaestros();
      
      // Cargar facturas de compra
      await this.buscarFacturas();
    } catch (error) {
      console.error('Error al cargar datos:', error);
      this.toast.error('Error al cargar los datos');
    } finally {
      this.cargando = false;
    }
  },
  methods: {
    // Cargar todos los datos maestros necesarios
    async cargarDatosMaestros() {
      try {
        // Cargar series
        const respSeries = await seriesService.getAll();
        this.series = respSeries.series.filter(serie => serie.tipos_movimientos === 'C') || [];
        
        // Cargar años
        const respAnos = await anosService.getAll();
        this.anos = respAnos.anos || [];
        
        // Cargar formas de pago
        const respFormasPago = await formasPagoService.getAll();
        this.formasPago = respFormasPago.formas_pago || [];
        
        // Cargar tipos de gasto
        const respTiposGasto = await tiposGastoService.getAll();
        this.tiposGasto = respTiposGasto.tipos_gasto || [];
        
        // Cargar tipos de retención
        const respTiposRetencion = await tiposRetencionService.getAll();
        this.tiposRetencion = respTiposRetencion.tipos_retencion || [];

        // Cargar tipos de impuesto
        const respTiposImpuesto = await tiposImpuestoService.getAll();
        this.tiposImpuesto = respTiposImpuesto.tipos_impuesto || [];
      } catch (error) {
        console.error('Error al cargar datos maestros:', error);
        this.toast.error('Error al cargar datos de configuración');
        throw error;
      }
    },
    
    // Buscar facturas con los filtros aplicados
    async buscarFacturas() {
      this.cargando = true;
      try {
        const params = {
          filter: {}
        };
        
        // Aplicar filtros si están definidos
        if (this.filtroProveedor) {
          params.filter.proveedor = this.filtroProveedor;
        }
        
        if (this.filtroSerie) {
          params.filter.serie = this.filtroSerie;
        }
        
        // Aplicar filtros de fechas si están definidos
        if (this.filtroFechaDesde) {
          params.filter.fecha_desde = this.filtroFechaDesde;
        }
        
        if (this.filtroFechaHasta) {
          params.filter.fecha_hasta = this.filtroFechaHasta;
        }
        
        // Llamar al servicio
        const response = await facturasCompraService.getAll(params);
        //this.facturas = response.facturas_compra || [];


    // Obtener todas las facturas
    const todasFacturas = response.facturas_compra || [];

    // Filtrar por rango de fechas
    this.facturas = todasFacturas.filter(factura => {
      const fechaFactura = new Date(factura.fecha);
      
      // Comprobar si está dentro del rango
      const cumpleFechaDesde = !this.filtroFechaDesde || fechaFactura >= new Date(this.filtroFechaDesde);
      const cumpleFechaHasta = !this.filtroFechaHasta || fechaFactura <= new Date(this.filtroFechaHasta);
      
      return cumpleFechaDesde && cumpleFechaHasta;
    });


        this.totalFacturas = response.total_count || response.facturas_compra?.length || 0;
        
        // Aplicar paginación
        this.facturasFiltradas = this.aplicarPaginacion();
      } catch (error) {
        console.error('Error al buscar facturas:', error);
        this.toast.error('Error al buscar facturas');
      } finally {
        this.cargando = false;
      }
    },
    
    // Aplicar paginación a las facturas
    aplicarPaginacion() {
      const inicio = (this.paginaActual - 1) * this.elementosPorPagina;
      const fin = inicio + this.elementosPorPagina;
      return this.facturas.slice(inicio, fin);
    },
    
    // Cambiar de página
    cambiarPagina(pagina) {
      if (pagina >= 1 && pagina <= this.totalPaginas) {
        this.paginaActual = pagina;
        this.facturasFiltradas = this.aplicarPaginacion();
      }
    },
    
    // Formatear fecha (YYYY-MM-DD)
    formatearFecha(fechaStr) {
      if (!fechaStr) return '';
      const fecha = new Date(fechaStr);
      return fecha.toLocaleDateString('es-ES');
    },
    
    // Formatear fecha para input date (YYYY-MM-DD)
    formatearFechaISO(fecha) {
      if (!fecha) return '';
      const date = new Date(fecha);
      return date.toISOString().split('T')[0];
    },
    
    // Formatear importe con 2 decimales y símbolo de euro
    formatearImporte(importe) {
      return (importe || 0).toFixed(2) + ' €';
    },
    
    // Obtener nombre de serie a partir de su ID
    obtenerNombreSerie(id) {
      const serie = this.series.find(s => s.id === id);
      return serie ? serie.descripcion : `Serie ${id}`;
    },
    
    // Obtener nombre de proveedor a partir de su ID
    obtenerNombreProveedor(id) {
      const proveedor = this.proveedores.find(p => p.id === id);
      return proveedor ? proveedor.name : `Proveedor ${id}`;
    },
    
    // Abrir modal para crear/editar factura
    async abrirModalFactura(factura = null) {
      if (factura) {
        // Modo edición
        this.modoEdicion = true;
        this.facturaForm = { 
          ...factura,
          fecha: this.formatearFechaISO(factura.fecha),
          fecha_factura: this.formatearFechaISO(factura.fecha_factura)
        };
        
        // Si la factura tiene un proveedor, cargamos sus datos
        if (this.facturaForm.proveedor) {
          try {
            const response = await proveedoresService.getById(this.facturaForm.proveedor);
            console.log('RESPUESTA del proveedor:', response);
            
            // Verifica si response.proveedores es un array y tiene elementos
            if (response && response.proveedores && Array.isArray(response.proveedores) && response.proveedores.length > 0) {
              // Asignamos el primer proveedor del array
              this.proveedorSeleccionado = response.proveedores[0];
              console.log('Proveedor cargado:', this.proveedorSeleccionado);
              this.proveedorSeleccionadoNombre = this.proveedorSeleccionado.name;
              
              // Si la factura no tiene alguno de estos valores, usamos los del proveedor
              if (!this.facturaForm.serie && this.proveedorSeleccionado.serie) {
                this.facturaForm.serie = this.proveedorSeleccionado.serie;
              }
              if (!this.facturaForm.forma_pago && this.proveedorSeleccionado.forma_pago) {
                this.facturaForm.forma_pago = this.proveedorSeleccionado.forma_pago;
              }
              if (!this.facturaForm.tipo_gasto && this.proveedorSeleccionado.tipo_gasto) {
                this.facturaForm.tipo_gasto = this.proveedorSeleccionado.tipo_gasto;
              }
              if (!this.facturaForm.tipo_retencion && this.proveedorSeleccionado.tipo_retencion) {
                this.facturaForm.tipo_retencion = this.proveedorSeleccionado.tipo_retencion;
                this.actualizarPorcentajeRetencion();
              }
            } else {
              this.proveedorSeleccionado = null;
              console.log('No se encontró información del proveedor');
            }
          } catch (error) {
            console.error('Error al cargar el proveedor:', error);
            this.toast.error('Error al cargar información del proveedor');
            this.proveedorSeleccionado = null;
          }
        } else {
          this.proveedorSeleccionado = null;
        }
        
        // Cargar líneas de factura
        await this.cargarLineasFactura(factura.id);
        this.paginaActualLineas = 1;
      } else {
        // Modo creación
        this.modoEdicion = false;
        this.facturaForm = {
          id: 0,
          serie: '',
          numero_factura: 0,
          numero_factura_editado: '',
          fecha: this.formatearFechaISO(new Date()),
          fecha_factura: this.formatearFechaISO(new Date()),
          proveedor: null,
          forma_pago: '',
          tipo_gasto: '',
          tipo_retencion: '',
          base_total: 0,
          impuesto_total: 0,
          base_retencion: 0,
          porcentaje_retencion: 0,
          retencion_total: 0,
          total: 0
        };
        this.proveedorSeleccionadoNombre = '';
        this.proveedorSeleccionado = null;
        this.lineasFactura = [];
      }
      
      this.mostrarModalFactura = true;
    },
    
    // Cerrar el modal de factura
    cerrarModalFactura() {
      this.mostrarModalFactura = false;
      this.facturaForm = {
        id: 0,
        serie: '',
        numero_factura: 0,
        numero_factura_editado: '',
        fecha: this.formatearFechaISO(new Date()),
        fecha_factura: this.formatearFechaISO(new Date()),
        proveedor: null,
        forma_pago: '',
        tipo_gasto: '',
        tipo_retencion: '',
        base_total: 0,
        impuesto_total: 0,
        base_retencion: 0,
        porcentaje_retencion: 0,
        retencion_total: 0,
        total: 0
      };
      this.proveedorSeleccionadoNombre = '';
    },
    
    // Abrir el modal para seleccionar proveedor en la factura
    abrirModalSeleccionarProveedorFactura() {
      this.mostrarModalSeleccionarProveedorFactura = true;
    },
    
    // Seleccionar proveedor para la factura
    seleccionarProveedorFactura(proveedor) {
      this.facturaForm.proveedor = proveedor.id;
      this.proveedorSeleccionadoNombre = proveedor.name;
      
      // Actualizamos los campos con los datos del proveedor
      if (proveedor.serie) {
        this.facturaForm.serie = proveedor.serie;
      }
      if (proveedor.forma_pago) {
        this.facturaForm.forma_pago = proveedor.forma_pago;
      }
      if (proveedor.tipo_gasto) {
        this.facturaForm.tipo_gasto = proveedor.tipo_gasto;
      }
      if (proveedor.tipo_retencion) {
        this.facturaForm.tipo_retencion = proveedor.tipo_retencion;
        this.actualizarPorcentajeRetencion();
      }
      
      this.mostrarModalSeleccionarProveedorFactura = false;
    },
    
    // Actualizar el porcentaje de retención según el tipo seleccionado
    actualizarPorcentajeRetencion() {
      if (this.facturaForm.tipo_retencion) {
        const tipoRetencion = this.tiposRetencion.find(t => t.id === this.facturaForm.tipo_retencion);
        if (tipoRetencion) {
          this.facturaForm.porcentaje_retencion = tipoRetencion.porcentaje || 0;
        }
      } else {
        this.facturaForm.porcentaje_retencion = 0;
      }
      this.calcularTotales();
    },
    

    
    // Guardar la factura (crear o actualizar)
    async guardarFactura() {
      if (!this.facturaForm.proveedor) {
        this.toast.error('Debe seleccionar un proveedor');
        return;
      }
      console.log('ESTOY EN EL GUARDAR FACTURA: ', this.facturaForm);
      console.log("modoEdicion: ", this.modoEdicion);
      this.cargando = true;
      try {
        if (this.modoEdicion) {
          // Guardar la página actual
          const paginaActual = this.paginaActual;
          // Actualizar factura
          await facturasCompraService.update(this.facturaForm.id, this.facturaForm);
          //this.toast.success('Factura actualizada correctamente');
          
          // Cerrar modal y recargar datos manteniendo la página
          this.cerrarModalFactura();
          await this.buscarFacturas();
          // Restaurar la página
          this.paginaActual = paginaActual;
        } else {
          // Crear factura
          const respuesta = await facturasCompraService.create(this.facturaForm);
          //this.toast.success('Factura creada correctamente: ', respuesta);
          console.log('RESPUESTA de la factura creada: ', respuesta);
          // Obtener la factura recién creada
          if (respuesta && respuesta.count) {
            // Recargar facturas
            //await this.buscarFacturas();
            this.facturas = respuesta.facturas_compra;

            
            // Cargar la factura recién creada para su edición
            const facturaCreada = await facturasCompraService.getById(this.facturas[0].id);
            if (facturaCreada) {
              // Cerrar modal actual
              this.mostrarModalFactura = false;
              console.log('Factura creada VOY A ABRIR EL MODAL DE EDICION: ', facturaCreada.facturas_compra[0]);
              // Abrir modal de edición con la factura creada
              await this.abrirModalFactura(facturaCreada.facturas_compra[0]);
              
              // Abrir modal para añadir línea
              this.abrirModalNuevaLinea();
            }
          } else {
            // Si no se pudo obtener la factura, solo cerrar y recargar
            this.cerrarModalFactura();
            await this.buscarFacturas();
          }
        }
      } catch (error) {
        console.error('Error al guardar factura:', error);
        this.toast.error('Error al guardar la factura');
        this.cargando = false;
      }
    },
    
    // Abrir modal para confirmar eliminación
    confirmarEliminar(factura) {
      this.facturaSiendoEliminada = factura;
      this.mostrarModalConfirmacion = true;
    },
    
    // Eliminar factura
    async eliminarFactura() {
      if (!this.facturaSiendoEliminada) return;
      
      this.cargando = true;
      try {
        await facturasCompraService.delete(this.facturaSiendoEliminada.id);
        //this.toast.success('Factura eliminada correctamente');
        this.mostrarModalConfirmacion = false;
        this.facturaSiendoEliminada = null;
        
        // Recargar facturas
        await this.buscarFacturas();
      } catch (error) {
        console.error('Error al eliminar factura:', error);
        this.toast.error('Error al eliminar la factura');
      } finally {
        this.cargando = false;
      }
    },
    
    // Abrir modal para seleccionar proveedor
    abrirModalSeleccionarProveedor() {
      this.mostrarModalSeleccionarProveedor = true;
    },
    
    // Seleccionar proveedor desde el modal
    seleccionarProveedor(proveedor) {
      this.filtroProveedor = proveedor.id;
      this.filtroProveedorNombre = proveedor.name;
      this.mostrarModalSeleccionarProveedor = false;
    },
    
    // Limpiar filtro de proveedor
    limpiarFiltroProveedor() {
      this.filtroProveedor = null;
      this.filtroProveedorNombre = '';
    },
    
    // Limpiar todos los filtros
    limpiarFiltros() {
      this.filtroProveedor = null;
      this.filtroProveedorNombre = '';
      this.filtroSerie = '';
      this.filtroAno = '';
      this.filtroFechaDesde = '';
      this.filtroFechaHasta = '';
      this.paginaActual = 1;
    },

    // En FacturasCompra.vue
async guardarLineaFactura(resultado) {
  try {
    // Extraer datos del resultado
    const response = resultado.response;
    const modoEdicion = resultado.modoEdicion;
    
    await movimientosAlmacenService.create(response);
    this.mostrarModalLineaFactura = false;
    await this.cargarLineasFactura(this.facturaForm.id);
    await this.leerFactura(this.facturaForm.id);
    
    // Si es una nueva línea, ir a la última página
    if (!modoEdicion) {
      this.paginaActualLineas = this.calcularTotalPaginasLineas();
    }
    // Si es edición, mantener la página actual
  } catch (error) {
    console.error('Error al guardar línea de factura:', error);
    this.toast.error('Error al guardar la línea de factura');
  }
},

    // MÉTODOS PARA GESTIONAR LÍNEAS DE FACTURA
    
    // Cargar las líneas de factura
    async cargarLineasFactura(facturaId) {
      this.cargando = true;
      try {
        const params = {
          filter: {
            facturas_compra: facturaId
          }
        };
        
        const response = await movimientosAlmacenService.getAll(params);
        console.log('Respuesta líneas de factura:', response);
        
        if (response && response.movimientos_almacen) {
          this.lineasFactura = response.movimientos_almacen;
          this.totalLineasFactura = response.movimientos_almacen.length;
        } else {
          this.lineasFactura = [];
          this.totalLineasFactura = 0;
        }
        
        // Reiniciar a la primera página
        //this.paginaActualLineas = 1;
        
      } catch (error) {
        console.error('Error al cargar líneas de factura:', error);
        this.toast.error('Error al cargar las líneas de factura');
      } finally {
        this.cargando = false;
      }
    },
    
    // Obtener líneas para la página actual
    getLineasPaginadas() {
      const inicio = (this.paginaActualLineas - 1) * this.elementosPorPaginaLineas;
      const fin = inicio + this.elementosPorPaginaLineas;
      return this.lineasFactura.slice(inicio, fin);
    },
    
    // Calcular total de páginas para líneas
    calcularTotalPaginasLineas() {
      return Math.ceil(this.totalLineasFactura / this.elementosPorPaginaLineas);
    },
    
    // Cambiar página de líneas
    cambiarPaginaLineas(pagina) {
      if (pagina >= 1 && pagina <= this.calcularTotalPaginasLineas()) {
        this.paginaActualLineas = pagina;
        console.log('ESTAMOS EN PAGINA LINEAS: ', this.paginaActualLineas);
      }
    },
    
    // Abrir modal para crear una nueva línea
    abrirModalNuevaLinea() {
      if (!this.facturaForm.id) {
        this.toast.error('Debe guardar la factura antes de añadir líneas');
        return;
      }
      
      this.lineaFacturaSeleccionada = null;
      this.mostrarModalLineaFactura = true;
    },
    
    // Abrir modal para editar línea existente
    abrirModalEditarLinea(linea) {
      this.lineaFacturaSeleccionada = linea;
      this.mostrarModalLineaFactura = true;
    },
    
    // Cerrar modal de línea
    cerrarModalLineaFactura() {
      this.mostrarModalLineaFactura = false;
      this.lineaFacturaSeleccionada = null;
    },
    
    // Guardar línea (crear o actualizar)
    async cargarLineaFactura(lineaGuardada) {
      console.log('Línea guardada:', lineaGuardada);
      await this.cargarLineasFactura(this.facturaForm.id);
      await this.leerFactura(this.facturaForm.id);
      // No modificamos paginaActualLineas para mantener la página actual
    },
    
    // Confirmar eliminación de línea
    confirmarEliminarLinea(linea) {
      this.lineaSiendoEliminada = linea;
      this.mostrarModalConfirmacionLinea = true;
    },
    
    // Eliminar línea de factura
    async eliminarLineaFactura() {
      if (!this.lineaSiendoEliminada) return;
      
      this.cargando = true;
      try {
        await movimientosAlmacenService.delete(this.lineaSiendoEliminada.id);
        //this.toast.success('Línea eliminada correctamente');
        this.mostrarModalConfirmacionLinea = false;
        
        // Recargar líneas manteniendo la página actual
        await this.cargarLineasFactura(this.facturaForm.id);
        await this.leerFactura(this.facturaForm.id);
        // No modificamos paginaActualLineas para mantener la página actual
      } catch (error) {
        console.error('Error al eliminar línea:', error);
        this.toast.error('Error al eliminar la línea');
      } finally {
        this.cargando = false;
        this.lineaSiendoEliminada = null;
      }
    },
    
    // Recalcular totales de la factura a partir de las líneas
    async leerFactura(facturaId) {
      const facturaLeida = await facturasCompraService.getById(facturaId);
      if (facturaLeida) {
        // Formatear la fecha correctamente
        const factura = facturaLeida.facturas_compra[0];
        factura.fecha_factura = this.formatearFechaISO(factura.fecha_factura);
        this.facturaForm = factura;
      }
    },
    
  },
  setup() {
    const toast = useToast();
    return { toast };
  }
};
</script> 