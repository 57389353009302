<template>
  <div id="app">
    <!-- Header con navegación -->
    <header-component 
      :is-authenticated="isAuthenticated" 
      :user-name="currentUser?.nombre || ''"
      :user-email="currentUser?.email || currentUser?.name || ''"
      @logout="onLogout"
      @open-login="showLoginModal = true"
    ></header-component>
    
    <!-- Contenido principal -->
    <main>

      
      <!-- Contenido de la ruta -->
      <router-view></router-view>
    </main>
    
    <!-- Footer -->
    <footer-component></footer-component>

    <!-- Modal de Login -->
    <login-modal 
      :show="showLoginModal" 
      @close="showLoginModal = false"
      @login-success="onLoginSuccess"
    ></login-modal>
  </div>
</template>

<script>
import HeaderComponent from './components/Header.vue';
import FooterComponent from './components/Footer.vue';
import LoginModal from './components/LoginModal.vue';
import authService from './services/auth';

export default {
  name: 'App',
  components: {
    HeaderComponent,
    FooterComponent,
    LoginModal
  },
  data() {
    return {
      isAuthenticated: false,
      currentUser: null,
      showLoginModal: false
    };
  },
  created() {
    // Verificar si hay un usuario autenticado al iniciar la aplicación
    this.checkAuthentication();
  },
  methods: {
    checkAuthentication() {
      this.isAuthenticated = authService.isAuthenticated();
      this.currentUser = authService.getUserSession();
    },
    onLoginSuccess(user) {
      this.isAuthenticated = true;
      this.currentUser = user;
      this.showLoginModal = false;
    },
    onLogout() {
      // Cerrar sesión
      authService.logout();
      this.isAuthenticated = false;
      this.currentUser = null;
      
      // Redirigir a la página principal
      if (this.$router.currentRoute.value.meta.requiresAuth) {
        this.$router.push('/');
      }
    }
  }
}
</script>

<style>
/* Estilos de botones basados en la imagen de referencia */
.btn-primary {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.25rem;
  border: 1px solid transparent;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
  border-radius: 0.375rem;
  color: white;
  background-color: #f59e0b;
}
.btn-primary:hover {
  background-color: #d97706;
}

.btn-secondary {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.25rem;
  border: 1px solid #d1d5db;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
  border-radius: 0.375rem;
  color: #374151;
  background-color: white;
}
.btn-secondary:hover {
  background-color: #f9fafb;
}
</style>
