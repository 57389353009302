import configService from './config';

/**
 * Servicio para autenticación de usuarios
 */
export default {
  /**
   * Valida las credenciales de un usuario
   * @param {string} email - Email del usuario
   * @param {string} password - Contraseña del usuario
   * @returns {Promise<Object>} Información del usuario si es válido
   */
  async login(email, password) {
    try {
      // Obtenemos la configuración
      const config = await configService.getConfig();
      
      // Formamos la URL correcta usando las variables de configuración
      const baseUrl = `${config.servidor}/${config.instancia}`;
      const apiKey = config.api_key;
      
      // Preparamos el filtro con el formato requerido (email,password)
      const credentials = `${email},${password}`;
      
      // Realizamos la petición directamente para evitar la estructura predeterminada
      // que crea el servicio API base
      const url = `${baseUrl}/simplegest_dat_dat/v1/usr_m?filter[usr_pwd]=${encodeURIComponent(credentials)}&api_key=${apiKey}`;
      
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'accept': 'application/json'
        }
      });
      
      if (!response.ok) {
        throw new Error(`Error en la autenticación: ${response.status}`);
      }
      
      const data = await response.json();
      
      // Verificamos si se encontró el usuario
      if (data.count === 0 || !data.usr_m || data.usr_m.length === 0) {
        throw new Error('Credenciales inválidas');
      }
      
      // Devolvemos los datos del usuario
      return data.usr_m[0];
    } catch (error) {
      console.error('Error en la autenticación:', error);
      throw error;
    }
  },
  
  /**
   * Guarda la información del usuario en sesión
   * @param {Object} user - Información del usuario
   */
  saveUserSession(user) {
    localStorage.setItem('simpleges_user', JSON.stringify(user));
  },
  
  /**
   * Obtiene la información del usuario en sesión
   * @returns {Object|null} Información del usuario o null si no hay sesión
   */
  getUserSession() {
    const userData = localStorage.getItem('simpleges_user');
    return userData ? JSON.parse(userData) : null;
  },
  
  /**
   * Cierra la sesión del usuario
   */
  logout() {
    localStorage.removeItem('simpleges_user');
  },
  
  /**
   * Verifica si hay un usuario autenticado
   * @returns {boolean} True si hay un usuario autenticado
   */
  isAuthenticated() {
    return this.getUserSession() !== null;
  }
} 