<template>
  <div>
    <div class="py-6">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <formas-pago></formas-pago>
      </div>
    </div>
  </div>
</template>

<script>
import FormasPago from '../components/FormasPago.vue';

export default {
  name: 'FormasPagoView',
  components: {
    FormasPago
  }
}
</script> 