<template>
  <div>
    <!-- Mensaje de error -->
    <div v-if="error" class="bg-red-50 p-4 rounded-md text-red-700 text-sm mb-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <svg class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3">
          <h3 class="font-medium text-sm">{{ error }}</h3>
        </div>
      </div>
    </div>
    
    <form @submit.prevent="handleLogin">
      <div class="mb-4">
        <label for="email" class="block text-sm font-medium text-gray-700 mb-1">Email</label>
        <input 
          id="email" 
          type="email" 
          v-model="credentials.email" 
          class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
          :disabled="loading"
          required
        >
      </div>
      <div class="mb-6">
        <label for="password" class="block text-sm font-medium text-gray-700 mb-1">Contraseña</label>
        <input 
          id="password" 
          type="password" 
          v-model="credentials.password" 
          class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
          :disabled="loading"
          required
        >
      </div>
      <div class="flex justify-end">
        <button 
          type="submit" 
          class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-amber-500 border border-transparent rounded-md shadow-sm hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 relative"
          :disabled="loading"
        >
          <span v-if="loading" class="absolute inset-y-0 left-0 flex items-center pl-3">
            <svg class="animate-spin h-5 w-5 text-amber-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </span>
          <span :class="{ 'pl-8': loading }">Iniciar Sesión</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import authService from '../services/auth';

export default {
  name: 'LoginForm',
  data() {
    return {
      credentials: {
        email: '',
        password: ''
      },
      loading: false,
      error: null
    };
  },
  methods: {
    async handleLogin() {
      this.loading = true;
      this.error = null;
      
      try {
        // Llamar al servicio de autenticación
        const user = await authService.login(this.credentials.email, this.credentials.password);
        
        // Guardar los datos del usuario en la sesión
        authService.saveUserSession(user);
        
        // Emitir evento de login exitoso
        this.$emit('login-success', user);
        
        // Resetear el formulario
        this.credentials = { email: '', password: '' };
      } catch (error) {
        console.error('Error de autenticación:', error);
        this.error = 'Credenciales incorrectas. Por favor, verifica tu email y contraseña.';
      } finally {
        this.loading = false;
      }
    }
  }
};
</script> 